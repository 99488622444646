
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { graphql } from '@apollo/client/react/hoc';
import queryString from 'query-string';

// Higher Order Components
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import {
  Input,
  withFormik,
  Form,
  GenericError,
  connect,
} from 'OldCommon/Form';
import { post } from 'utils/axios';

import {
  ChevronRight,
} from 'common-ui/Icon';
import ID from 'common-ui/ConsistentIDs';
import { useI18n } from 'internationalization';
import { SET_NOTIFICATION } from 'apollo';
import { CODE_FAILED, CODE_TOO_MANY_ATTEMPTS } from 'Notification/NotificationTypes';

// Components - Quark
import { Button } from 'OldCommon/Button';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { BackButtonTransparent } from '../../common-ui/Button';


const VerifyCodeForm = ({ handleBack, errors, formik }) => {
  const intlz = useI18n();

  // Show customized error message that is translated
  const codeIsValid = formik.values.code.length === 5;
  const [hasHitSignIn, setHasHitSignIn] = useState(false); // Only show once message is set

  return (
    <div className={styles.loginFormContainer}>
      <Form>
        <div className={styles.verifyCodeFormWrapper}>
          <div className={styles.verifyCodeFormInputWrapper}>
            <Input
              labelClassName={styles.fiveDigitCodeLabel}
              label={intlz('5-Digit Code')}
              name="code"
              placeholder="ex. 12345"
              type="tel"
              autoFocus
              showError={false}
              id={ID.create(ID.Areas.Login, ID.Types.Input, ID.Labels.VerificationCode)}
            />
            { !codeIsValid && hasHitSignIn && (
            <div className={styles.phoneNumberErrorMessage}>
              {intlz('Please enter your 5-digit code.')}
            </div>
            )}
          </div>
        </div>
        <GenericError name="serverError" />
        <Button
          type="submit"
          onClick={() => setHasHitSignIn(true)}
          primary
          label={intlz('Sign In')}
          rightIcon={<ChevronRight />}
          fullWidth
          pad
          id={ID.create(ID.Areas.Login, ID.Types.Button, ID.Labels.SignIn)}
        />
        <div className={styles.backButtonTopLeft}>
          <BackButtonTransparent
            text={intlz('Back')}
            onClick={handleBack}
            className={classNames(
              styles.backButton,
              errors && errors.code && styles.codeErrorBackButton,
            )}
          />
        </div>
      </Form>
    </div>
  );
};

VerifyCodeForm.propTypes = {
  handleBack: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
};
const redirectURL = () => {
  const q = queryString.parse(window.location.search);
  // Make sure its an internal path i.e starts with "/"
  return q.des && q.des.startsWith('/') ? q.des : false;
};

const formConfig = {
  initialValues: { code: '' },
  mapPropsToValues: () => ({ code: '' }),
  validate: (values) => {
    const errors = {};
    if (values.code.length !== 5) {
      errors.code = '';
    }
    return errors;
  },
  handleSubmit: ({ code }, { props, setErrors, setSubmitting }) => {
    setSubmitting(true);
    props
      .verifyLoginCode(code)
      .then(() => {
        const redirect = redirectURL();
        if (redirect) {
          props.history.push(redirect);
          return;
        }
        props.history.push('/');
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response) {
          if (error.response.data && error.response.data && error.response.data.identifier) {
            if (error.response.data.identifier === 'APP_LCI') {
              props.notify({
                variables: { type: CODE_FAILED, message: 'Code was invalid.' },
              });
            } else if (error.response.data.identifier === 'APP_TMLA') {
              props.notify({
                variables: { type: CODE_TOO_MANY_ATTEMPTS, message: 'Too Many Attempts.' },
              });
            }
          }

          if (error.response.status >= 400 && error.response.status < 500) {
            setErrors({ serverError: 'Login failed!' });
          } else {
            setErrors({ serverError: error.response.data.toString() });
          }
        }
      });
  },
};

export default compose(
  withRouter,
  withHandlers({
    verifyLoginCode: () => (code) => {
      const params = new URLSearchParams();
      params.append('code', code);
      return post('token/auth', params);
    },
  }),
  graphql(SET_NOTIFICATION, { name: 'notify' }),
  withFormik(formConfig),
)(connect(VerifyCodeForm));
