import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Text } from 'OldCommon/Text';
import { useI18n } from 'internationalization';

import styles from './styles.module.scss';

const DayHeader = ({ day }) => {
  const intlz = useI18n();
  return (
    <div className={styles.headingContainer}>
      <Heading small className={styles.dayOfWeek}>
        {day.calendar(null, {
          lastDay: `[${intlz('Yesterday')}]`,
          sameDay: `[${intlz('Today')}]`,
          nextDay: `[${intlz('Tomorrow')}]`,
          lastWeek: `[${intlz('Last')}] dddd`,
          nextWeek: 'dddd',
          sameElse: 'L',
        })}
      </Heading>
      <Text secondary className={styles.date}>
        {day.format('LL')}
      </Text>
    </div>
  );
};

DayHeader.propTypes = {
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default DayHeader;
