/**
 * These are the types for the general area names. They are divided generally speaking by areas
 * that you can access in the top navigation bar.
 *
 * If there are any significant changes please update ReadMe.md in this directory
 */
// eslint-disable-next-line import/prefer-default-export
export const Areas = {
  Dispatch: 'Dispatch',
  Live: 'Live',
  Target: 'Target',
  Sites: 'Sites',
  History: 'History',
  Requests: 'Requests',
  Insights: 'Insights',
  Payments: 'Payments',
  Settings: 'Settings',
  General: 'General',
  Accounts: 'Accounts',
  Jobs: 'Jobs',
  Tickets: 'Tickets',
  Login: 'Login',
  TicketDetails: 'TicketDetails',
  TimesheetDetails: 'TimesheetDetails',
};

export type Area = $Keys<typeof Areas>;
