import { Component } from 'react';
import PropTypes from 'prop-types';

// Higher Order Component
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  shouldUpdate,
} from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

class MetadataProvider extends Component {
  componentDidMount() {
    this.updateMetadata();
    this.timer = setInterval(this.updateMetadata, 24 * 60 * 60 * 1000);
  }

  componentDidUpdate() {
    this.updateMetadata();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateMetadata() {
    const { accountID } = this.props;

    if (process.env.NODE_ENV === 'development') {
      console.log(
        `Calling Device Metadata Handler with Account ID: ${accountID}`,
      );
    }

    try {
      window.webkit.messageHandlers.MetadataHandler.postMessage(accountID);
    } catch (error) {
      // swallow error
    }
    try {
      window.Android.metadataHandler(accountID);
    } catch (error) {
      // swallow error
    }
  }

  render() {
    return null;
  }
}

MetadataProvider.propTypes = {
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MetadataProvider.defaultProps = {
  accountID: null,
};

export const ACCOUNT_METADATA_QUERY = gql`
  query AccountMetadataQuery {
    whoami {
      id
    }
  }
`;

export default compose(
  graphql(ACCOUNT_METADATA_QUERY),
  branch(
    props => !props.data || props.data.loading || !props.data.whoami.id,
    renderNothing,
  ),
  mapProps(props => ({ accountID: props.data.whoami.id })),
  shouldUpdate((prev, next) => prev.accountID !== next.accountID),
)(MetadataProvider);
