import { formatPayUnit } from './rateUnits';

export function formatPayRate(rate) {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2,
  });

  return rate ? formatter.format(rate) : formatter.format(0);
}

export function formatPay(rate, unit) {
  if (!rate && !unit) {
    return 'No rate provided';
  }
  if (unit && !rate) {
    return `Pay by ${formatPayUnit(unit)}`;
  }

  return `${formatPayRate(rate)}/${formatPayUnit(unit)}`;
}

// Provides an internationalized way to format pay rates
export function formatPayIntlz(rate, unit, intlz) {
  if (!rate && !unit) {
    return intlz('No rate provided');
  }
  if (!rate && unit) {
    return intlz(`Pay by ${formatPayUnit(unit)}`);
  }
  if (rate && !unit) {
    return formatPayRate(rate);
  }

  return `${formatPayRate(rate)}/${intlz(formatPayUnit(unit))}`;
}
