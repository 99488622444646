// @flow
import React from 'react';
import moment from 'moment';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core/styles'; // eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { useI18n } from 'internationalization';
import styles from './styles.module.scss';

type FormikMobileTimePickerProps = {
  value: moment,
  onChange: moment => void,
  placeholder?: string,
  id?: string,
  readOnly?: boolean,
}

const white = '#EEEEEE';
const darkGray = '#4a4a4a';
const blue = '#106FEE';
const spacer = '1rem';
const fontFamily = '"Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif';

const theme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: white,
        height: 'unset',
        paddingTop: spacer,
        paddingBottom: spacer,
      },
    },
    MuiPickersTimePickerToolbar: {
      toolbarAmpmLeftPadding: {
        paddingLeft: '2rem',
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: darkGray,
        opacity: '1',
      },
      toolbarTxt: {
        fontFamily,
        fontSize: '80px',
        fontWeight: '400',
        color: darkGray,
        opacity: '0.5',
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        fontFamily,
        color: darkGray,
        fontWeight: '600',
      },
      clockNumberSelected: {
        background: darkGray,
      },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: white,
      },
      pin: {
        backgroundColor: darkGray,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: darkGray,
      },
    },
    MuiButton: {
      label: {
        fontFamily,
        textTransform: 'capitalize',
        color: blue,
        fontSize: spacer,
      },
    },
  },
});

const MobileTimePicker = ({
  onChange,
  value,
  placeholder,
  id,
  readOnly,
}: FormikMobileTimePickerProps) => {
  const intlz = useI18n();

  return (
    <div className={styles.overrideStyles}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            readOnly={readOnly}
            value={value || null}
            onChange={onChange}
            placeholder={placeholder}
            clearable
            cancelLabel={intlz('Cancel')}
            clearLabel={intlz('Clear')}
            okLabel={intlz('Ok')}
            id={id}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};

MobileTimePicker.defaultProps = {
  placeholder: '',
  id: undefined,
  readOnly: false,
};

export default MobileTimePicker;
