// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type FormFieldProps = {
  label?: Node,
  required?: boolean,
  locked?: boolean,
  error?: string,
  children: Node,
  embedded?: boolean,
  hint?: string,
  description?: string,
  hideError?: boolean,
  tooltip?: Node,
  id?: string,
  warning?: string,
  showWarning?: boolean,
};

const FormField = ({
  label,
  required,
  error,
  children,
  embedded,
  hint,
  locked,
  description,
  hideError,
  tooltip,
  id,
  warning,
  showWarning,
}: FormFieldProps) => {
  return (
  <div id={id} className={classNames(styles.formField, { [styles.embedded]: embedded })}>
    {label && (
      <div className={styles.formFieldLabelsContainer}>
        <span className={styles.formFieldLabel}>
          {label}
          {tooltip && (
            <span className={styles.formFieldLabelTooltip}>
              {tooltip}
            </span>
          )}
        </span>
        {required && <span className={styles.formFieldRequired}>Required</span>}
        {locked && <span className={styles.formFieldRequired}>Locked</span>}
      </div>
    )}
    {description && <div className={styles.formFieldDescription}>{description}</div>}
    {children}
    {hint && (
      <div className={styles.formFieldLabelsContainer}>
        <span className={styles.formFieldHint}>
          {hint}
        </span>
      </div>
    )}
    {!embedded && !hideError && (
      <span className={styles.formFieldError}>
        {error}
        &nbsp;
      </span>
    )}
    {showWarning && warning && (
      <span className={styles.formFieldError}>
        {warning}
        &nbsp;
      </span>
    )}
  </div>
)};

FormField.defaultProps = {
  required: false,
  error: '',
  embedded: false,
  hint: '',
  locked: false,
  description: '',
  label: undefined,
  hideError: false,
  tooltip: undefined,
  id: undefined,
  warning: '',
  showWarning: false,
};

export default FormField;
