import React from 'react';

type PaginationPrevPropTypes = {
  viewBox?: string,
  height?: number,
  width?: number,
};

const PaginationPrev = (props: PaginationPrevPropTypes) => {
  const { viewBox, width, height } = props;
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="31.5" y="31.5" width={width-1} height={height-1} rx="15.5" transform="rotate(180 31.5 31.5)" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3326 23.285C19.6171 23.5598 19.7312 23.9667 19.6311 24.3493C19.5309 24.732 19.2321 25.0308 18.8494 25.131C18.4667 25.2312 18.0598 25.117 17.785 24.8325L10.1239 17.1714C9.6966 16.744 9.6966 16.0512 10.1239 15.6238L17.785 7.96261C18.2145 7.54784 18.8971 7.55378 19.3193 7.97594C19.7414 8.39811 19.7474 9.08073 19.3326 9.51017L12.4452 16.3976L19.3326 23.285Z" fill="#4A4A4A"/>
            <rect x="31.5" y="31.5" width={width-1} height={height-1} rx="15.5" transform="rotate(180 31.5 31.5)" stroke="#C3C3C3"/>
        </svg>
    );
};

PaginationPrev.defaultProps = {
    viewBox: '0 0 32 32',
    width: 32,
    height: 32,
};

export default PaginationPrev;
