import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { compose, withHandlers } from 'recompose';
import { Redirect } from 'react-router-dom';
import { post } from 'utils/axios';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = { didLogout: false };
  }

  componentDidMount() {
    const { client, logout } = this.props;
    logout();
    client.resetStore();
    this.setState({ didLogout: true });
  }

  render() {
    const { didLogout } = this.state;
    return didLogout ? <Redirect to="/login" /> : null;
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  client: PropTypes.shape({
    resetStore: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  withApollo,
  withHandlers({
    logout: () => () => post('token/clear'),
  }),
)(Logout);
