import moment from 'moment-timezone';

import { ACCEPTED, LOGGING } from 'utils/constants/job';

// / Get Accepted Jobs (based on sendStatus)
const getAcceptedJobs = jobs => jobs
  .filter((j) => {
    switch (j.sendStatus) {
      case ACCEPTED:
      case LOGGING:
        return true;
      default:
        return false;
    }
  })
  .sort((a, b) => {
    const aTime = moment(a.startTime);
    const isSame = aTime.isSame(b.startTime);

    if (isSame) {
      if (!!(a.sendStatus === LOGGING) || !(b.sendStatus === LOGGING)) {
        return -1;
      }
      return 1;
    }
    return moment(a.startTime).isBefore(b.startTime) ? -1 : 1;
  });

// / Get the currently focused job (based on progress sendStatus)
const getFocusedJob = (jobs) => {
  // filter jobs (should keep order)
  let activeJob = jobs.filter(
    job => job.sendStatus === LOGGING,
  );

  activeJob = !!activeJob && activeJob.length > 0 ? activeJob[0] : null;

  if (activeJob) {
    return activeJob;
  }
  return !!jobs && jobs.length > 0 ? jobs[0] : null;
};

// / Get Closest Scheduled Job (based on start time)
const getClosestScheduledJob = (jobs) => {
  const now = moment();

  return jobs.reduce((acc, curr) => {
    if (curr.trackingStart) {
      return acc;
    }
    if (
      !curr.startTime
      || !moment(curr.startTime).isValid()
      || Math.abs(now.diff(curr.startTime, 'days', true)) >= 1
    ) {
      return acc;
    }
    if (!acc) {
      return curr;
    }

    const currDiff = Math.abs(now.diff(curr.startTime));
    const accDiff = Math.abs(now.diff(acc.startTime));
    return currDiff < accDiff ? curr : acc;
  }, null);
};

// Get if the job is outside its logging window
// The logging window is 6 hours before the start time up to 12 hours after the start time.
const jobIsInLoggingWindow = (job, timezone) => {
  if (!job) {
    // no job, so is not in logging window
    return false;
  }

  let beforeWindow = false;
  let afterWindow = false;
  if (!timezone) {
    beforeWindow = moment(job.startTime).isSameOrBefore(moment().add(6, 'hour'), 'minute');
    afterWindow = moment(job.startTime).isSameOrAfter(moment().add(-12, 'hour'), 'minute');
  } else {
    beforeWindow = moment(job.startTime, timezone).isSameOrBefore(moment().tz(timezone).add(6, 'hour'), 'minute');
    afterWindow = moment(job.startTime, timezone).isSameOrAfter(moment().tz(timezone).add(-12, 'hour'), 'minute');
  }

  return beforeWindow && afterWindow
};

export {
  getAcceptedJobs,
  getFocusedJob,
  getClosestScheduledJob,
  jobIsInLoggingWindow,
};
