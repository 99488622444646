import React from 'react';

const Person = props => (
  <svg width={21} height={21} viewBox="0 0 21 21" {...props}>
    <title>Person</title>
    <path
      d="M6.384 12.432a19.702 19.702 0 0 0-2.628 1.22c-.312.174-.525.304-.6.353-.253.17-.537.493-.754.876-.199.351-.302.688-.302.859v1.424c.367.224.917.473 1.591.703 1.86.637 4.277 1.03 6.809 1.03 2.533 0 4.95-.392 6.81-1.028.674-.231 1.223-.479 1.59-.703V15.74c0-.162-.104-.495-.304-.848-.214-.378-.495-.704-.748-.888-.107-.068-.31-.19-.594-.349a19.858 19.858 0 0 0-2.636-1.226c-.894 1.605-2.216 2.796-4.118 2.796-1.9 0-3.222-1.19-4.116-2.793zm-.832-1.933c-.604-1.83-.827-3.786-.827-5.061C4.725 2.434 7.311 0 10.5 0c3.19 0 5.775 2.434 5.775 5.438 0 1.274-.223 3.23-.826 5.058 2.103.803 3.575 1.766 3.575 1.766 1.092.76 1.976 2.322 1.976 3.478v2.108C21 19.008 16.47 21 10.5 21S0 19.004 0 17.848V15.74c0-1.16.87-2.732 1.987-3.482 0 0 1.463-.957 3.565-1.76zm4.948 2.626c2.068 0 3.675-3.798 3.675-7.687 0-1.815-1.618-3.338-3.675-3.338-2.057 0-3.675 1.523-3.675 3.338 0 3.889 1.607 7.687 3.675 7.687z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default Person;
