/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import { BackButtonTransparent, TextOnlyButton } from 'common-ui/Button';
import { useFullScreenRender } from 'common-ui/Hooks';
import styles from './styles.module.scss';
import { useI18n } from '../../../internationalization';

type PageProps = {
  title: string | null,
  onBack?: (() => void) | null,
  onClose?: (() => void) | null,
  visible?: boolean,
  fullScreen?: boolean,
  hasBottomGutter?: boolean,
  children: Node,
};

const Page = ({
  title,
  onBack,
  onClose,
  visible,
  fullScreen,
  children,
  hasBottomGutter,
}: PageProps) => {
  const intlz = useI18n();
  return (
    <div
      className={classNames(
        styles.page,
        visible && styles.visible,
        fullScreen && styles.fullScreen,
      )}
    >
      <div className={styles.pageHeader}>
        <BackButtonTransparent
          text={intlz('Back')}
          onClick={onBack}
          className={classNames(styles.headerButton, !onBack && styles.hidden)}
        />
        <div className={styles.navMiddleTitle}>{title}</div>
        <TextOnlyButton
          text={intlz('Cancel')}
          onClick={onClose}
          className={classNames(styles.headerButton, !onClose && styles.hidden)}
        />
      </div>
      <div className={styles.pageBody}>
        {children}
        { hasBottomGutter && <div className={styles.bottomGutter} />}
      </div>
    </div>
  );
};

Page.defaultProps = {
  onBack: null,
  onClose: null,
  visible: true,
  fullScreen: false,
  hasBottomGutter: true,
};


type FullScreenPageProps = {
  pageProps?: { [x: string]: any; }
}
// The FullScreenPage component is used to show a full screen component, outside the normal
// component tree (things like ticket/sign off page flows). It is meant to be used with the
// usePageDisplay hook in common-ui.
export const FullScreenPage = ({
  ...pageProps
}:FullScreenPageProps) => {
  const fullScreenRender = useFullScreenRender();


  return fullScreenRender(
    // $FlowFixMe
    <Page fullScreen {...pageProps} />,
  );
};

FullScreenPage.defaultProps = {
  pageProps: undefined,
};

export default Page;
