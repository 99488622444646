import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { Button } from 'OldCommon/Button';
import { Play } from 'common-ui/Icon';
import { Text } from 'OldCommon/Text';
import { useI18n } from 'internationalization';

import styles from './styles.module.scss';

const DisabledLogButton = ({
  cantLogNotCurrentDay,
  cantLogCurrentlyLogging,
  jobStartTime,
  prefTimeZone,
}) => {
  const startTime = prefTimeZone
    ? moment.tz(jobStartTime, prefTimeZone)
    : moment(jobStartTime);
  const intlz = useI18n();

  return (
    <>
      <div className={styles.logButton}>
        <Button fullWidth disabled leftIcon={<Play />} label={intlz('Start Job')} />
      </div>
      <div>
        {cantLogCurrentlyLogging ? (
          <Text className={styles.alignCenter} secondary>
            {intlz('You must <bold>Stop Logging</bold> before starting a new job', {
              bold: (word) => <span key={word} className={styles.bold}>{word}</span>,
            })}
          </Text>
        ) : null}
        {cantLogNotCurrentDay ? (
          <Text className={styles.alignCenter} secondary>
            {intlz('Scheduled for')}
            &nbsp;
            {startTime.format('MMM D')}
          </Text>
        ) : null}
      </div>
    </>
  );
};

DisabledLogButton.propTypes = {
  cantLogNotCurrentDay: PropTypes.bool.isRequired,
  cantLogCurrentlyLogging: PropTypes.bool.isRequired,
  jobStartTime: PropTypes.string,
  prefTimeZone: PropTypes.string,
};

DisabledLogButton.defaultProps = {
  jobStartTime: null,
  prefTimeZone: null,
};

export default DisabledLogButton;
