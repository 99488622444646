import React from "react";
import styles from "./styles.module.scss";
import PropType from "prop-types";

const Badge = ({ count, alert, neutral, label, online, idle, disconnected }) => (
  <div className={styles.badgeWrap}>
    <div
      className={`${styles.badgeCount} ${!!alert &&
        styles.badgeAlert} ${!!neutral && styles.badgeNeutral} ${!!online && styles.badgeOnline} ${!!idle && styles.badgeIdle} ${!!disconnected && styles.badgeDisconnected}`}
    >
      <div className={styles.countLabel}>{count}</div>
    </div>
    {!!label && <div className={styles.badgeLabel}>{label}</div>}
  </div>
);

Badge.propTypes = {
  alert: PropType.bool,
  count: PropType.number.isRequired
};

Badge.defaultProps = {
  alert: false
};

export default Badge;
