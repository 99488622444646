import React from "react";
import styles from "./styles.module.scss";

const Notification = ({
  className,
  leftIcon,
  label,
  logging,
  rightIcon,
  mobile,
  inline
}) => (
  <div
    className={`${styles.notification} ${!!logging && styles.logging} 
      ${!!mobile && styles.mobile} ${!!className ? className : ""}
      ${!!inline && styles.inline}
      `}
  >
    {!!leftIcon && <div className={styles.icon}>{leftIcon}</div>}
    {!!label && <div className={styles.label}>{label}</div>}
    {!!rightIcon && <div className={styles.icon}>{rightIcon}</div>}
  </div>
);

export default Notification;
