// @flow
import React from 'react';
import { useI18n } from 'internationalization';
import { Tag } from 'common-ui/Labels';
import styles from './styles.module.scss';

type TicketHeaderProps = {
  ticketNumber?: string | null,
  treadID?: string | null,
  isScaleGenerated?: boolean,
};

const TicketHeader = ({ ticketNumber, treadID, isScaleGenerated }:TicketHeaderProps) => {
  const intlz = useI18n();
  return (
    <div className={styles.ticketTitleContainer}>
      <div className={styles.textHeader}>
        {ticketNumber ? `${intlz('Ticket #')} ${ticketNumber}` : intlz('Ticket')}
      </div>
      <div>
        {isScaleGenerated && (
          <Tag text={intlz('Scale generated')} className={styles.ticketPill} />
        )}
      </div>
      <div className={styles.textBody}>
        {treadID && `${intlz('Tread ID')} ${treadID}`}
      </div>
    </div>
  );
};

TicketHeader.defaultProps = {
  ticketNumber: '',
  treadID: null,
  isScaleGenerated: false,
};

export default TicketHeader;
