// @flow
import React, { type Node } from 'react';
import { Place } from 'common-ui/Icon';
import { SmallButton } from 'common-ui/Button';
import { useI18n } from 'internationalization';
import { SiteIcon } from 'common-ui/Sites';
import { googleMapURL } from 'utils/format';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import type { Site } from 'common-ui/types';
import { PENDING } from 'utils/constants/job';
import styles from './styles.module.scss';
type DetailsRowProps = {
  label: string,
  value: Node,
};

export const DetailsRow = ({ label, value }: DetailsRowProps) => (
  <>
    <div className={styles.detailsRowLabel}>{label}</div>
    <div className={styles.detailsRowValue}>{value}</div>
  </>
);

type SiteDetailsRowProps = {
  label: string,
  site: Site,
  address: string,
  sendStatus: string,
  hideDirections: boolean,
};

export const SiteDetailsRow = ({
  label,
  site,
  address,
  sendStatus,
  hideDirections,
}: SiteDetailsRowProps) => {
  const intlz = useI18n();
  const trackDirectionsClick = () => {
    if (sendStatus === PENDING) {
      GA.trackClick(GAActions.requestDirections, 'Get Directions');
    } else {
      GA.trackClick(GAActions.jobDirections, 'Get Directions');
    }
  };
  return (
    <DetailsRow
      label={label}
      value={(
        <>
          {!!site && (
            <div className={styles.projectSiteIndicator}>
              <SiteIcon type={site.type} />
              <div className={styles.siteDetailText}>
                {site.name}
              </div>
            </div>
          )}
          <div className={styles.siteAddressText}>
            {address}
          </div>
          {!!site && !!site.notes && (
            <div className={styles.siteNotes}>
              {site.notes}
            </div>
          )}
          {!!site && !hideDirections && (
            <a href={googleMapURL(address)}>
              <SmallButton
                text={intlz('Get Directions')}
                icon={<Place />}
                className={styles.siteDirectionsButton}
                onClick={trackDirectionsClick}
              />
            </a>
          )}
        </>
      )}
    />
  );
};
