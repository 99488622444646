import React from 'react';

const Pen = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <title>Pen</title>
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M15.8143 2.25667L16.1221 2.56078C16.3596 2.32519 16.6882 2.17938 17.0513 2.17938C17.7757 2.17938 18.363 2.75963 18.363 3.47541C18.363 3.83406 18.2155 4.15869 17.9773 4.39334C17.9828 4.39903 17.9885 4.4048 17.9942 4.41065L18.2922 4.70511C18.3458 4.75811 18.3953 4.8137 18.4405 4.87146C18.6094 5.04767 18.7663 5.21415 18.8498 5.30976C19.007 5.4896 19.1526 5.66517 19.2845 5.83532C19.7301 6.4104 20.0044 6.88988 20.071 7.35219C20.36 9.35553 19.4897 11.2274 17.4093 12.3698C15.5572 13.3868 14.896 14.5405 15.5818 15.5086C15.8587 15.8995 15.7624 16.4383 15.3668 16.7119C14.9711 16.9855 14.4259 16.8904 14.149 16.4995C12.6978 14.4506 13.9278 12.3047 16.5598 10.8593C17.9876 10.0753 18.5293 8.91023 18.3397 7.59603C18.333 7.54959 18.2736 7.43152 18.1696 7.27001L6.57857 18.7234C6.40835 18.8916 6.076 19.028 5.83038 19.028H2.54872L2.3677 19.2069C2.02622 19.5443 1.47258 19.5443 1.13111 19.2069C0.789631 18.8694 0.789631 18.3224 1.13111 17.985L1.3125 17.8057L1.3125 14.5643C1.3125 14.3242 1.4525 13.9914 1.62087 13.8252L13.3417 2.25494C14.0243 1.58106 15.1313 1.58173 15.8143 2.25667ZM16.7871 5.65997C16.866 5.74086 16.9579 5.83562 17.0508 5.93208L13.3791 9.56021L10.8982 7.1088C12.9392 5.09508 14.5778 3.47844 14.578 3.47864C14.578 3.47864 16.1446 5.02608 16.7871 5.65997ZM4.29454 17.3029L4.5537 17.0468C4.89518 16.7094 4.89518 16.1623 4.5537 15.8249C4.21223 15.4875 3.65859 15.4875 3.31711 15.8249L3.06362 16.0754L3.06165 14.8405L9.6603 8.32959L12.1421 10.7819L5.53956 17.306L4.29454 17.3029Z" 
      fill="#767676"/>
  </svg>
);

export default Pen;
