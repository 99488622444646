import React from "react";
import PropTypes from "prop-types";
import { ChevronRight } from "common-ui/Icon";

import styles from "./styles.module.scss";

const PageNav = ({ arrow, leftIcon, label, count, tag, setting, ...rest }) => (
  <div className={`${styles.base} ${!!count && styles.count} ${!!setting && styles.setting} `} {...rest}>
    {!!leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
    <div className={styles.label}>
      <div className={styles.bold}>{label}</div>
      {!!tag && tag}
    </div>
    {!!arrow && (
      <div className={styles.arrow}>
        <ChevronRight />
      </div>
    )}
  </div>
);

PageNav.propTypes = {
  arrow: PropTypes.bool,
  tag: PropTypes.element,
  leftIcon: PropTypes.element
};

PageNav.defaultProps = {
  arrow: true
};

export default PageNav;
