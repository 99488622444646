import React from 'react';

// NOTE: To convert from SVG, you must rename any 'xlink:href'
// to 'xlinkHref' and 'xmlns:xlink' to 'xmlnsXlink'
// https://github.com/facebook/react/issues/4908
const Pause = (props) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icon/Pause</title>
    <desc>Created with Figma.</desc>
    <rect x="4.75" y="4.75" width="3.16667" height="9.5" fill="white" />
    <rect x="11.0833" y="4.75" width="3.16667" height="9.5" fill="white" />
  </svg>
);

export default Pause;
