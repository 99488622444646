// @flow
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import type { Match } from 'react-router';
import classNames from 'classnames';
import { useI18n } from 'internationalization';
import moment from 'moment-timezone';
import 'moment-duration-format';
import { Button } from 'common-ui/Button';
import { CardDivider } from 'common-ui/Cards';
import ClickableDiv from 'common-ui/ClickableDiv';
import { MimeType } from 'common-ui/Const';
import { useTimezone, usePageDisplay } from 'common-ui/Hooks';
import { CameraIcon, FlatClockIcon, Pen, PenPaperIcon } from 'common-ui/Icon';
import TicketZoomer from 'common-ui/Tickets/TicketZoomer';
import BottomSheet from 'common-ui/BottomSheet';
import Page, { FullScreenPage } from 'components/Common/Page';
import useRequestSignoff from 'components/Receipts/hooks/useRequestSignoff';
import useDriverConfigurableFields from 'Hooks/useDriverConfigurableFields';
import useTimesheet from '../Hooks/useTimesheet';
import TimesheetPhotoUpload from './TimesheetPhotoUpload'
import TimesheetSignoffStatus from './TimesheetSignoffStatus';
import TimesheetRequestedInfoForm from './TimesheetRequestedInfoForm';
import styles from './styles.module.scss';
import Jobs from './Jobs/index';

type TimesheetDetailsProps = {
  match: Match,
};

const TimesheetDetail = ({ match }: TimesheetDetailsProps) => {
  const history = useHistory();
  const timesheetID = match.params.id;
  const companyID = match.params.companyId;
  const [timesheet, { loading, error }] = useTimesheet(timesheetID, companyID);
  const timezone = useTimezone();
  const intlz = useI18n();
  const requestSignoff = useRequestSignoff();
  const [openPageDisplay, pageDisplayProps] = usePageDisplay();
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const data = timesheet && timesheet.timesheetAttachment && timesheet.timesheetAttachment.uploadData;
  const assignment = timesheet && timesheet.assignmentInstances && timesheet.assignmentInstances[0].assignment;
  const projectID = assignment && assignment.project && assignment.project.id;
  const { configurableFields } = useDriverConfigurableFields(companyID, projectID);

  if (loading) return <div className={styles.loadingSpinner} />;
  if (error || !timesheet) return <Redirect to="/receipts" />;

  const tsDate = moment.tz(timesheet.date, timezone);
  const formattedDate = tsDate.format('MMM D, YYYY');
  const companyName = timesheet.assignmentInstances[0].assignment.project.companyName;

  const validTime = timesheet.adjustmentCalculation && timesheet.adjustmentCalculation.totalAdjustmentTime;
  const adjustedTime = validTime ? moment.duration(timesheet.adjustmentCalculation.totalAdjustmentTime, 'minutes').format('h[ Hrs] mm[ Mins]') : '-';

  const jobs = timesheet.assignmentInstances.map((i) => {
    return {
      assignmentInstanceID: i.id,
      number: i.assignment.project.jobNumber,
      name: i.assignment.project.name,
      pickUp: (i.assignment.pickUpSite && i.assignment.pickUpSite.name) || "",
      dropOff: (i.assignment.dropOffSite && i.assignment.dropOffSite.name) || "",
      product: i.assignment.product,
      tickets: i.tickets.map((t) => {
        return { id: t.id };
      }),
      isSelfLogged: (i.assignment && i.assignment.project && i.assignment.project.selfLog || false),
    };
  });

  const tsConfigurableFields = configurableFields.filter(cf => cf.driverVisible && cf.isTimesheetField);
  const canViewRequestedInfo = tsConfigurableFields && tsConfigurableFields.length > 0;
  const readOnly = timesheet && timesheet.signoff;

  return (
    <Page title="" onBack={() => history.push('/timesheets')}>
      <div className={styles.timesheetDetailContainer}>
        {/* Signoff Status */}
        <TimesheetSignoffStatus timesheet={timesheet} />

        {/* Company name and Date Header */}
        <div className={styles.timesheetDetailHeader}>
          <div className={styles.textHeader}>{formattedDate}</div>
          <div className={styles.timesheetDetailSubHeader}>{companyName}</div>
        </div>

        {/* Total Adjusted Hours */}
        <div className={styles.borderedContainer}>
          <div className={styles.textHeader}>{intlz('Total Adjusted Hours')}</div>
          <div className={styles.adjustedHoursValue}>{adjustedTime}<FlatClockIcon /></div>
          <div className={styles.timesheetDetailSubHeader}>{intlz('Adjustments are made by your administrator')}</div>
        </div>

        <CardDivider className={styles.divider} />

        {/* My Tasks */}
        <div className={styles.timesheetDetailTasks}>
          <div className={styles.timesheetDetailSectionHeader}>{intlz('My Tasks')}</div>

          {/* Requested Info */}
          {canViewRequestedInfo && (
            <div className={classNames(styles.borderedContainer, styles.timesheetDetailSection)}>
              <div className={styles.sectionSVG}><PenPaperIcon /></div>
              <div className={styles.timesheetSectionText}>
                {intlz('Requested Info from Dispatcher')}
                <ClickableDiv className={styles.taskLink} onClick={() => setBottomSheetVisible(true)}>
                  {`\u2295 ${intlz(readOnly ? 'View Info' : 'Enter Info')}`}
                </ClickableDiv>
              </div>
              <BottomSheet visible={bottomSheetVisible} setVisible={setBottomSheetVisible}>
                <TimesheetRequestedInfoForm
                  setBottomSheetVisible={setBottomSheetVisible}
                  configurableFields={tsConfigurableFields}
                  configurableFieldValues={timesheet.configurableFields}
                  readOnly={readOnly}
                  timesheetID={timesheet.id} />
              </BottomSheet>
            </div>
          )}

          {/* Timehseet Photo */}
          {/* https://tread.atlassian.net/browse/POW-734 */}
          <div className={classNames(styles.borderedContainer, styles.timesheetDetailSection, readOnly && styles.uploadDisabled)}>
            <div className={styles.sectionSVG}><CameraIcon /></div>
            <div className={styles.timesheetSectionText}>
              {intlz('Add Timesheet Photo')}
              {
                <ClickableDiv className={styles.taskLink} onClick={() => {
                  !readOnly && openPageDisplay()
                }}>
                  {`\u2295 ${intlz('Add a Photo')}`}
                </ClickableDiv>}
              <FullScreenPage
                title={intlz('Add Timesheet Photo')}
                {...pageDisplayProps}
                titleDivider
                onClose={() => pageDisplayProps.goBack()}
              >
                <div className={styles.timesheetAddPhotoContainer}>
                  <TimesheetPhotoUpload timesheet={timesheet} onComplete={() => pageDisplayProps.goBack()} />
                </div>
              </FullScreenPage>
            </div>
            {data.getURL && data.getURL.length &&
              (
                <div className={styles.timesheetPhoto}>
                  <TicketZoomer
                    src={data.getURL}
                    rotation={0}
                    filename={data.filename}
                    mimeType={MimeType.Image}
                  />
                </div>
              )
            }
          </div>
        </div>

        <div className={styles.timesheetDetailJobs}>
          <div className={styles.timesheetDetailHeader}>
            <div className={styles.timesheetDetailSectionHeader}>{intlz('My Jobs')}</div>
          </div>
          <Jobs match={match} jobs={jobs} />
        </div>
      </div>

      {/* Sign Off Footer - only visible if timesheet has not been signed off or requested*/}
      {!(timesheet.signoffStatus === 'REQUESTED' || timesheet.signoff) && (<>
        <CardDivider className={styles.divider} />
        <div className={styles.timesheetDetailSignoffRequest}>
          <div className={styles.timesheetDetailSignoffText}>
            {intlz('The timesheet has not been signed off yet')}
          </div>
          <Button
            fullWidth
            className={styles.signoffButton}
            icon={<Pen />}
            text={intlz('Request Sign Off')}
            onClick={() => requestSignoff(timesheetID, companyID)}
          />
        </div>
      </>)}
    </Page>
  );
};

export default TimesheetDetail;
