import _ from 'lodash';
import dictionary from './dictionary';

// This function parses the dictionary.js object and returns separate objects mapping
// the key (the word or phrase itself) to its appropriate translation (in English,
// these values are identical)
const messagesForLanguage = (language) => _.zipObject(
  Object.keys(dictionary),
  Object.keys(dictionary).map((key) => _.get(dictionary, [key, language], key)),
);

const messages = {
  en: messagesForLanguage('en'),
  es: messagesForLanguage('es'),
};

export default messages;
