import React from 'react';
import moment from 'moment-timezone';
import { useI18n } from 'internationalization';
import { Pen, CheckInCircle } from 'common-ui/Icon';
import type { Timesheet } from 'common-ui/types';
import styles from './styles.module.scss';

type TimesheetSignoffStatusProps = {
  timesheet: Timesheet,
};

const TimesheetSignoffStatus = ({ timesheet }: TimesheetSignoffStatusProps) => {
  const intlz = useI18n();
  if (timesheet.signoffStatus !== 'REQUESTED' && !timesheet.signoff) {
    return <></>;
  }

  let signoffSvg, signoffDateTime, signoffText;
  if (timesheet.signoffStatus === 'REQUESTED') {
    signoffSvg = (<div className={styles.penSVG}> <Pen /> </div>);
    signoffDateTime = timesheet.signoffStatusUpdatedAt;
    signoffText = 'Sign Off has been requested';
  }

  if (!!timesheet.signoff) {
    signoffSvg = <CheckInCircle />;
    signoffDateTime = timesheet.signoff.signoffDate;
    signoffText = 'Timesheet has been signed off';
  }

  const signoffRequestedTime = moment(signoffDateTime).format('lll');

  return (
    <div className={styles.timesheetDetailSignoffStatus}>
      <div className={styles.signoffSvg}> {signoffSvg} </div>
      <div>
        <div> {intlz(signoffText)} </div>
        <div className={styles.timesheetDetailSubHeader}>{signoffRequestedTime}</div>
      </div>
    </div>
  );
};

export default TimesheetSignoffStatus;