// @flow
import React from 'react';
import classNames from 'classnames';
import { Button } from 'common-ui/Button';
import { Add } from 'common-ui/Icon';
import type { Ticket } from 'common-ui/types';
import { UploadWrapper } from 'common-ui/Upload';
import { useI18n } from 'internationalization';
import { ERROR } from 'components/Notification/NotificationTypes';
import parseGraphqlError, { useNotify } from 'utils/Error';
import Thumbnail from 'components/Common/Upload/components/Thumbnail';
import styles from './styles.module.scss';

type OCRUploadProps = {
  ticket: Ticket,
  instanceId: string,
  setSubmitting: (boolean) => void,
  onUpload?: (Ticket) => void,
  hideThumbnail?: boolean,
  shouldRunOCR: boolean,
};

const OCRUpload = ({
  ticket,
  instanceId,
  setSubmitting,
  onUpload,
  hideThumbnail,
  shouldRunOCR,
}: OCRUploadProps) => {
  const intlz = useI18n();
  const notify = useNotify();
  const hasNoImage = !(ticket && ticket.data.getURL);
  return (
    <div className={classNames(styles.ticketUpload, hideThumbnail && styles.ticketUploaded)}>
      <UploadWrapper
        input={{ id: instanceId, domain: 'INSTANCE' }}
        shouldRunOCR={shouldRunOCR}
        onUpload={onUpload}
        onError={(err) => notify({ variables: { type: ERROR, message: parseGraphqlError(err) } })}
        setUploading={setSubmitting}
      >
        {(openPicker) => (
          <>
            {!hideThumbnail && (
              <Thumbnail
                thumbnail={ticket && ticket.data.getURL}
                orientation={0}
                uploadCount={ticket ? 1 : 0}
                onClick={openPicker}
              />
            )}
            { hasNoImage && (
              <Button
                text={intlz('Add Photo')}
                icon={<Add />}
                large={!hideThumbnail}
                styleName="neutral"
                onClick={openPicker}
              />
            )}

          </>
        )}
      </UploadWrapper>
    </div>
  );
};

OCRUpload.defaultProps = {
  onUpload: () => {},
  hideThumbnail: false,
};

export default OCRUpload;
