// @flow
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import {
  useLocation,
  useHistory,
  Redirect,
  type Match,
} from 'react-router-dom';
import {
  ACCEPTED,
  LOGGING,
  PENDING,
  DONE,
  PAUSED,
} from 'utils/constants/job';
import { useI18n } from 'internationalization';
import JobInfoDetails from 'components/Common/JobInfoDetails';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import ClickableDiv from 'common-ui/ClickableDiv';
import { ContextSwitcher } from 'common-ui/ContextSwitcher';
import {
  ChevronRight, Dispatcher, Insights, Add,
} from 'common-ui/Icon';
import { Count } from 'common-ui/Labels';
import { useTimezone } from 'common-ui/Hooks';
import { formatWithTimezone } from 'common-ui/Utils/utils';
import ActivityStream from 'components/ActivityStream';
import Page from 'components/Common/Page';
import JobsHeader from 'components/Common/JobsHeader';
import TicketsPageDisplay, { useTicketsPageDisplay } from 'components/Tickets/TicketsPageDisplay';
import { Button } from 'common-ui/Button';
import { useGetAssignment } from 'components/TabBar/gql';
import { DetailsTable, DetailsRow } from 'components/Common/DetailsTable';
import { useGetAllAssignments } from './graphql';
import { useGetTicketNotifications } from './hooks';
import AcceptJobSection from './subcomponents/AcceptJobSection';
import JobLogButton from './subcomponents/LogButton';
import PullToRefresh from '../Common/PullToRefresh';
import styles from './styles.module.scss';

const Tabs = {
  INFO: 'info',
  ACTIVITY: 'activity',
};

type JobsDetailProps = {
  match: Match,
};

const JobsDetail = ({ match }: JobsDetailProps) => {
  const assignmentId = match.params.id;
  const intlz = useI18n();
  const timezone = useTimezone();
  const location = useLocation();
  const history = useHistory();

  const [openTicketsPage, pageDisplayProps] = useTicketsPageDisplay();

  const [assignment, loading, error, refetchJobDetails] = useGetAssignment(assignmentId);

  const [allAssignments] = useGetAllAssignments();

  const [currentTab, setTab] = useState(
    location.hash.substring(1) === Tabs.ACTIVITY ? Tabs.ACTIVITY : Tabs.INFO,
  );
  useEffect(() => {
    setTab(location.hash.substring(1) === Tabs.ACTIVITY ? Tabs.ACTIVITY : Tabs.INFO);
  }, [location]);

  const [accordionIndex, toggleAccordionIndex] = useState({});

  const [notifications, refetchNotifications] = useGetTicketNotifications(true);
  const numTicketNotifications = notifications[assignmentId]
    ? notifications[assignmentId].length
    : 0;

  if (loading) return <div className={styles.spinner} />;

  if (error
    || (assignment.sendStatus !== PENDING
      && assignment.sendStatus !== ACCEPTED
      && assignment.sendStatus !== LOGGING
      && assignment.sendStatus !== PAUSED
      && assignment.sendStatus !== DONE)) {
    return <Redirect to="/jobs" />;
  }

  const { project } = assignment;
  const assignmentInstance = assignment.assignmentInstances[0];

  if (assignment.sendStatus === DONE) {
    return <Redirect to={`/receipts/${assignmentInstance.id}`} />;
  }

  const isCurrentlyLoggingAnyJob = allAssignments.some((a) => a.sendStatus === LOGGING);
  const isPending = assignment.sendStatus === PENDING;
  const isPendingSchedule = isPending && !!assignment.schedule;
  const isPaused = assignment.sendStatus === PAUSED;
  const isAccepted = assignment.sendStatus === ACCEPTED;
  const canViewLogButton = isPaused || (isAccepted);
  const canAddTickets = assignment.sendStatus === LOGGING;

  const showActivityTab = assignment.sendStatus !== PENDING
    && assignmentInstance
    && assignmentInstance.loggingStart;

  const projectName = project.name || `${project.companyName} (${intlz('Self-Assigned')})`;

  const assignments = assignment.schedule && assignment.schedule.assignments;
  const start = assignments && moment.tz(assignments[0].pickupArrival, timezone);
  const end = assignments && moment.tz(assignments[assignments.length - 1].dropoffDeparture, timezone);

  const ActivityIconWithBadge = () => (
    <div className={styles.activityIconContainer}>
      <Insights />
      {numTicketNotifications > 0 && (
        <Count className={styles.notificationsCount} value={numTicketNotifications} />
      )}
    </div>
  );

  const toggleOptions = [
    { label: `${intlz('Job Info')}`, value: Tabs.INFO, icon: <Dispatcher /> },
    { label: `${intlz('Activity')}`, value: Tabs.ACTIVITY, icon: <ActivityIconWithBadge /> }];

  return (
    <Page
      title={isPendingSchedule ? project.companyName : projectName}
      onBack={() => {
        if (isPending) {
          GA.trackClick(GAActions.backRequest, 'Go back');
        } else {
          GA.trackClick(GAActions.backJob, 'Go back');
        }
        history.push('/jobs');
      }}
    >
      <PullToRefresh
        onRefresh={() => {
          refetchJobDetails();
          refetchNotifications();
        }}
      >
        <JobsHeader assignment={assignment} project={assignment.project} />
        <div className={styles.jobsDetailSection}>
          {showActivityTab && (
            <ContextSwitcher
              onChange={(newTab) => history.replace({ hash: newTab })}
              selectedValue={currentTab}
              options={toggleOptions}
              isOperatorStyle
            />
          )}
        </div>
        {currentTab === Tabs.INFO ? (
          <>
            {canAddTickets && (
              <div className={styles.jobsDetailSection}>
                <Button
                  fullWidth
                  large
                  className={styles.newTicketButton}
                  icon={<Add />}
                  text={intlz('New Ticket')}
                  onClick={() => openTicketsPage(TicketsPageDisplay.Steps.newTicket, null)}
                />
              </div>
            )}
            {isPendingSchedule ? (
              <>
                <div className={styles.scheduleHeader}>{intlz('Estimates')}</div>
                <div className={styles.scheduleInfo}>
                  <DetailsTable>
                    <DetailsRow
                      label={intlz('Start - Stop')}
                      value={`${start.format('h:mma')} - ${end.format('h:mma')}`}
                    />
                  </DetailsTable>
                </div>
                <div className={styles.scheduleHeader}>{intlz('Loads')}</div>
                {assignment.schedule.assignments.map((assign, index) => (
                  <ClickableDiv
                    key={assign.id}
                    onClick={() => toggleAccordionIndex((map) => ({
                      ...map, [index]: !map[index],
                    }))}
                  >
                    <div className={styles.scheduleAccordion}>
                      <Count value={index + 1} />
                      <span className={styles.scheduleAccordionTime}>
                        {formatWithTimezone(assignment.pickupArrival, timezone)}
                        &nbsp;
                        &#8209;
                        &nbsp;
                        {formatWithTimezone(assignment.dropoffDeparture, timezone)}
                      </span>
                      <ChevronRight
                        className={classNames(styles.scheduleAccordionToggle, {
                          [styles.open]: accordionIndex[index],
                          [styles.closed]: !accordionIndex[index],
                        })}
                      />
                    </div>
                    {accordionIndex[index] && (
                      <JobInfoDetails
                        key={assignment.id}
                        assignment={assignment}
                        project={project}
                      />
                    )}
                  </ClickableDiv>
                ))}
              </>
            ) : (
              <JobInfoDetails
                assignment={assignment}
                project={project}
              />
            )}
            {isPending ? <AcceptJobSection jobId={assignment.id} /> : null}
            {canViewLogButton && (
              <div className={styles.wrap}>
                <JobLogButton
                  focusedJobID={assignment.id}
                  assignmentStatus={assignment.sendStatus}
                  isCurrentlyLogging={isCurrentlyLoggingAnyJob}
                  prefTimeZone={timezone}
                />
              </div>
            )}
          </>
        ) : (
          <ActivityStream assignment={assignment} />
        )}
        {canAddTickets && (
          <TicketsPageDisplay
            assignment={assignment}
            instanceId={assignmentInstance.id}
            tickets={assignmentInstance.tickets}
            signoffEnabled={assignment.project.signOffRequired}
            onAddTicket={() => setTab(Tabs.ACTIVITY)}
            {...pageDisplayProps}
          />
        )}
      </PullToRefresh>
    </Page>
  );
};

export default JobsDetail;
