import { useIntl } from 'react-intl';

// This hook provides a function that pulls the internationalized version of a certain
// word or phrase. Usage is as follows:
// const intlz = useI18n();
// const internationalizedWord = intlz('word');
// Then in dictionary.js, a key is added for 'word' and translations for each language
// code that we support
const useI18n = () => {
  const { formatMessage } = useIntl();
  return (message, options) => (
    formatMessage({ id: message, defaultMessage: message }, options)
  );
};

export default useI18n;
