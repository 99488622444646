import React from 'react'
import styles from './styles.module.scss';
import {
    ChevronRight,
    TicketClock,
    OneDot,
    TicketSignedOff,
    TicketNotSignedOff
} from 'common-ui/Icon';
import { SignedOffStatusMap } from '../../utils';
import { GetDisplayText } from '../../utils';
import { useGetRateAndQuantity, rateUnitOptions } from '../../../../utils/format/rateUnits'
import { useI18n } from 'internationalization';

type TicketCardProps = {
    match: Match,
    ticketID: string,
    startTime: string,
    endTime: string,
    number: string,
    status: string,
    quantity: string,
    unit: string,
    product: string,
    clickEvent: any,
    ticket: any,
    assignment: any,
}

const StatusIcon = ({ status }) => {
    if (status === SignedOffStatusMap.SignedOff) {
        return <TicketSignedOff />
    }
    return <TicketNotSignedOff />
}

const StatusText = ({ status, intlz }) => {
    if (status === SignedOffStatusMap.SignedOff) {
        return intlz("Signed Off")
    }
    return intlz("Not Signed Off")
}

const TicketCard = ({ match, ticketID, startTime, endTime, number, status, quantity, unit, product, clickEvent, ticket, assignment }: TicketCardProps) => {
    const intlz = useI18n();

    const [
        initialRateUnit,
        initialQuantity,
    ] = useGetRateAndQuantity(ticket, assignment);

    let ticketUnitLabel = '';
    const ticketUnit = rateUnitOptions.find(u => u.value === initialRateUnit);
    if (ticketUnit) {
        ticketUnitLabel = ticketUnit.label;
    }

    return (
        <div
            className={styles.ticketCardContainer}
            onClick={() => {
                clickEvent();
            }}
        >
            <div className={styles.time}>
                <TicketClock />
                {GetDisplayText(startTime)} - {GetDisplayText(endTime)}
            </div>
            <div className={styles.card}>
                <div className={styles.col1}>
                    <div className={styles.row1}>
                        {intlz('Ticket')} # {GetDisplayText(number)}
                        <div className={styles.status}>
                            <StatusIcon status={status} />
                            <div className={styles.statusText}>
                                <StatusText status={status} intlz={intlz} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.row2}>
                        {`${initialQuantity} ${ticketUnitLabel ? intlz(ticketUnitLabel) : ''}`}
                        <OneDot />
                        {GetDisplayText(product)}
                    </div>
                </div>
                <div className={styles.col2}>
                    <ChevronRight />
                </div>
            </div>
        </div>
    )
}

export default TicketCard;