// @flow
import React from 'react';
import moment from 'moment-timezone';
import { Tag } from 'common-ui/Labels';
import { Check } from 'common-ui/Icon';
import { useTimezone } from 'common-ui/Hooks';
import { useI18n } from 'internationalization';
import styles from './styles.module.scss';

type SignedOffTagProps = {
  date: string,
};

const SignedOffTag = ({ date }: SignedOffTagProps) => {
  const timezone = useTimezone();
  const intlz = useI18n();

  return (
    <Tag
      leftIcon={<Check />}
      text={`${intlz('Signed')} ${moment.tz(date, timezone).format('D-MMM-YY h:mma')}`}
      className={styles.signedTag}
    />
  );
};

export default SignedOffTag;
