import React from 'react';
import JobCard from '../JobCard/index';
import { GetComponentKey } from '../../utils';
import styles from './styles.module.scss';

type Ticket = {
  id: string,
}

type Job = {
  assignmentInstanceID: string,
  number: string,
  name: string,
  pickUp: string,
  dropOff: string,
  tickets: Ticket[],
  isSelfLogged: boolean,
}

type JobsProps = {
  match: Match,
  jobs: Job[],
}

const Jobs = ({ match, jobs }: JobsProps) => {
  return (
    <>
      <div className={styles.jobsContainer}>
        {jobs.map((job) => (
          <JobCard
            key={GetComponentKey()}
            assignmentInstanceID={job.assignmentInstanceID}
            match={match}
            number={job.number}
            name={job.name}
            pickUp={job.pickUp}
            dropOff={job.dropOff}
            product={job.product}
            tickets={job.tickets}
            isSelfLogged={job.isSelfLogged}
          />
        ))}
      </div>
    </>
  );
};

export default Jobs;
