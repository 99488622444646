/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { forwardRef, type Node } from 'react';
import classNames from 'classnames';
import {
  Check,
  ChevronLeft,
  Close,
  Trash,
  Sort,
} from '../Icon';

import styles from './styles.module.scss';

export type StyleName = 'primary' | 'neutral' | 'destructive' | 'negative' | 'secondary' | 'selected' | 'attachmentButton' | 'default';

const classForStyle = (styleName?: StyleName): string => {
  switch (styleName) {
    case 'primary':
      return styles.primary;
    case 'neutral':
      return styles.neutral;
    case 'attachmentButton':
        return styles.attachmentButton;
    case 'destructive':
      return styles.destructive;
    case 'negative':
      return styles.negative;
    case 'secondary':
      return styles.secondary;
    case 'selected':
      return styles.selected;
    default:
      return styles.default;
  }
};

const iconForStyle = (styleName?: StyleName): Node => {
  switch (styleName) {
    case 'primary':
      return <Check />;
    case 'destructive':
      return <Trash />;
    case 'negative':
      return <Close />;
    default:
      return null;
  }
};

type ButtonProps = {
  icon?: Node,
  text: string | Node,
  styleName?: StyleName,
  className?: string,
  rightIcon?: boolean,
  fullWidth?: boolean,
  large?: boolean,
  id?: string,
  wantIconForStyle?: boolean, // default true so if you are using style tag you will get an icon
  type?: string,
};

export const Button = forwardRef(({
  icon,
  text,
  styleName,
  className,
  rightIcon,
  fullWidth,
  large,
  id,
  wantIconForStyle,
  type,
  ...inputProps
}: ButtonProps, ref) => {
  const buttonIcon = icon || (wantIconForStyle && iconForStyle(styleName));
  return (
    <button
      id={id}
      className={classNames(
        styles.button,
        classForStyle(styleName),
        className,
        {
          [styles.fullWidth]: fullWidth,
          [styles.large]: large,
        },
      )}
      type={type}
      background=""
      ref={ref}
      {...inputProps}
    >
      {buttonIcon && !rightIcon && (
        <div className={classNames(styles.buttonIcon)}>
          {buttonIcon}
        </div>
      )}
      <span className={styles.buttonLabel}>{text}</span>
      {buttonIcon && rightIcon && (
        <div className={classNames(styles.buttonIcon, styles.right)}>
          {buttonIcon}
        </div>
      )}
    </button>
  );
});

Button.defaultProps = {
  icon: null,
  styleName: undefined,
  className: undefined,
  rightIcon: false,
  fullWidth: false,
  large: false,
  id: undefined,
  wantIconForStyle: true,
  type: 'button',
};

type SmallButtonProps = {
  icon?: Node,
  text?: string | Node,
  styleName?: StyleName,
  className?: string,
  id?: string,
};

export const SmallButton = ({
  icon,
  text,
  styleName,
  className,
  id,
  ...inputProps
}: SmallButtonProps) => {
  const buttonIcon = icon || iconForStyle(styleName);

  return (
    <button
      id={id}
      className={classNames(
        styles.smallButton,
        classForStyle(styleName),
        className,
      )}
      type="button"
      {...inputProps}
    >
      {buttonIcon && <div className={styles.smallButtonIcon} background="">{buttonIcon}</div>}
      {text && <span className={styles.smallButtonLabel}>{text}</span>}
    </button>
  );
};

SmallButton.defaultProps = {
  icon: null,
  text: '',
  styleName: undefined,
  className: undefined,
  id: undefined,
};

type BackButtonTransparentProps = {
  text?: string,
  className?: string,
  onClick?: () => void | null,
};

export const BackButtonTransparent = ({
  text,
  className,
  onClick,
}: BackButtonTransparentProps) => (
  <button
    className={classNames(
      styles.smallButton,
      styles.backButtonTransparentButtonElement,
      className,
    )}
    type="button"
    onClick={onClick}
  >
    <div className={classNames(styles.smallButtonIcon, styles.backButtonTransparentContainer)}>
      <ChevronLeft
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 -3 6 28"
        className={styles.backButtonTransparentLeftArrow}
      />
      <span className={classNames(styles.smallButtonLabel, styles.backButtonTransparentLabelText)}>
        {text}
      </span>
    </div>
  </button>
);

BackButtonTransparent.defaultProps = {
  text: 'Back',
  className: '',
  onClick: () => {},
};

type BackButtonProps = {
  text: string,
};


export const BackButton = ({
  text,
  ...inputProps
}: BackButtonProps) => (
  <SmallButton text={text} icon={<ChevronLeft />} styleName="secondary" {...inputProps} />
);

type SortButtonProps = {
};

export const SortButton = ({
  ...inputProps
}: SortButtonProps) => (
  <SmallButton icon={<Sort />} {...inputProps} />
);

type CloseButtonProps = {};

type TextOnlyButtonProps = {
  text: string,
  styleName?: StyleName,
  className?: string,
  buttonIcon?: Node,
  onClick?: () => void,
};

export const TextOnlyButton = ({
  text,
  styleName,
  className,
  buttonIcon,
  onClick,
  ...props
}: TextOnlyButtonProps) => (
  <button
    className={classNames(
      styles.smallButton,
      styles.smallTextOnlyButtonLabel,
      styles.textOnlyButtonElement,
      classForStyle(styleName),
      className,
    )}
    type="button"
    onClick={onClick}
    {...props}
  >
    {buttonIcon && (
    <div className={classNames(styles.buttonIcon)}>
      {buttonIcon}
    </div>
    )}
    {text}
  </button>
);

TextOnlyButton.defaultProps = {
  styleName: undefined,
  className: '',
  onClick: undefined,
  buttonIcon: undefined,
};


export const CloseButton = ({
  ...inputProps
}: CloseButtonProps) => (
  <SmallButton styleName="negative" {...inputProps} />
);

type MinimalButtonProps = {
  className?: string,
};

export const MinimalButton = ({
  className,
  ...inputProps
}: MinimalButtonProps) => (
  <button
    className={classNames(
      styles.minimal,
      className,
    )}
    type="button"
    {...inputProps}
  />
);

MinimalButton.defaultProps = {
  className: undefined,
};

type DoubleIconButtonProps = {
  icon?: Node,
  text: string | Node,
  styleName?: StyleName,
  className?: string,
  secondIcon?: Node,
  id?: string;
};

export const DoubleIconButton = ({
  icon,
  text,
  styleName,
  className,
  secondIcon,
  id,
  ...inputProps
}: DoubleIconButtonProps) => {
  const buttonIcon = icon || iconForStyle(styleName);
  return (
    <button
      id={id}
      className={classNames(
        styles.button,
        classForStyle(styleName),
        className,
      )}
      type="button"
      {...inputProps}
      background=""
    >
      {buttonIcon && (
        <div className={classNames(styles.buttonIcon)}>
          {buttonIcon}
        </div>
      )}
      <span className={styles.buttonLabel}>{text}</span>
      {secondIcon && (
        <div className={classNames(styles.buttonIcon, styles.right)}>
          {secondIcon}
        </div>
      )}
    </button>
  );
};

DoubleIconButton.defaultProps = {
  icon: null,
  styleName: undefined,
  className: undefined,
  secondIcon: null,
  id: undefined,
};
