/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'formik';
import classNames from 'classnames';
import styles from './styles.module.scss';

class Input extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    hint: PropTypes.string,
    dollar: PropTypes.bool,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
  };

  static defaultProps = {
    dollar: false,
  };

  render() {
    const {
      className,
      labelClassName,
      label,
      hint,
      dollar,
      formik,
      name,
      ...rest
    } = this.props;
    // form states
    const value = formik.values[name] || '';
    const touched = formik.touched[name];
    const error = formik.errors[name];

    // determine container class
    let containerClass = styles.inputText;
    containerClass = `${containerClass} ${dollar ? styles.inputDollar : ''}`;

    const shouldShowError = touched && !!error;
    if (shouldShowError) {
      containerClass += ` ${
        error ? styles.error : styles.success
      }`;
    }

    // render input
    return (
      <div className={classNames(containerClass, className)}>
        <div className={styles.labelRow}>
          {!!label
            && (
            <span className={classNames(styles.textLabel, labelClassName)}>
              {label}
            </span>
            )}
          {!!hint
          && (
            <span className={styles.textHint}>
              {hint}
            </span>
          )}
        </div>
        <div className={styles.input}>
          <input
            {...{ value, name }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...rest}
          />
          {!!dollar && <div className={styles.dollar}>$</div>}
        </div>
        {shouldShowError && <div className={styles.errorMessage}>{error}</div>}
      </div>
    );
  }
}

export default connect(Input);
