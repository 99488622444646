import React from 'react';

const Switch = (props) => (
  <svg width={24} height={23} viewBox="0 0 24 23" {...props}>
    <path
      d="M9.065 3.632L7.518 2.066a1.216 1.216 0 0 1 0-1.711 1.197 1.197 0 0 1 1.7 0l3.608 3.631c.47.473.47 1.24 0 1.712L9.22 9.33c-.47.472-1.231.472-1.7 0a1.216 1.216 0 0 1 0-1.712l1.546-1.565H7.814c-2.985 0-5.41 2.44-5.41 5.447 0 2.823 2.518 5.447 4.807 5.447.664 0 1.203.542 1.203 1.21 0 .67-.539 1.211-1.203 1.211C3.574 19.368 0 15.641 0 11.5c0-4.344 3.501-7.868 7.814-7.868h1.251zm5.821 15.736l1.547 1.566c.47.472.47 1.239 0 1.711-.47.473-1.23.473-1.7 0l-3.607-3.631a1.216 1.216 0 0 1 0-1.712l3.607-3.632a1.197 1.197 0 0 1 1.7 0c.47.473.47 1.24 0 1.712l-1.547 1.565h1.3c2.985 0 5.41-2.44 5.41-5.447 0-2.823-2.518-5.447-4.807-5.447a1.206 1.206 0 0 1-1.203-1.21c0-.67.539-1.211 1.203-1.211C20.426 3.632 24 7.359 24 11.5c0 4.344-3.501 7.868-7.814 7.868h-1.3z"
      fill="#000B9D"
      fillRule="evenodd"
    />
  </svg>
);

export default Switch;
