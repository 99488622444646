import React from 'react';
import styles from './styles.module.scss';
import { GetComponentKey } from '../utils';
import TimesheetsCard from '../TimesheetCard/TimesheetsCard';

type TimesheetsCardProps = {
  id: string,
  company: string,
  time: string,
  lastDropOff: string,
  signedOffStatus: string,
};

type TimesheetsByDateProps = {
  date: string,
  timesheets: TimesheetsCardProps[],
};

const TimesheetsByDate = ({
  date,
  timesheets,
}: TimesheetsByDateProps) => (
  <>
    <div className={styles.title}>
      {date}
    </div>
    <div className={styles.timesheetsContainer}>
      {timesheets.map((ts) => (
        <TimesheetsCard
          key={GetComponentKey()}
          id={ts.id}
          company={ts.company}
          companyID={ts.companyID}
          time={ts.time}
          lastDropOff={ts.lastDropOff}
          signedOffStatus={ts.signedOffStatus}
        />
      ))}
    </div>
  </>
);

export default TimesheetsByDate;
