// @flow
import moment from 'moment-timezone';
import { useMemo, useRef, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { SignedOffStatusMap, FetchPolicy } from '../utils';

export const TIMESHEETS_QUERY = gql`
query UpdatedDriverTimesheets($timesheetsInput: TimesheetsInput!, $limit: Int, $offset: Int) {
  updatedDriverTimesheets(timesheetsInput: $timesheetsInput, limit: $limit, offset: $offset) {
    timesheetCount
    timesheets {
      id
      scheduledStart
      dropoff
      signoffStatus
      companyName
      companyId
      totalTime
    }
  }
}
`;

export const PAGE_LIMIT = 10;

export const useTimesheets = (
  companyIDs: string[],
  page: number,
  { skip, query, ...overrides }: any = {},
) => {
  // Fetch from network on initial fetch
  const shouldFetchNetwork = useRef(true);

  const sortBy = 'Date';
  const sortOrder = 'DESC';

  if (overrides.fetchPolicy) {
    shouldFetchNetwork.current = overrides.fetchPolicy === FetchPolicy.Network;
  }

  const { loading, error, data } = useQuery(query || TIMESHEETS_QUERY, {
    variables: {
      timesheetsInput: {
        companyID: companyIDs[0],
        sortBy,
        sortOrder,
      },
      limit: PAGE_LIMIT,
      offset: PAGE_LIMIT * (page - 1),
    },
    skip: !companyIDs || companyIDs.length === 0 || skip,
    fetchPolicy: shouldFetchNetwork.current ? 'network-only' : 'cache-first',
    ...overrides,
  });

  // Once the data has initially been loaded from the network, fetch from the cache
  useEffect(() => { if (loading) shouldFetchNetwork.current = false; }, [loading]);
  useEffect(() => { shouldFetchNetwork.current = true; }, [companyIDs]);

  const tsData = {
    total: 0,
    timesheetsGroupByDate: [],
  };
  if (!loading && data && data.updatedDriverTimesheets) {
    const tsWithFormattedDate = [];
    data.updatedDriverTimesheets.timesheets.forEach((ts) => {
      const newTs = { ...ts };
      const momentDate = overrides.timezone ? moment(ts.scheduledStart).tz(overrides.timezone) : moment(ts.scheduledStart);
      newTs.scheduledStart = momentDate.format('MMM DD, YYYY');
      tsWithFormattedDate.push(newTs);
    });

    const uniqueDates = [...new Set(tsWithFormattedDate.map((ts) => ts.scheduledStart))];

    const tsGroupByDate = uniqueDates.map((d) => {
      const tsByDate = tsWithFormattedDate.filter((ts) => ts.scheduledStart === d);
      return {
        date: d,
        timesheets: tsByDate,
      };
    });

    const getLatestDropOffSiteName = (ts) => {
      const ais = ts.assignmentInstances;
      const aisWithDate = ais.map((x) => {
        const letX = { ...x };
        letX.loggingStop = new Date(x.loggingStop);
        return letX;
      });

      const sortedAis = aisWithDate.slice().sort((a, b) => b.loggingStop - a.loggingStop);

      if (sortedAis[0].assignment.dropOffSite) {
        return sortedAis[0].assignment.dropOffSite.name;
      }

      return '';
    };

    const getSignedOffStatus = ({ signoff, signoffStatus }) => {
      if (signoffStatus && signoffStatus.toUpperCase() === 'REQUESTED' && !signoff) return SignedOffStatusMap.SignOffRequested;
      if (signoff && !signoff.deleted) return SignedOffStatusMap.SignedOff;
      return SignedOffStatusMap.NotSignedOff;
    };

    const allTs = tsGroupByDate.map((ts) => {
      const tsListForDate = ts.timesheets.map((t) => ({
        id: t.id,
        companyID: t.companyId,
        company: t.companyName,
        time: t.totalTime > 0 ? moment.utc((t.totalTime * 60) * 1000).format('HH [Hrs] mm [Mins]') : '0 Hrs 0 Mins',
        lastDropOff: t.dropoff,
        signedOffStatus: t.signoffStatus,
      }));
      return {
        date: ts.date,
        timesheets: tsListForDate,
      };
    });

    tsData.total = data.updatedDriverTimesheets.timesheetCount;
    allTs.forEach((x) => tsData.timesheetsGroupByDate.push(x));
  }

  return useMemo(() => {
    const meta = { loading, error };
    if (loading || error || !tsData) return [[], meta];
    return [tsData, meta];
  }, [loading, error, tsData]);
};

export default useTimesheets;
