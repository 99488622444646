/* global google */

// expects parent to import this
// <script src="https://maps.googleapis.com/maps/api/js?key=[API_KEY]&libraries=places"></script>

import React, { Component } from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";
import { connect } from 'formik';

class AddressInput extends Component {
  static contextTypes = {
    formik: PropTypes.object
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    hint: PropTypes.string,
    icon: PropTypes.object,
    label: PropTypes.string
  };

  static defaultProps = {
    hint: null,
    icon: null,
    label: null
  };

  componentDidMount() {
    const input = this.inputRef;
    const options = {
      types: ["address"],
      componentRestrictions: { country: "ca" }
    };
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.setTypes(["address"]);
    const infowindow = new google.maps.InfoWindow();
    this.autocomplete.addListener("place_changed", () => {
      infowindow.close();
      const place = this.autocomplete.getPlace();
      this.handlePlaceChange(place);
    });
  }

  parseGoogleAddressComponent = addressComponents => {
    const address = {};
    const map = {
      street_number: "street",
      route: "street",
      locality: "city",
      administrative_area_level_1: "state",
      postal_code: "zip",
      country: "country"
    };
    for (let ii = 0; ii < addressComponents.length; ii += 1) {
      for (let xx in map) {
        if (addressComponents[ii].types.indexOf(xx) > -1) {
          if (
            (xx === "street_number" || xx === "route") &&
            address.street !== undefined
          ) {
            if (xx === "street_number") {
              address.street = `${addressComponents[ii].short_name} ${
                address.street
              }`;
            } else if (xx === "route") {
              address.street = `${address.street} ${
                addressComponents[ii].short_name
              }`;
            }
          } else if (xx === "locality") {
            address[map[xx]] = addressComponents[ii].long_name;
          } else {
            address[map[xx]] = addressComponents[ii].short_name;
          }
        }
      }
    }
    return address;
  };

  handlePlaceChange = place => {
    if (!!place.address_components) {
      const address = this.parseGoogleAddressComponent(
        place.address_components
      );
      address.lat = place.geometry.location.lat();
      address.lng = place.geometry.location.lng();
      address.fullAddress = place.formatted_address;

      const { name, formik } = this.props;
      formik.setFieldTouched(name, true);
      formik.setFieldValue(name, address);
      return;
    }

    // If the user enters the name of a Place that was not suggested by the control
    // and presses the Enter key, or if a Place detail request fails, a place_changed
    // event will be fired that contains the user input in the name property, with no
    // other properties defined.
    if (!!place.name) {
      const { name, formik } = this.props;
      formik.setFieldTouched(name, true);
      formik.setFieldValue(name, { fullAddress: place.name });
    }
  };

  handleTextChange = e => {
    const { name, formik } = this.props;
    formik.setFieldTouched(name, true);
    formik.setFieldValue(name, { fullAddress: e.currentTarget.value });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  render() {
    const { name, className, label, hint, icon, ...rest } = this.props;
    const { formik } = this.props;

    // form states
    const value = formik.values[name];
    const touched = formik.touched[name];
    const error = formik.errors[name];

    // get input value to display
    let inputValue = "";
    if (!!value && !!value.fullAddress) {
      inputValue = value.fullAddress;
    }

    // determine container class
    let containerClass = styles.inputText;
    let shouldShowError = touched && !!error;
    if (shouldShowError) {
      containerClass = `${containerClass} ${
        !!error ? styles.error : styles.success
      }`;
    }

    return (
      <div className={containerClass}>
        <div className={styles.labelRow}>
          {!!label && <span className={styles.textLabel}>{label}</span>}
          {!!hint && <span className={styles.textHint}>{hint}</span>}
        </div>
        <div className={styles.input}>
          <input
            name={name}
            value={inputValue}
            onChange={this.handleTextChange}
            onBlur={formik.handleBlur}
            onKeyPress={this.handleKeyPress}
            ref={c => (this.inputRef = c)}
            {...rest}
          />
        </div>
        {shouldShowError && <div className={styles.errorMessage}>{error}</div>}
      </div>
    );
  }
}

export default connect(AddressInput);
