import React from 'react';

type DashedClockPropTypes = {
  height?: number,
  width?: number,
};

const DashedClock = (props: DashedClockPropTypes) => (
  <svg width={props.width} height={props.height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6214_1372)">
      <path d="M10.4584 18.4247C9.06675 18.4247 7.67508 18.0913 6.44175 17.458C6.03341 17.2497 5.87508 16.7413 6.08342 16.333C6.29175 15.9247 6.80008 15.7663 7.20842 15.9747C8.21675 16.4913 9.31675 16.758 10.4667 16.758C14.4001 16.758 17.5917 13.558 17.5917 9.63301C17.5917 5.70801 14.3917 2.49967 10.4584 2.49967C6.52508 2.49967 3.33341 5.69967 3.33341 9.62467C3.33341 10.083 2.95841 10.458 2.50008 10.458C2.04175 10.458 1.66675 10.083 1.66675 9.62467C1.66675 4.77467 5.60841 0.833008 10.4584 0.833008C15.3084 0.833008 19.2501 4.77467 19.2501 9.62467C19.2501 14.4747 15.3084 18.4247 10.4584 18.4247Z" fill="#767676" />
      <path d="M13.8169 14.2827C13.5836 14.2827 13.3586 14.1911 13.1919 13.9994L9.62524 9.9494V4.6744C9.62524 4.21606 10.0002 3.84106 10.4586 3.84106C10.9169 3.84106 11.2919 4.21606 11.2919 4.6744V9.31607L14.4419 12.8994C14.7502 13.2411 14.7086 13.7744 14.3669 14.0744C14.2086 14.2077 14.0086 14.2827 13.8169 14.2827Z" fill="#767676" />
      <path d="M4.90016 16.1162C5.3512 16.1162 5.71683 15.7506 5.71683 15.2996C5.71683 14.8485 5.3512 14.4829 4.90016 14.4829C4.44913 14.4829 4.0835 14.8485 4.0835 15.2996C4.0835 15.7506 4.44913 16.1162 4.90016 16.1162Z" fill="#767676" />
      <path d="M3.50832 14.2666C3.95935 14.2666 4.32498 13.901 4.32498 13.45C4.32498 12.9989 3.95935 12.6333 3.50832 12.6333C3.05728 12.6333 2.69165 12.9989 2.69165 13.45C2.69165 13.901 3.05728 14.2666 3.50832 14.2666Z" fill="#767676" />
    </g>
    <defs>
      <clipPath id="clip0_6214_1372">
        <rect width="17.5917" height="17.5917" fill="white" transform="translate(1.66675 0.833252)" />
      </clipPath>
    </defs>
  </svg>
);

DashedClock.defaultProps = {
  width: 20,
  height: 20,
};

export default DashedClock;
