export const readFileAsURL = (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    try {
      reader.addEventListener('load', (e) => {
        resolve(e.target.result);
      });
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
};

export const IsImage = (file) => /^image\/.*/.test(file.type);

export const LoadImage = (dataURL) => new Promise((resolve, reject) => {
  try {
    const img = new Image();
    img.addEventListener('load', () => {
      resolve(img);
    });
    img.src = dataURL;
  } catch (err) {
    reject(err);
  }
});

export const ResizeCompressImage = (img) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Cribbed from https://hacks.mozilla.org/2011/01/how-to-develop-a-html5-image-uploader/
  const MAX_WIDTH = 1600;
  const MAX_HEIGHT = 1200;

  let { width, height } = img;

  if (width > height && width > MAX_WIDTH) {
    height *= MAX_WIDTH / width;
    width = MAX_WIDTH;
  } else if (height > MAX_HEIGHT) {
    width *= MAX_HEIGHT / height;
    height = MAX_HEIGHT;
  }

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(img, 0, 0, width, height);

  return canvas;
};

export const ImgOrientation = (file) => new Promise((resolve, reject) => {
  try {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const scanner = new DataView(fileReader.result);
      let idx = 0;
      let orientation = 1; // Non-rotated is the default
      if (fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xffd8) {
        // Not a JPEG
        resolve(orientation);
        return;
      }
      idx += 2;
      let maxBytes = scanner.byteLength;
      while (idx < maxBytes - 2) {
        const uint16 = scanner.getUint16(idx);
        idx += 2;
        switch (uint16) {
          case 0xffe1: {
            // Start of EXIF
            const exifLength = scanner.getUint16(idx);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          }
          case 0x0112: {
            // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            orientation = scanner.getUint16(idx + 6, false);
            maxBytes = 0; // Stop scanning
            break;
          }
          default:
            break;
        }
      }
      resolve(orientation);
    };
    fileReader.readAsArrayBuffer(file);
  } catch (err) {
    reject(err);
  }
});

export const ImgOrientationCSS = {
  1: 'rotate(0deg)',
  3: 'rotate(180deg)',
  6: 'rotate(90deg)',
  8: 'rotate(270deg)',
};

export const CompressIfImage = (file) => new Promise((resolve, reject) => {
  const resolveBlob = (blob) => {
    blob.name = file.name;
    resolve(blob);
  };

  if (!IsImage(file)) {
    resolve(file);
  }
  readFileAsURL(file)
    .then((imgURL) => LoadImage(imgURL).then((img) => {
      const canvas = ResizeCompressImage(img);

      canvas.toBlob(resolveBlob, 'image/jpeg', 0.9);
    }))
    .catch((err) => {
      reject(err);
    });
});
