import React from 'react';

const PersonPlaceholder = props => (
  <svg width={28} height={17} viewBox="0 0 28 17" {...props}>
    <g fill="#D8D8D8" fillRule="evenodd">
      <path d="M13.153 17c2.241 0 3.414-.987 3.54-1.099.369-.328.368-.853.005-1.187-.362-.333-.964-.341-1.345-.024-.03.024-.75.61-2.2.61-1.43 0-2.15-.569-2.199-.609a1.04 1.04 0 0 0-1.34.008.785.785 0 0 0 0 1.202c.125.112 1.298 1.099 3.54 1.099z" />
      <path
        d="M4.917 9.35h2.859c1.253.001 2.424-.65 3.115-1.734l1.587-2.46c.211-.334.361-.705.443-1.096a2.188 2.188 0 0 1 2.115 0c.082.39.232.762.443 1.097l1.587 2.46c.693 1.081 1.863 1.732 3.116 1.733h2.944C25.818 9.35 28 7.088 28 4.297v-.963C28.024 1.54 26.657.057 24.927 0h-6.804a3.004 3.004 0 0 0-2.215.934c-.21.235-.388.5-.529.785a4.392 4.392 0 0 0-2.73 0 3.497 3.497 0 0 0-.543-.785A3.004 3.004 0 0 0 9.906 0H3.158C1.418.008.009 1.47 0 3.275v.963a5.15 5.15 0 0 0 1.423 3.627A4.787 4.787 0 0 0 4.917 9.35z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default PersonPlaceholder;
