import React from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";

const Heading = ({ children, className, medium, small, ...props }) => {
  let style = styles.textTitle;

  if (!!medium) {
    style = styles.textHeading;
  } else if (!!small) {
    style = styles.textSubheading;
  }

  if (!!className) {
    style = style.concat(" " + className);
  }

  return (
    <div className={style} {...props}>
      {children}
    </div>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node
};

Heading.defaultProps = {
  medium: false,
  small: false
};

export default Heading;
