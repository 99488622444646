// @flow
import React from 'react';
import { connect } from 'formik';
import { get, includes } from 'lodash';
import { FormField, Checkbox } from '..';
import styles from './styles.module.scss';

type FormikCheckboxGroupProps = {
  formik: any,
  label: string,
  required?: boolean,
  options: { label: string, value: string }[],
  name: string,
  readOnly?: boolean,
  onChange?: () => {},
  id?: string,
  hint?: string,
};

const FormikCheckboxGroup = ({
  formik,
  label,
  required,
  options,
  name,
  readOnly,
  onChange,
  id,
  hint,
  ...inputProps
}: FormikCheckboxGroupProps) => (
  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
    hint={hint}
  >
    <div className={styles.checkboxGroupContainer}>
      {options.map((option) => (
        <Checkbox
          id={id}
          key={option.label}
          label={option.label}
          name={name}
          checked={get(formik.values, name) && includes(formik.values[name], option.value)}
          onChange={(checked) => {
            // Sets are used to easily delete/add the checked box
            const boxes = formik.values[name] ? new Set(formik.values[name]) : new Set();
            if (checked) {
              boxes.add(option.value);
            } else {
              boxes.delete(option.value);
            }
            // This was to be converted back to a list so that
            // formik comparison works. There is a bug in one of the
            // current dependencies when comparing sets.
            // https://github.com/epoberezkin/fast-deep-equal/issues/50
            const newBoxes = Array.from(boxes);

            formik.setFieldTouched(name);
            formik.setFieldValue(name, newBoxes);
            if (onChange) onChange(checked);
          }}
          readOnly={readOnly}
          {...inputProps}
        />
      ))}
    </div>
  </FormField>
);

FormikCheckboxGroup.defaultProps = {
  required: false,
  readOnly: false,
  id: undefined,
  onChange: () => {},
  hint: null,
};

export default connect(FormikCheckboxGroup);
