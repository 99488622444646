// @flow
import _ from 'lodash';
import {
  compose, withHandlers, withStateHandlers, type HOC,
} from 'recompose';
/* eslint-disable no-underscore-dangle */
import overrideSetStateHandlerToCheckIsMounted from './overrideSetStateHandlerToCheckIsMounted';

// Handle Promisable State Handlers
// Due to the nature of withStateHandlers, we must fake the state parameters,
// and instead just pass in the props, which includes the initialState,
// as the state function
const withPromisableStateHandlers = (
  initialState: {},
  functions: {},
): HOC<*, *> => {
  const funcs = {};
  // Wrap Functions to set State after promises are resolved
  _.forOwn(functions, (value, key) => {
    funcs[key] = props => (...theArgs) => Promise.resolve(value(props, props)(...theArgs))
      .then((results) => {
        props.setState(results);
      });
  });

  return compose(
    withStateHandlers(initialState, {
      getState: state => () => state,
      setState: () => result => result,
    }),
    overrideSetStateHandlerToCheckIsMounted,
    withHandlers(funcs),
  );
};

export default withPromisableStateHandlers;
