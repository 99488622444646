import React from 'react';
import styles from './styles.module.scss';

const TopNav = () => (
  <div>
    {process.env.REACT_APP_ENV !== 'production' && (
      <div className={styles.environment}>
        <span>{process.env.REACT_APP_ENV === 'beta' ? 'demo' : process.env.REACT_APP_ENV}</span>
      </div>
    )}
  </div>
);

export default TopNav;
