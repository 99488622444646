import { gql } from '@apollo/client';

// Device
export const DEVICE_QUERY = gql`
  query DeviceQuery {
    device @client {
      isLogging
    }
  }
`;

export const SET_DEVICE_IS_LOGGING = gql`
  mutation SetDeviceIsLogging($isLogging: Boolean!) {
    setDeviceIsLogging(isLogging: $isLogging) @client {
      isLogging
    }
  }
`;

// Notification
export const RETRIEVE_NOTIFICATION = gql`
  query notificationStateQuery {
    notification @client {
      message
      type
    }
  }
`;

export const SET_NOTIFICATION = gql`
  mutation setNotification($type: String!, $message: String) {
    setNotification(type: $type, message: $message) @client {
      message
      type
    }
  }
`;
