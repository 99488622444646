import React from 'react';
import { Button } from 'common-ui/Button';
import { useI18n } from 'internationalization';
import {
  Receipt,
} from 'common-ui/Icon';
import styles from './styles.module.scss';
import { CardDivider } from '../../../../common-ui/Cards';
import { Link } from 'react-router-dom';
import { GetDisplayText } from '../../utils';

type Ticket = {
  id: string,
}

type JobCardProps = {
  match: Match,
  assignmentInstanceID: string,
  number: string,
  name: string,
  pickUp: string,
  dropOff: string,
  product: string,
  tickets: Ticket[],
  isSelfLogged: boolean,
};

const JobCard = ({
  match, assignmentInstanceID, number, name, pickUp, dropOff, product, tickets, isSelfLogged
}: JobCardProps) => {
  const intlz = useI18n();
  const timesheetID = match.params.id;
  const companyID = match.params.companyId;
  return (
    <>
      <div className={styles.jobCardContainer}>
        {isSelfLogged ? (
          <div className={styles.selfLoggedBadge}>
            Self Logged
          </div>
        ) : (<></>)}
        <div className={styles.jobInfo}>
          <div className={styles.numberText}>
            {`${intlz('Job No.')} ${GetDisplayText(number)}`}
          </div>
          <div className={styles.jobInfoProps}>
            <div className={styles.label}>
              {intlz('Name')} :
            </div>
            <div>
              {GetDisplayText(name)}
            </div>
          </div>

          <div className={styles.jobInfoProps}>
            <div className={styles.label}>
              {intlz('Pick Up')} :
            </div>
            <div>
              {GetDisplayText(pickUp)}
            </div>
          </div>

          <div className={styles.jobInfoProps}>
            <div className={styles.label}>
              {intlz('Drop Off')} :
            </div>
            <div>
              {GetDisplayText(dropOff)}
            </div>
          </div>

          <div className={styles.jobInfoProps}>
            <div className={styles.label}>
              {intlz('Product')} :
            </div>
            <div>
              {GetDisplayText(product)}
            </div>
          </div>

        </div>

        <CardDivider className={styles.divider} />

        <div className={styles.tickets}>
          <div className={styles.ticketsCountText}>
            {`${tickets.length} ${intlz('Tickets')}`}
          </div>

          <Link key={timesheetID} to={`/timesheets/${companyID}/${timesheetID}/tickets/${assignmentInstanceID}`}>
            <Button
              text={intlz('View Tickets')}
              icon={<Receipt />}
              className={styles.button}
              fullWidth
            />
          </Link>

        </div>
      </div>
    </>
  );
};

export default JobCard;
