import React from 'react';
import { withRouter, matchPath, Link } from 'react-router-dom';
import { Badge } from 'OldCommon/Badge';
import { ContextSwitcher } from 'common-ui/ContextSwitcher';
import { Settings, Dispatcher, DashedClock, Receipt } from 'common-ui/Icon';
import { IntercomLauncher } from 'common-ui/Hooks/Intercom';
import { PENDING } from 'utils/constants/job';
import { useI18n } from 'internationalization';
import { useGetAllAssignments } from 'Jobs/graphql';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import styles from './styles.module.scss';

const JobsIconWithBadge = (pendingAssignmentsCount) => {
  const badgeNumber = Number(pendingAssignmentsCount.pendingAssignmentsCount) || 0;
  return (
    <span className={styles.jobsIconContainer}>
      <Dispatcher />
      {badgeNumber !== 0 && (
        <div className={styles.pendingAssignmentsBadge}>
          <Badge count={badgeNumber} alert />
        </div>
      )}
    </span>
  );
};

const JobTopNav = withRouter(({ location, history }) => {
  const [assignments] = useGetAllAssignments();
  const pendingAssignmentsCount = assignments.filter((a) => a.sendStatus === PENDING).length;
  const intlz = useI18n();

  const toggleOptions = [
    { label: `${intlz('Jobs')}`, value: '/jobs', icon: <JobsIconWithBadge pendingAssignmentsCount={pendingAssignmentsCount} /> },
    { label: `${intlz('History')}`, value: '/receipts', icon: <DashedClock height={25} width={25} /> },
    { label: `${intlz('Timesheets')}`, value: '/timesheets', icon: <Receipt /> },
  ];

  return (
    <div className={styles.topNavBar}>
      <div className={styles.navSize}>
        <ContextSwitcher
          onChange={(val) => {
            history.push(val);
            if (val === '/receipts') {
              GA.trackClick(GAActions.receipts, 'Receipts');
            }
          }}
          selectedValue={
            location.pathname
          }
          options={toggleOptions}
          isOperatorStyle
        />
      </div>
      <div className={styles.settingsLink}>
        <IntercomLauncher mobile />
      </div>
      <Link to="/settings" className={styles.settingsLink} onClick={() => GA.trackClick(GAActions.settings)}>
        <Settings />
      </Link>
    </div>
  );
});

export default JobTopNav;
