// @flow

import React, { Component } from 'react';

type WithPromisableStateHandlersHOCProps = {
  setState: (result: any) => void
};

/* eslint-disable no-underscore-dangle */
const overrideSetStateHandlerToCheckIsMounted = (WrappedComponent: any) => (
  class WithPromisableStateHandlersHOC extends Component<
    WithPromisableStateHandlersHOCProps,
    *
  > {
    _isMounted = false;

    constructor(props: WithPromisableStateHandlersHOCProps) {
      super(props);
      this.setState = this.setState.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    // In order to handle promises calling setState,
    // we need to check if it's still mounted before calling setState
    /* :: setState: (result: any) => void */
    setState(result: any) {
      if (this._isMounted) {
        const { setState } = this.props;
        setState(result);
      }
    }

    render() {
      return <WrappedComponent {...this.props} setState={this.setState} />;
    }
  });

export default overrideSetStateHandlerToCheckIsMounted;
