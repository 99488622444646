// @flow

import { Areas } from './Areas';
import { Types } from './Types';
import { Labels } from './Labels';
import create from './Create';

const ID = {
  Areas,
  Types,
  Labels,
  create,
};

export default ID;
