// @flow
import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { TIMESHEET_QUERY } from './useTimesheet';

const UPDATE_CONFIGURABLE_FIELDS_MUTATION = gql`
mutation updateTimesheetConfigurableFieldData($timesheetID: ID!, $input: TimesheetConfigurableFieldsInput!) {
  updateTimesheetConfigurableFieldData(timesheetID: $timesheetID, input: $input) {
    id
    configurableFields {
      id
      value
    }
  }
}
`;

const getFormattedInput = (values) => {
  const input = [];
  values.forEach((value) => {
    input.push({
      id: Number(value.id),
      value: value.value,
    });
  });
  return input;
};

const useUpdateTimesheetConfigurableFields = () => {
  const [updateConfigurableFields] = useMutation(UPDATE_CONFIGURABLE_FIELDS_MUTATION);
  return useCallback((
    timesheetID: any,
    values: Object[],
  ) => (
    updateConfigurableFields({
      variables: {
        timesheetID,
        input: {
          configurableFields: getFormattedInput(values),
        },
      },
      refetchQueries: [TIMESHEET_QUERY],
    })
  ), [updateConfigurableFields]);
};

export default useUpdateTimesheetConfigurableFields;
