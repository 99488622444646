import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import { Data } from 'common-ui/Icon';
import { DEVICE_QUERY } from 'apollo';
import { graphql } from '@apollo/client/react/hoc';
import { Notification } from 'OldCommon/Notification';

const NullableLoggingBanner = () => (
  <div>
    <Notification mobile logging leftIcon={<Data />} label="You're Logging" />
  </div>
);

export default compose(
  graphql(DEVICE_QUERY),
  branch(
    props => props.data.loading || !props.data.device || !props.data.device.isLogging,
    renderNothing,
  ),
)(NullableLoggingBanner);
