import 'moment/locale/es';

// This object lists out the various words/phrases used throughout the app
// and includes their translation in each supported language
const dictionary = {
  // Navigation
  Cancel: {
    es: 'Cancelar',
  },
  Back: {
    es: 'Atrás',
  },

  // Login
  'Sign In': {
    es: 'Iniciar Sesión',
  },
  Verification: {
    es: 'Verificación',
  },
  'Enter Code': {
    es: 'Introduzca el código',
  },
  'Phone Number': {
    es: 'Número de Teléfono',
  },
  'Get Code': {
    es: 'Obtener código',
  },
  '5-Digit Code': {
    es: 'Código de 5 dígitos',
  },
  Continue: {
    es: 'Continuar',
  },
  'Please enter a valid phone number.': {
    es: 'Por favor, introduce un número de teléfono válido.',
  },
  'Once you receive a text message with a 5-digit code, enter it here.': {
    es: 'Al recibir un mensaje de texto con un código de 5 dígitos, introdúcelo aquí.',
  },
  'Please enter your 5 digit code.': {
    es: 'Introduce tu código de activación (5 dígitos).',
  },

  // Jobs
  // Jobs Overview
  Jobs: {
    es: 'Trabajos',
  },
  Offers: {
    es: 'Ofertas',
  },
  'Your schedule is clear': {
    es: 'Tu agenda está libre',
  },
  'Accepted jobs will appear here': {
    es: 'Los trabajos aceptados aparecerán aquí',
  },
  'Created by You': {
    es: 'Creado por ti',
  },
  Yesterday: {
    es: 'Ayer',
  },
  Today: {
    es: 'Hoy',
  },
  Tomorrow: {
    es: 'Mañana',
  },
  Last: {
    es: 'Pasado',
  },

  // Job Details
  'Job Details': {
    es: 'Detalles del Trabajo',
  },
  'Self-Assigned': {
    es: 'Autoasignado',
  },
  'Your Tickets': {
    es: 'Tus Notas de Remisión',
  },
  'Add Attachments': {
    es: 'Añadir Adjuntos ',
  },
  'Add Another Attachment': {
    es: 'Añadir Otra Foto',
  },
  'Your Notes': {
    es: 'Tus Notas',
  },
  'ex. Delayed on the 401': {
    es: 'p. ej. Retrasado por tráfico intenso',
  },
  Client: {
    es: 'Cliente',
  },
  'Pick Up': {
    es: 'Recolección',
  },
  'Drop Off': {
    es: 'Destino',
  },
  'Assigned By': {
    es: 'Asignado por',
  },
  'Start Time': {
    es: 'Hora de Inicio',
  },
  'Job No.': {
    es: 'Número de trabajo',
  },
  Foreman: {
    es: 'Capataz',
  },
  Phase: {
    es: 'Fase',
  },
  Estimates: {
    es: 'Estimaciones',
  },
  Equipment: {
    es: 'Equipo',
  },
  Loads: {
    es: 'Cargas',
  },
  Product: {
    es: 'Material',
  },
  Rate: {
    es: 'Tarifa',
  },
  'Pay by Load': {
    es: 'Pagado por carga',
  },
  'Pay by Tonne': {
    es: 'Pagado por tonelada (Tm)',
  },
  'Pay by Ton': {
    es: 'Pagado por tonelada (US)',
  },
  'Pay by Yard': {
    es: 'Pagado por yarda',
  },
  'Pay by Meter': {
    es: 'Pagado por metro',
  },
  'Pay by Foot': {
    es: 'Pagado por pie',
  },
  'Pay by Liter': {
    es: 'Pagado por litro',
  },
  'Pay by Hour': {
    es: 'Pagado por hora',
  },
  'Pay by Wait Hour': {
    es: 'Pagado por hora de espera',
  },
  'Pay by Kilogram': {
    es: 'Pagado por kilogramo',
  },
  'Pay by Pound': {
    es: 'Pagado por libra',
  },
  'Pay by Bushel': {
    es: 'Pagado por bushel',
  },
  'Pay by Gallon': {
    es: 'Pagado por galón',
  },
  'Pay by Cubic Meter': {
    es: 'Pagado por metro cúbico',
  },
  'No rate provided': {
    es: 'No se proporcionó una tarifa',
  },
  Notes: {
    es: 'Notas',
  },
  'Project Notes': {
    es: 'Notas del Proyecto',
  },
  'Project Attachments': {
    es: 'Adjuntos del Proyecto',
  },
  'Get Directions': {
    es: 'Obtener Direcciones',
  },
  'Job Info': {
    es: 'Información',
  },
  Activity: {
    es: 'Actividades',
  },

  // Activity Stream
  'Started logging': {
    es: 'Comenzó a registrar',
  },
  'Stopped logging': {
    es: 'Dejó de registrar',
  },
  Signed: {
    es: 'Firmado',
  },
  'by {user}': {
    es: 'por {user}',
  },
  You: {
    es: 'Ti',
  },
  'Get Sign Off': {
    es: 'Obtener la Autorización',
  },
  Ticket: {
    es: 'Nota de remisión',
  },
  Quantity: {
    es: 'Cantidad',
  },
  Gross: {
    es: 'Bruto',
  },
  Tare: {
    es: 'Tara',
  },

  // Pay Units
  Load: {
    es: 'Carga',
  },
  Loads: {
    es: 'Cargas',
  },
  Tonne: {
    es: 'Tonelada (Tm)',
  },
  Tonnes: {
    es: 'Toneladas (Tm)',
  },
  Ton: {
    es: 'Tonelada (US)',
  },
  Tons: {
    es: 'Toneladas (US)',
  },
  Yard: {
    es: 'Yarda',
  },
  Yards: {
    es: 'Yardas',
  },
  Meter: {
    es: 'Metro',
  },
  Meters: {
    es: 'Metros',
  },
  Foot: {
    es: 'Pie',
  },
  Feet: {
    es: 'Pies',
  },
  Liter: {
    es: 'Litro',
  },
  Liters: {
    es: 'Litros',
  },
  Hour: {
    es: 'Hora',
  },
  'Wait Hour': {
    es: 'Horas de espera',
  },
  Kilogram: {
    es: 'Kilogramo',
  },
  Pound: {
    es: 'Libra',
  },
  Bushel: {
    es: 'Bushel',
  },
  Bushels: {
    es: 'Bushels',
  },
  Gallon: {
    es: 'Galón',
  },
  Gallons: {
    es: 'Galónes',
  },
  'Cubic Meter': {
    es: 'Metro cúbico',
  },
  'Cubic Meters': {
    es: 'Metros cúbicos',
  },
  // Signoff
  'Your Name': {
    es: 'Tu Nombre',
  },
  Signature: {
    es: 'Firma',
  },
  'Thank You!': {
    es: '¡Gracias!',
  },
  'You have signed off': {
    es: 'Haz firmado',
  },
  'Please pass the phone back to the driver.': {
    es: 'Por favor, devuelve el teléfono al conductor.',
  },
  Name: {
    es: 'Nombre',
  },
  'No signature provided': {
    es: 'No se proporcionó una firma',
  },
  'Sign Off': {
    es: 'Autorizar',
  },
  'To verify everything is correct, please add a signature.': {
    es: 'Para verificar que todo esté correcto, incluye una firma.',
  },
  'Pass your phone to a foreman to verify everything is correct and get their signature.': {
    es: 'Pasa tu teléfono a un capataz que verifique que todo esté correcto e incluya su firma.',
  },
  'Add Signature': {
    es: 'Agregar Firma',
  },
  'Does this look correct?': {
    es: '¿Se ve correcto?',
  },
  Required: {
    es: 'Obligatorio',
  },
  Clear: {
    es: 'Borrar',
  },
  Target: {
    es: 'Objetivo',
  },

  // Log Button
  'Start Job': {
    es: 'Iniciar Trabajo',
  },
  'Start Next Job': {
    es: 'Iniciar Trabajo Siguiente',
  },
  'Stop Job': {
    es: 'Detener Trabajo',
  },
  'Resume Job': {
    es: 'Reanudar trabajo',
  },
  'You must <bold>Stop Logging</bold> before starting a new job': {
    es: 'Debes <bold>Detener Trabajo</bold> antes de iniciar un nuevo trabajo',
  },
  'You must <bold>Stop Logging</bold> before starting a paused job': {
    es: 'Tienes que seleccionar <bold>Detener Trabajo</bold> antes de comenzar un trabajo en pausa',
  },
  'Scheduled for': {
    es: 'Programado para',
  },
  'Remove Attachment': {
    es: 'Quitar adjunto',
  },


  // Self Log
  'New Job Log': {
    es: 'Nuevo trabajo',
  },
  Company: {
    es: 'Empresa',
  },
  'Who are you completing this job for?': {
    es: '¿Para quién estás completando este trabajo?',
  },

  // Accept/Decline Section
  Decline: {
    es: 'Rechazar',
  },
  Accept: {
    es: 'Aceptar',
  },
  'Are you sure?': {
    es: '¿Estás seguro?',
  },
  'Declining the job will notify': {
    es: 'Rechazar el trabajo notificará',
  },
  'Decline job': {
    es: 'Rechazar trabajo',
  },

  // Stop Logging Modal
  'End Job': {
    es: 'Terminar el Trabajo',
  },
  "Let your dispatcher know if you're done this job or planning to resume it later.": {
    es: 'Informa a tu despachador si ya terminaste este trabajo o vas a reanudarlo más tarde.',
  },
  'Pause Job': {
    es: 'Pausar el Trabajo',
  },

  // Add/Edit Ticket Form
  'Scan in progress. Any ticket information that is recognized will auto-fill fields.': {
    es: 'Escaneando... Los datos se autocompletan en cuanto se encuentren.',
  },
  Remove: {
    es: 'Eliminar',
  },
  'Continue to Sign Off': {
    es: 'Continuar a Autorizar',
  },
  'Tread ID': {
    es: 'Tread ID',
  },
  Add: {
    es: 'Añadir',
  },
  'Notes for ticket.': {
    es: 'Notas para la nota de remisión.',
  },
  'ex. 1234': {
    es: 'p. ej. 1234',
  },
  'Ticket #': {
    es: 'Nota de remisión #',
  },
  'Ticket Number': {
    es: 'Nota de remisión #',
  },
  'Uploading...': {
    es: 'Subiendo...',
  },
  'Add Photo': {
    es: 'Añadir Foto',
  },
  Rotate: {
    es: 'Girar',
  },
  'At least one field must be filled in': {
    es: 'No puedes guardar una nota de remisión sin información',
  },
  'Edit Ticket': {
    es: 'Editar nota de remisión',
  },
  'View Tickets': {
    es: 'Ver Nota De Remisión',
  },
  'No Photo': {
    es: 'Sin Fotografía',
  },
  'Remove Photo': {
    es: 'Eliminar Fotografía',
  },
  'Delete Ticket': {
    es: 'Eliminar Nota de Remisión',
  },
  'Scale generated': {
    es: 'Creado por báscula',
  },
  'Add New Ticket': {
    es: 'Añadir Nota de Remisión',
  },
  'Add Ticket': {
    es: 'Añadir Nota de Remisión',
  },
  'Load Time': {
    es: 'Hora al cargar',
  },
  'Dump Time': {
    es: 'Hora al descargar',
  },
  'Warning: Dump Time set to next day': {
    es: 'Advertencia: la hora de descarga es el día siguiente',
  },
  'Warning: Arrival Time set to next day': {
    es: 'Advertencia: la llegada es el día siguiente',
  },
  Ok: {
    es: 'Aceptar',
  },
  'Rate Unit': {
    es: 'Unidad de tarifa',
  },
  Attachments: {
    es: 'Archivos adjuntos',
  },
  Times: {
    es: 'Horario de cargas',
  },
  'Arrive at Pick Up': {
    es: 'Llegó a recolectar',
  },
  'Arrive at Drop Off': {
    es: 'Llegó a descargar',
  },
  'Delivery Details': {
    es: 'Detalles de la entrega',
  },
  'Pick Up Site': {
    es: 'Sitio de recolección',
  },
  'Drop Off Site': {
    es: 'Sitio de destino',
  },

  // Statuses
  Live: {
    es: 'En Vivo',
  },
  New: {
    es: 'Nuevo',
  },
  Next: {
    es: 'Siguiente',
  },
  Paused: {
    es: 'En pausa',
  },
  Ended: {
    es: 'Terminado',
  },

  // Receipts
  // Receipts Overview
  Receipts: {
    es: 'Recibos',
  },
  'No receipts yet': {
    es: 'No hay recibos todavía',
  },
  'When you complete a job, your receipt will appear here': {
    es: 'Cuando completes un trabajo, tu recibo aparecerá aquí',
  },
  'New Ticket': {
    es: 'Nueva nota de remisión',
  },
  History: {
    es: 'Historial',
  },
  'Timesheet not signed off': {
    es: 'Bitácora de asistencia sin autorización',
  },
  'Timesheet Signed Off': {
    es: 'Bitácora de asistencia autorizada',
  },

  // Receipts Detail
  'Your Attachments': {
    es: 'Tus Archivos Adjuntos',
  },
  Assignment: {
    es: 'Trabajo',
  },
  'Log Details': {
    es: 'Detalles de Registro',
  },
  Subtotal: {
    es: 'Total parcial',
  },
  'Job Rate': {
    es: 'Tasa de trabajo',
  },
  'Total Qty': {
    es: 'Cantidad Total',
  },
  'Scheduled Start': {
    es: 'Inicio programado',
  },
  'Timesheet Photo': {
    es: 'Foto de la bitácora de asistencia',
  },
  'Route Map': {
    es: 'Mapa de la ruta',
  },
  Adding: {
    es: 'Agregando',
  },
  Type: {
    es: 'Tipo de proy.',
  },
  'Take a photo of your timesheet to keep a record of it.': {
    es: 'Por favor, añade tus notas de remisión aquí.',
  },
  'Manual Entry': {
    es: 'Manualmente',
  },
  'Note: this project was manually entered by the company.': {
    es: 'Nota: Este proyecto fue ingresado manualmente por la empresa.',
  },

  // Log Details
  'When a job is paused and then resumed, you’ll see the time stamps here.': {
    es: 'Cuando un trabajo ha sido puesto en pausa y se ha reanudado, aquí se muestra el código de tiempo.',
  },
  Started: {
    es: 'Iniciado',
  },
  Resumed: {
    es: 'Reanudado',
  },

  // Log Summary
  'Log Summary': {
    es: 'Resumen de Registro',
  },
  'Start time': {
    es: 'Hora de Inicio',
  },
  'End time': {
    es: 'Hora final',
  },
  'Job ended by': {
    es: 'Trabajo terminó por',
  },
  Duration: {
    es: 'Duración',
  },
  'Total logged': {
    es: 'Total registrado',
  },
  'Total paused': {
    es: 'Total pausado',
  },


  // Tickets And Notes Section
  'You have no notes.': {
    es: 'No tienes notas.',
  },
  Tickets: {
    es: 'Notas de Remisión',
  },
  'Go ahead and add your tickets here.': {
    es: 'Por favor, añade tus notas de remisión aquí.',
  },
  'You have no attached tickets.': {
    es: 'No tienes notas de remisión.',
  },
  Done: {
    es: 'Hecho',
  },
  'View attachments': {
    es: 'Ver los Archivos Adjuntos',
  },
  'Request a sign off on this timesheet from the foreman': {
    es: 'Solicita la firma de autorización del capataz para esta bitácora de asistencia',
  },
  'Request Sign Off': {
    es: 'Solicita Autorización',
  },
  'Sign Off Requested': {
    es: 'Autorización solicitada',
  },
  'Signed Off': {
    es: 'Con firma',
  },

  // Travel Info Section
  Cancelled: {
    es: 'Cancelado',
  },
  Terminated: {
    es: 'Terminado',
  },
  Status: {
    es: 'Condición',
  },
  'Start - Stop': {
    es: 'Inicio - Fin',
  },


  // Settings
  // Settings Overview
  'Go back': {
    es: 'Regresar',
  },
  Settings: {
    es: 'Configuraciones',
  },
  Notifications: {
    es: 'Notificaciones',
  },
  'Your profile': {
    es: 'Tu perfil',
  },
  'Sign out': {
    es: 'Cerrar sesión',
  },
  Companies: {
    es: 'Tus empresas',
  },
  Address: {
    es: 'Dirección',
  },

  // Settings company
  'Leave company': {
    es: 'Dejar la empresa',
  },
  'This means you will not be able to receive job assignments from this company.': {
    es: 'Esto significa que no podrá recibir asignaciones de trabajo de esta empresa.',
  },
  'Yes, leave company': {
    es: 'Sí, dejar la empresa',
  },

  // Settings notifications
  'Send me <bold>{type}</bold> reminders as:': {
    es: 'Enviarme recordatorios de <bold>{type}</bold> por:',
  },
  'Send me <bold>{type}</bold> notifications:': {
    es: 'Enviarme recordatorios de <bold>{type}</bold> por:',
  },
  ticket: {
    es: 'nota de remisión',
  },
  request: {
    es: 'solicitudes',
  },
  'urgent request': {
    es: 'solicitudes urgentes',
  },
  job: {
    es: 'trabajo',
  },
  'urgent job': {
    es: 'trabajo urgentes',
  },
  'Text Message': {
    es: 'Mensaje de Texto',
  },
  Push: {
    es: 'Notificación Push',
  },
  "Don't send": {
    es: 'No enviar',
  },
  'Remind me to:': {
    es: 'Recuérdame que:',
  },
  Reconnect: {
    es: 'Reconectar',
  },
  Update: {
    es: 'Actualizar',
  },

  // Settings Profile
  Language: {
    es: 'Idioma',
  },
  'First name': {
    es: 'Nombre',
  },
  'Last name': {
    es: 'Apellido',
  },
  Email: {
    es: 'Email',
  },
  Optional: {
    es: 'Opcional',
  },
  'Profile Photo': {
    es: 'Foto de Perfil',
  },
  'Upload Profile Photo': {
    es: 'Subir Foto de Perfil',
  },
  'Adding...': {
    es: 'Agregando...',
  },
  Replace: {
    es: 'Reemplazar',
  },
  Timezone: {
    es: 'Zona horaria',
  },

  // Notifications
  'An error has occurred': {
    es: 'Ha ocurrido un error',
  },
  'Joined company': {
    es: 'Unido a la empresa',
  },
  'Invitation to company has been declined': {
    es: 'Se ha rechazado la invitación a la empresa',
  },
  'Job has been added to your list': {
    es: 'El trabajo ha sido agregado a tu lista',
  },
  'Job has been declined': {
    es: 'El trabajo ha sido rechazado',
  },
  'Account has been created': {
    es: 'Se ha creado una cuenta',
  },
  'Profile has been updated': {
    es: 'Perfil actualizado',
  },
  'Notifications have been updated': {
    es: 'Notificaciones han sido actualizadas',
  },
  'Receipt has been created': {
    es: 'Se ha creado un recibo',
  },
  'Receipt has been updated': {
    es: 'Recibo ha sido actualizado',
  },
  'Code was incorrect': {
    es: 'Código incorrecto',
  },
  'You cannot leave a company if you are an admin': {
    es: 'No puede abandonar una compañía si eres administrador',
  },
  'You cannot leave your only company': {
    es: 'No puedes salir tu única compañía',
  },
  'Please enter a valid quantity or make this empty.': {
    es: 'Por favor, introduce una cantidad válida o déjala vacía',
  },

  // Timesheets
  Timesheets: {
    es: 'Horas Trabajadas',
  },
  'View Timesheet': {
    es: 'Ver horas trabajadas',
  },
  'Last Drop Off': {
    es: 'Última descarga',
  },
  'Sign Off has been requested': {
    es: 'Firma solicitada',
  },
  'Total Adjusted Hours': {
    es: 'Total de horas con ajustes'
  },
  'Adjustments are made by your administrator': {
    es: 'Los ajustes son realizados por tu administrador'
  },
  'Timesheet has been signed off': {
    es: 'La hoja de horas trabajadas se ha firmado'
  },
  'The timesheet has not been signed off yet': {
    es: 'La hoja de horas trabajadas no se ha firmado todavía'
  },
  'Not Signed Off': {
    es: 'Sin firma',
  },
  'Project': {
    es: 'Proyecto'
  },
  'Tickets for': {
    es: 'Tickets para'
  },
  'My Jobs': {
    es: 'Mis trabajos'
  },
  'My Tasks': {
    es: 'Mis Actividades'
  },
  'Requested Info from Dispatcher': {
    es: 'Solicitar información por tu despachador'
  },
  'Enter Info': {
    es: 'Introducir información'
  },
  'Please fill out fields below as requested by your employer to complete your timesheet.': {
    es: 'Por favor, completa los datos a continuación según lo haya solicitado tu supervisor para completar tus horas trabajadas'
  },
  'Save Changes': {
    es: 'Guardar cambios'
  },
  'Enter Info': {
    es: 'Introducir información'
  },
  'Self Assigned': {
    es: 'Autoasignado'
  },
  'There aren’t any timesheets here yet.': {
    es: 'Todavía no hay horas trabajadas en tu perfil.'
  },
  'Once you complete a job, you’ll see your timesheets here.': {
    es: 'Una vez que completes un trabajo con horas trabajadas verás tus horas trabajadas aquí.'
  }
};

export default dictionary;
