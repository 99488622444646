// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import ClickableDiv from 'common-ui/ClickableDiv';
import styles from './styles.module.scss';

type BottomSheetProps = {
  visible: boolean,
  setVisible: (boolean) => void,
  children: Node,
};

const BottomSheet = ({ visible, setVisible, children }: BottomSheetProps) => {
  return (
    <ClickableDiv
      onClick={() => setVisible(false)}
      disabled={!visible}
      className={classNames(styles.bottomSheet, { [styles.visible]: visible })}
    >
      <ClickableDiv className={styles.bottomSheetContent} onClick={e => e.stopPropagation()}>
        {children}
      </ClickableDiv>
    </ClickableDiv>
  );
};

export default BottomSheet;
