// @flow

// This function can be used to help update the cache after a mutation
// without having to refetch anything (e.g. after creating something)
// Most of the time you won't need this - if the return value of the mutation
// includes an id and an object with that id is already in the cache, apollo
// will update that object automatically. However, if you are creating something,
// that item won't exist in the cache so you must add it manually.
// NOTE: Before using this, see if you can use the refetchQueries option instead.
//       It is much easier than having to use this, at the cost of a network call.
//       For complicated mutations, refetchQueries makes much more sense.
//
// Usage--------------------------------------------------------------------------------
// If you want to add an object of type Item to an object Parent with a field collection
// (the collection field is an array of Items):
//
// const ITEMS_FRAGMENT = gql`
//   fragment items on Parent {
//     __typename <- this will be Parent
//     id
//     collection {
//       __typename <- this will be Item
//       id
//     }
//   }
// `;
// const [addItem] = useMutation(ADD_ITEM_MUTATION);
// addItem({
//   variables: {
//     input,
//   },
//   update: updateCacheObject(
//     'ParentTypename',
//     parentId,
//     ITEMS_FRAGMENT,
//     (oldParent, { addItem: newItem }) => ({
//       ...oldParent,
//       collection: [...oldParent.items, newItem],
//     }),
//   ),
// });
// The first parameter is the typename of the parent object that will be updated
// The second parameter is the id of the object
// The third parameter is a fragment used to fetch the existing items in the collection
// The fourth parameter is a callback that takes in the old Parent and the new Item
//   and returns the new Parent object (i.e. the old Parent with the new item added to
//   the collection field)
//
// IMPORTANT: the return value of the mutation must contain EVERY SINGLE FIELD that is fetched
// by the object you want to update in the original query. If the mutation's return value is missing
// some fields apollo will refuse to update anything with absolutely no indication why.
export const updateCacheObject = (
  typename: string,
  id: string,
  fragment: string,
  callback: ((existingObject: any, newData: any) => any),
) => (store: any, { data }: { data: any }) => {
  const oldObject = store.readFragment({
    id: `${typename}:${id}`,
    fragment,
  });
  store.writeFragment({
    id: `${typename}:${id}`,
    fragment,
    data: callback(oldObject, data),
  });
};

export default updateCacheObject;
