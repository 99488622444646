// @flow
import React, { type Node, useMemo } from 'react';
import { connect } from 'formik';
import GA, { GAEvents } from '../../Analytics/GoogleAnalytics';
import { FormField, TextInput } from '..';

type NestedName = {
  arrayName: string,
  index: number,
  name: string,
};

type FormikTextInputProps = {
  formik: any,
  label: string,
  required?: boolean,
  name: string,
  hint: string,
  embedded: boolean,
  readOnly?: boolean,
  readOnlyValue?: Node,
  analyticsAction?: string,
  step?: number,
  id?: string,
  disableIncrements?: boolean,
  nestedName?: NestedName,
  setUserMadeChangeFlag?: () => void, // fields should be locked if value already populated
};

const fieldArrayValidatorHelper = (touched, errors, nestedName) => {
  touched = touched[nestedName.arrayName];

  if (!Array.isArray(touched) || !Array.isArray(errors)) {
    return '';
  }

  if (!touched[nestedName.index] || !errors[nestedName.index]) {
    return '';
  }

  if (nestedName.name in touched[nestedName.index]) {
    if (touched[nestedName.index][nestedName.name]) {
      if (nestedName.name in errors[nestedName.index]) {
        return errors[nestedName.index][nestedName.name];
      }
    }
  }

  return '';
};

const FormikTextInput = ({
  formik,
  label,
  required,
  name,
  hint,
  embedded,
  readOnly,
  readOnlyValue,
  analyticsAction,
  step,
  id,
  disableIncrements,
  nestedName,
  setUserMadeChangeFlag,
  ...inputProps
}: FormikTextInputProps) => useMemo(() => (
  <FormField
    label={label}
    required={required}
    error={
      !nestedName
        ? formik.touched[name] && formik.errors[name]
        : fieldArrayValidatorHelper(formik.touched, formik.errors, nestedName)
    }
    embedded={embedded}
    hint={hint}
    hideError={inputProps.hideError || false}
  >
    {!readOnly ? (
      <TextInput
        disableIncrements={disableIncrements}
        value={
          !nestedName
            ? formik.values[name]
            : formik.values[nestedName.arrayName][nestedName.index][nestedName.name]
        }
        id={id}
        onChange={(value) => {
          if (setUserMadeChangeFlag !== null) {
            setUserMadeChangeFlag(true);
          }
          formik.setFieldValue(name, value);
        }}
        onBlur={() => formik.setFieldTouched(name)}
        step={step}
        onFocus={(value) => {
          if (analyticsAction) {
            GA.pushEvent(GAEvents.formChange, analyticsAction, value);
          }
        }}
        name={name}
        embedded={embedded}
        {...inputProps}
      />
    )
      : (
        <div id={id}>
          {readOnlyValue || formik.values[name] || '-'}
        </div>
      )}
  </FormField>
), [
  formik.values[name],
  formik.errors[name],
  formik.touched[name],
  label,
  required,
  name,
  hint,
  embedded,
  readOnly,
  readOnlyValue,
  analyticsAction,
  step,
  id,
  disableIncrements,
  nestedName,
]);

FormikTextInput.defaultProps = {
  required: false,
  readOnly: false,
  readOnlyValue: '',
  analyticsAction: '',
  step: undefined,
  id: undefined,
  disableIncrements: true,
  setUserMadeChangeFlag: null,
};

FormikTextInput.styleNames = TextInput.styleNames;

export default connect(FormikTextInput);