// @flow
import React from 'react';
import { connect } from 'formik';
import GA, { GAEvents } from '../../Analytics/GoogleAnalytics';
import { Toggle } from '..';

type FormikToggleProps = {
  formik: any,
  name: string,
  analyticsAction?: string,
};

const FormikToggle = ({
  formik,
  name,
  analyticsAction,
  ...inputProps
}: FormikToggleProps) => (
  <Toggle
    checked={formik.values[name]}
    onChange={(value) => {
      formik.setFieldValue(name, value);
      if (analyticsAction) {
        GA.pushEvent(GAEvents.formChange, analyticsAction, value);
      }
    }}
    onBlur={() => formik.setFieldTouched(name)}
    name={name}
    {...inputProps}
  />
);

FormikToggle.defaultProps = {
  analyticsAction: '',
};

export default connect(FormikToggle);
