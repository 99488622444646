// @flow
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { gql, useMutation, useQuery } from '@apollo/client';
import { updateCacheObject } from 'apollo/utils';
import type { Ticket } from 'common-ui/types';
import { FEATURED_ASSIGNMENT_QUERY } from 'components/TabBar/gql';
import { ASSIGNMENT_DETAIL_QUERY } from 'components/Receipts/ReceiptsDetail/gql';

const ADD_TICKET_MUTATION = gql`
  mutation createTicket($instanceId: ID!, $input: TicketInput!) {
    createTicket(instanceId: $instanceId, input: $input) {
      id
      data {
        filename
        getURL
        thumbnailURL
        mimeType
      }
      rotation
      ticketNumber
      quantity
      gross
      tare
      notes
      haulerRate
      haulerRateUnit
      haulerTotal
      scaleSource
      deleted
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      signoffsRequired
      signoffs {
        id
        signoffName
        signoffDate
        deleted
      }
      attachments {
        id
        uploadData {
          filename
          getURL
          thumbnailURL
          mimeType
        }
      }
      pickUpArrival
      dropOffDeparture
      jobNumber
      phase
      product
      configurableFields {
        id
        value
      }
    }
  }
`;

const TICKETS_FRAGMENT = gql`
  fragment tickets on AssignmentInstance {
    __typename
    id
    tickets {
      __typename
      id
    }
  }
`;

export const useAddTicket = (instanceId: string, assignmentId: string) => {
  const [addTicket] = useMutation(ADD_TICKET_MUTATION);
  return useCallback((ticket: Ticket) => {
    const startTime = ticket.startTime ? moment(ticket.startTime, ['h:m a', 'H:m']) : null;
    const endTime = ticket.startTime ? moment(ticket.endTime, ['h:m a', 'H:m']) : null;

    return (
      addTicket({
        variables: {
          instanceId,
          input: {
            ticketUrl: (ticket && ticket.data && ticket.data.getURL) || '',
            ticketFileName: (ticket && ticket.data && ticket.data.filename) || '',
            rotation: ticket.rotation || 0,
            ticketNumber: ticket.ticketNumber || '',
            quantity: ticket.quantity || '',
            gross: ticket.gross || '',
            tare: ticket.tare || '',
            notes: ticket.notes || '',
            haulerRate: parseFloat(ticket.haulerRate) || 0,
            haulerRateUnit: ticket.haulerRateUnit || '',
            ocrData: ticket.ocrData || null,
            startTime,
            endTime,
            pickupArrival: (ticket && ticket.pickUpArrival) || null,
            dropoffDeparture: (ticket && ticket.dropOffDeparture) || null,
            jobNumber: (ticket && ticket.jobNumber) || '',
            phase: (ticket && ticket.phase) || '',
            product: (ticket && ticket.product) || '',
            attachments: ticket.pendingAttachments || null,
            pickupSiteName: ticket.pickUpSiteAddress || '',
            pickupSiteID: ticket.pickUpSiteID ? parseInt(ticket.pickUpSiteID, 10) : null,
            dropoffSiteName: ticket.dropOffSiteAddress || '',
            dropoffSiteID: ticket.dropOffSiteID ? parseInt(ticket.dropOffSiteID, 10) : null,
            configurableFields: ticket.configurableFields || [],
          },
        },
        refetchQueries: [{
          query: FEATURED_ASSIGNMENT_QUERY,
          variables: {
            id: assignmentId,
          },
        }, {
          query: ASSIGNMENT_DETAIL_QUERY,
          variables: {
            instanceId,
          },
        }],
        update: updateCacheObject(
          'AssignmentInstance',
          instanceId,
          TICKETS_FRAGMENT,
          (oldInstance, { createTicket: newTicket }) => ({
            ...oldInstance,
            tickets: [...oldInstance.tickets, newTicket],
          }),
        ),
      }).then(({ data }) => data.createTicket)
    );
  }, [addTicket, instanceId, assignmentId]);
};

const UPDATE_TICKET_MUTATION = gql`
mutation updateTicket($id:ID!, $input:TicketInput!) {
  updateTicket(id:$id, input:$input) {
    id
    data {
      filename
      getURL
      thumbnailURL
      mimeType
    }
    rotation
    ticketNumber
    quantity
    gross
    tare
    notes
    haulerRate
    haulerRateUnit
    haulerTotal
    deleted
    attachments {
      id
      uploadData {
        filename
        getURL
        thumbnailURL
        mimeType
      }
    }
    pickUpArrival
    dropOffDeparture,
    jobNumber
    phase
    product
    pickUp
    dropOff
    configurableFields {
      id
      value
    }
  }
}
`;

/**
 * frontend only values that is attached to value object
 */
type updateTicketValueType = {
  dropOffSiteID: string,
  pickUpSiteID: string
}

export const useUpdateTicket = (instanceId: string, assignmentId: string) => {
  const [updateTicket] = useMutation(UPDATE_TICKET_MUTATION);

  return useCallback((ticket: Ticket | updateTicketValueType) => {
    const ticketData = ticket.data;
    if (!ticketData) return Promise.resolve(null);
    const { getURL, filename } = ticketData;

    return updateTicket({
      instanceId,
      variables: {
        id: ticket.id,
        input: {
          ticketUrl: getURL || '',
          ticketFileName: filename || '',
          rotation: ticket.rotation || 0,
          ticketNumber: ticket.ticketNumber || '',
          quantity: ticket.quantity || '',
          gross: ticket.gross || '',
          tare: ticket.tare || '',
          notes: ticket.notes || '',
          haulerRate: parseFloat(ticket.haulerRate) || 0,
          haulerRateUnit: ticket.haulerRateUnit || '',
          ocrData: ticket.ocrData || null,
          startTime: ticket.startTime || null,
          endTime: ticket.endTime || null,
          pickupArrival: ticket.pickUpArrival || null,
          dropoffDeparture: ticket.dropOffDeparture || null,
          jobNumber: ticket.jobNumber || '',
          phase: ticket.phase || '',
          product: ticket.product || '',
          pickupSiteName: ticket.pickUpSiteAddress || '',
          dropoffSiteName: ticket.dropOffSiteAddress || '',
          attachments: ticket.pendingAttachments || null,
          deletedAttachmentIDs: ticket.deletedAttachmentIDs || null,
          dropoffSiteID: ticket.dropOffSiteID ? parseInt(ticket.dropOffSiteID, 10) : null,
          configurableFields: ticket.configurableFields || [],
        },
      },
      refetchQueries: [{
        query: FEATURED_ASSIGNMENT_QUERY,
        variables: {
          id: assignmentId,
        },
      }],
    }).then(({ data }) => data.updateTicket);
  }, [updateTicket, instanceId, assignmentId]);
};

const DELETE_TICKET_MUTATION = gql`
  mutation removeTickets($ids: [ID!]!) {
    removeTickets(ids: $ids) {
      id
      deleted
    }
  }
`;

export const useDeleteTicket = () => {
  const [removeTicket] = useMutation(DELETE_TICKET_MUTATION);
  return useCallback((id: string) => (
    removeTicket({
      variables: {
        ids: [id],
      },
    }).then(({ data }) => data.removeTickets)
  ), [removeTicket]);
};

export const TICKET_DEFAULTS_QUERY = gql`
  query ticketDefaults($companyID:ID!, $assignmentID:ID!) {
    ticketDefaults(companyID:$companyID, assignmentID:$assignmentID) {
      defaults
    }
  }
`;

export const useCompanyTicketQuantityDefaults = (companyID: number, assignmentId: number, skip: boolean) => {
  const { data, loading, error } = useQuery(TICKET_DEFAULTS_QUERY, {
    variables: {
      companyID: companyID.toString(),
      assignmentID: assignmentId.toString(),
    },
    skip,
  });
  return useMemo(() => (
    (!loading && !error && data)
      ? data.ticketDefaults.defaults
      : {}
  ), [data, loading, error]);
};
