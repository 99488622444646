// @flow
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { TIMESHEET_QUERY } from "../../Timesheets/Hooks/useTimesheet";

const REQUEST_SIGNOFF_MUTATION = gql`
mutation requestSignoff($timesheetID: ID!) {
  requestSignoff(timesheetID: $timesheetID) {
    id
    signoffStatus
    signoffStatusUpdatedAt
  }
}
`;

const useRequestSignoff = () => {
  const [requestSignoff] = useMutation(REQUEST_SIGNOFF_MUTATION);
  return useCallback((timesheetID: string, companyID: string) => (
    requestSignoff({
      variables: {
        timesheetID,
      },
      refetchQueries: [{
        query: TIMESHEET_QUERY,
        variables: {
          timesheetID,
          companyID,
        },
      }],
    })
  ), [requestSignoff]);
};

export default useRequestSignoff;
