/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'OldCommon/Form';

import Formik from 'formik';
import { UploadToServer } from '..';

type FormikUploadToServerProps = {
  formik: Formik,
  name: string,
  onUploaded: (value: Object) => void;
}

const FormikUploadToServer = ({
  formik,
  name,
  onUploaded,
  ...inputProps
}: FormikUploadToServerProps) => (
  <UploadToServer
    value={formik.values[name]}
    onUploaded={(value) => {
      if (onUploaded) {
        onUploaded(value);
      }

      return formik.setFieldValue(name, value);
    }}
    onBlur={() => formik.setFieldTouched(name)}
    name={name}
    {...inputProps}
  />
);

export default connect(FormikUploadToServer);
