import React from 'react';
import { Button } from 'OldCommon/Button';
import Modal from 'react-modal';
import ID from 'common-ui/ConsistentIDs';
import styles from './styles.module.scss';

type ActionSheetModalProps = {
  visible: boolean,
  onClose: () => {},
  onComplete: () => {},
  confirmLabel: Node,
  cancelLabel: Node,
}

const ActionSheetModal = ({
  visible,
  onClose,
  onComplete,
  confirmLabel,
  cancelLabel,
}: ActionSheetModalProps) => (
  <Modal
    onRequestClose={onClose}
    isOpen={visible}
    overlayClassName={styles.overlay}
    className={styles.modal}
  >
    <div className={styles.stopLoggingModalButtons}>
      <Button
        className={styles.deleteAttachmentButton}
        label={confirmLabel}
        onClick={(e) => {
          e.preventDefault();
          onComplete();
          onClose();
        }}
        id={ID.create(ID.Areas.General, ID.Types.Button, ID.Labels.ConfirmRemoveAttachment)}
      />
      <Button
        className={styles.cancelButton}
        label={cancelLabel}
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
        id={ID.create(ID.Areas.General, ID.Types.Button, ID.Labels.ConfirmRemoveCancel)}
      />
    </div>
  </Modal>
);

export default ActionSheetModal;
