// @flow
import React from 'react';
import { FormField } from 'common-ui/Form';
import { Button } from 'common-ui/Button';
import { CircleCheck } from 'common-ui/Icon';
import type { Signoff } from 'common-ui/types';
import { useI18n } from 'internationalization';
import styles from './styles.module.scss';

type SignedOffPageProps = {
  signoff: Signoff | null,
  onComplete: () => void,
};

const SignedOffPage = ({ signoff, onComplete }: SignedOffPageProps) => {
  const intlz = useI18n();

  return (
    <div className={styles.signoffPage}>
      <div className={styles.signoffPageHeader}>
        <CircleCheck />
        {intlz('You have signed off')}
      </div>
      <div className={styles.signoffPageText}>
        {intlz('Please pass the phone back to the driver.')}
      </div>
      {!!signoff && (
        <div className={styles.signoffInfo}>
          <FormField label={intlz('Name')}>
            {signoff.signoffName || '-'}
          </FormField>
          <FormField label={intlz('Signature')}>
            {signoff.data && !!signoff.data.getURL ? (
              <img alt={signoff.data.filename} src={signoff.data.getURL} />
            ) : (
              intlz('No signature provided')
            )}
          </FormField>
        </div>
      )}
      <Button styleName="primary" onClick={onComplete} text={intlz('Done')} fullWidth large />
    </div>
  );
};

export default SignedOffPage;
