import React from 'react';

const Attach = props => (
  <svg width={21} height={23} viewBox="0 0 21 23" {...props}>
    <title>Attach</title>
    <path
      d="M12.428 11.838l-3.77 3.854a1.05 1.05 0 0 1-1.507-.001 1.108 1.108 0 0 1-.001-1.54l5.278-5.395a1.107 1.107 0 0 0 0-1.541 1.05 1.05 0 0 0-1.508 0L5.642 12.61a3.321 3.321 0 0 0 .001 4.622 3.15 3.15 0 0 0 4.522.001l3.77-3.854 4.9-5.008c1.873-1.914 1.873-5.022.001-6.935a4.726 4.726 0 0 0-6.785 0l-4.9 5.008-5.277 5.394c-2.499 2.554-2.499 6.693 0 9.247a6.299 6.299 0 0 0 9.046 0l6.785-6.935a1.107 1.107 0 0 0 0-1.541 1.05 1.05 0 0 0-1.507 0l-6.785 6.935a4.199 4.199 0 0 1-6.031 0c-1.666-1.703-1.666-4.462 0-6.165l5.277-5.394 4.9-5.007a2.626 2.626 0 0 1 3.77-.001 2.77 2.77 0 0 1-.001 3.853l-4.9 5.008z"
      fill="#162093"
      fillRule="nonzero"
    />
  </svg>
);

export default Attach;
