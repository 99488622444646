import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Higher Order Components
import { Button } from 'OldCommon/Button';
import { Play, Stop } from 'common-ui/Icon';
import { Text } from 'OldCommon/Text';
import { PAUSED } from 'utils/constants/job';
import { useI18n } from 'internationalization';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import StopLoggingModal from '../StopLoggingModal';
import DisabledLogButton from './DisabledLogButton.ui';
import ID from 'common-ui/ConsistentIDs';

import styles from './styles.module.scss';

const LogButton = ({
  jobStarted,
  endJobModalOpen,
  cancelAction,
  handleButtonClick,
  completeJob,
  pauseJob,
  resumeJob,
  cantLogNotCurrentDay,
  cantLogCurrentlyLogging,
  jobStartTime,
  prefTimeZone,
  assignmentStatus,
}) => {
  const intlz = useI18n();
  const [disabled, setDisabled] = useState(false);

  if (assignmentStatus === PAUSED) {
    return (
      <>
        { (!!cantLogNotCurrentDay || !!cantLogCurrentlyLogging) ? (
          <>
            <div className={styles.logButton}>
              <Button fullWidth disabled leftIcon={<Play />} label={intlz('Resume Job')} />
            </div>
            <div>
              <Text className={styles.alignCenter} secondary>
                {intlz('You must <bold>Stop Logging</bold> before starting a paused job', {
                  bold: (word) => <span key={word} className={styles.bold}>{word}</span>,
                })}
              </Text>
            </div>
          </>
        ) : (
          <>
            <div className={styles.logJobButton}>
              <Button
                fullWidth
                disabled={disabled}
                primary
                leftIcon={<Play />}
                label={intlz('Resume Job')}
                onClick={(e) => {
                  setDisabled(true);
                  resumeJob(e)
                    .finally(() => setDisabled(false));
                  GA.trackClick(GAActions.resumeLogging, 'Resume Logging');
                }}
              />
            </div>
          </>

        )}
      </>
    );
  }

  if (!!cantLogNotCurrentDay || !!cantLogCurrentlyLogging) {
    return (
      <DisabledLogButton
        cantLogNotCurrentDay={cantLogNotCurrentDay}
        cantLogCurrentlyLogging={cantLogCurrentlyLogging}
        jobStartTime={jobStartTime}
        prefTimeZone={prefTimeZone}
        assignmentStatus={assignmentStatus}
      />
    );
  }


  return (
    <>
      <div className={styles.logJobButton}>
        <Button
          id={ID.create(
            ID.Areas.Jobs,
            ID.Types.Button,
            ID.Labels.StartJob,
          )}
          fullWidth
          disabled={disabled || endJobModalOpen}
          stopLog={!!jobStarted}
          primary={!jobStarted}
          leftIcon={jobStarted ? <Stop /> : <Play />}
          label={
            jobStarted ? intlz('Stop Job') : intlz('Start Job')
          }
          onClick={(e) => {
            setDisabled(true);
            handleButtonClick(e)
              .finally(() => setDisabled(false));
            if (jobStarted) {
              GA.trackClick(GAActions.stopJob, 'Stop Job');
            } else {
              GA.trackClick(GAActions.startJob, 'Start Job');
            }
          }}
        />
      </div>
      <StopLoggingModal
        visible={endJobModalOpen}
        onClose={cancelAction}
        onComplete={completeJob}
        onPause={pauseJob}
      />
    </>
  );
};

LogButton.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  pauseJob: PropTypes.func,
  resumeJob: PropTypes.func,
  completeJob: PropTypes.func,
  jobStarted: PropTypes.bool.isRequired,
  jobStartTime: PropTypes.string,
  prefTimeZone: PropTypes.string,
  cantLogNotCurrentDay: PropTypes.bool,
  endJobModalOpen: PropTypes.bool,
  cantLogCurrentlyLogging: PropTypes.bool,
};

LogButton.defaultProps = {
  jobStartTime: null,
  completeJob: null,
  pauseJob: null,
  resumeJob: null,
  prefTimeZone: null,
  cantLogNotCurrentDay: false,
  endJobModalOpen: false,
  cantLogCurrentlyLogging: false,
};

export default LogButton;
