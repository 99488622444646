// @flow
import React, { type Node } from 'react';
import _ from 'lodash';
import { connect, type Formik } from 'formik';
import { FormField } from '..';
import DropdownCreatable from '../Dropdown/DropdownCreatable';

type FormikCreatableDropdownProps = {
  formik: Formik,
  label?: string,
  required?: boolean,
  locked?: boolean,
  options: { label: Node, value: string }[],
  name: string,
  embedded: boolean,
  readOnly?: boolean,
  readOnlyValue?: Node,
  id?: string,
  canCreateOption?: boolean,
  isTimeInput?: boolean,
  hideError?: boolean,
};

/**
 * Similar to its cousin FormikDropdown expect that it uses DropdownCreatable as its base.
 */
const FormikCreatableDropdown = ({
  formik,
  label,
  required,
  locked,
  options,
  name,
  embedded,
  readOnly,
  readOnlyValue,
  id,
  isTimeInput,
  hideError,
  tooltip,
  ...inputProps
}: FormikCreatableDropdownProps) => (
  <FormField
    label={label}
    required={required}
    locked={locked}
    error={formik.touched[name] && formik.errors[name]}
    embedded={embedded}
    hideError={hideError}
    tooltip={tooltip}
  >
    {
        !readOnly && ( 
          <DropdownCreatable
            id={id}
            options={options}
            value={formik.values[name]}
            inputId={name}
            onChange={(value) => formik.setFieldValue(name, value)}
            onBlur={() => formik.setFieldTouched(name)}
            name={name}
            embedded={embedded}
            canCreateOption={true}
            isTimeInput={isTimeInput}
            {...inputProps}
          />
        )
      }
    { readOnly && (
    <>
      {readOnlyValue || _.get(options.find((o) => o.value === formik.values[name]), 'label', '-')}
    </>
    )}
  </FormField>
);

FormikCreatableDropdown.defaultProps = {
  required: false,
  locked: false,
  readOnly: false,
  readOnlyValue: '',
  id: undefined,
  label: undefined,
  isTimeInput: false,
  hideError: false,
};

export default connect(FormikCreatableDropdown);
