// formatAddress takes an address object and returns a string
export default function formatAddress(addr) {
  if (!addr || !addr.fullAddress) {
    return '';
  }

  if (!!addr.street && !!addr.city) {
    return `${addr.unit ? `${addr.unit} - ` : ''}${addr.street}, ${addr.city}`;
  }

  return addr.fullAddress;
}

export const formatCompanyAddress = (company) => {
  if(!company) {
    return '';
  }

  if(!!company.address1) {
    return `${company.address1}${company.address2 ? ' ' + company.address2 : ''}` + (company.city ? `, ${company.city}` : '');
  }

  return '';
};
