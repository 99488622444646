import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';

// Higher Order Components
import { compose, withStateHandlers } from 'recompose';

// Components
import { NullableNotificationBanner } from 'Notification';
import DeviceLoggingReconciler from 'Device';
import { Heading } from 'OldCommon/Text';
import { LogoYellow } from 'common-ui/Icon';
import { messages } from 'internationalization';
import RequestCodeForm from './RequestCodeForm';
import VerifyCodeForm from './VerifyCodeForm';
import styles from './styles.module.scss';

const Login = ({ form, handleLogin, handleBack }) => {
  // Get the language from the browser
  const language = ((navigator.languages && navigator.languages[0])
    || navigator.language
    || navigator.userLanguage).split('-')[0];
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <NullableNotificationBanner />
      <DeviceLoggingReconciler />
      <div className={styles.backgroundColorContainer} />
      <div className={styles.container}>
        <div className={styles.largeLogo}>
          <LogoYellow />
        </div>
        {form === 'request' ? (
          <div className={styles.inputArea}>
            <div className={styles.padY}>
              <Heading className={styles.invert} />
            </div>
            <RequestCodeForm onSuccess={handleLogin} />
          </div>
        ) : (
          <div className={styles.inputArea}>
            <div className={styles.padY}>
              <Heading className={styles.enterCodeContainer}>
                <FormattedMessage id="Enter Code" defaultMessage="Enter Code" />
              </Heading>
              <div className={styles.enterCodeInstructionsContainer}>
                <div className={styles.enterCodeInstructions}>
                  <FormattedMessage
                    id="Once you receive a text message with a 5-digit code, enter it here."
                    defaultMessage="Once you receive a text message with a 5-digit code, enter it here."
                  />
                </div>
              </div>
            </div>
            <VerifyCodeForm handleBack={handleBack} />
          </div>
        )}
      </div>
    </IntlProvider>
  );
};

Login.propTypes = {
  form: PropTypes.string.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default compose(
  withStateHandlers(
    { form: 'request' },
    {
      handleLogin: () => () => ({ form: 'verify' }),
      handleBack: () => () => ({ form: 'request' }),
    },
  ),
)(Login);
