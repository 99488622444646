import { useMemo } from 'react';
import moment from 'moment-timezone';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTimezone } from 'common-ui/Hooks';
import { ERROR } from 'Notification/NotificationTypes';
import parseGraphqlError from 'utils/Error';
import { SET_NOTIFICATION } from 'apollo';
import { ASSIGNMENTS_QUERY } from 'Jobs/graphql';

const START_LOGGING_MUTATION = gql`
  mutation StartLoggingMutation($id: ID!) {
    startLogging(id: $id) {
      id
      loggingStart
    }
  }
`;

const STOP_LOGGING_MUTATION = gql`
  mutation StopLoggingMutation($id: ID!, $companyID: ID) {
    stopLogging(id: $id, companyID: $companyID) {
      id
      loggingStop
    }
  }
`;

const PAUSE_LOGGING_MUTATION = gql`
mutation PauseLoggingMutation($id: ID!) {
  pauseLogging(id: $id) {
    id
  }
}
`;

export const useStartLogging = () => {
  const [startLogging] = useMutation(START_LOGGING_MUTATION);
  const [notify] = useMutation(SET_NOTIFICATION);

  return (assignment) => {
    const instanceId = assignment.assignmentInstances
      && assignment.assignmentInstances
      && assignment.assignmentInstances[0]
      && assignment.assignmentInstances[0].id;
    if (!instanceId) return Promise.reject();
    return startLogging({
      variables: {
        id: instanceId,
      },
      refetchQueries: [{ query: ASSIGNMENTS_QUERY }],
    }).catch((err) => {
      notify({
        variables: { type: ERROR, message: parseGraphqlError(err) },
      });
    });
  };
};

export const useStopLogging = () => {
  const [stopLogging] = useMutation(STOP_LOGGING_MUTATION);
  const [notify] = useMutation(SET_NOTIFICATION);

  return (assignment) => {
    const instanceId = assignment.assignmentInstances
      && assignment.assignmentInstances
      && assignment.assignmentInstances[0]
      && assignment.assignmentInstances[0].id;

    const companyID = assignment && assignment.companyId.toString();

    if (!instanceId) return Promise.reject();
    return stopLogging({
      variables: {
        id: instanceId,
        companyID
      },
      refetchQueries: [{ query: ASSIGNMENTS_QUERY }],
    }).catch((err) => {
      notify({
        variables: { type: ERROR, message: parseGraphqlError(err) },
      });
    });
  };
};

export const usePauseLogging = () => {
  const [pauseLogging] = useMutation(PAUSE_LOGGING_MUTATION);
  const [notify] = useMutation(SET_NOTIFICATION);

  return (assignment) => {
    const instanceId = assignment.assignmentInstances
      && assignment.assignmentInstances[0]
      && assignment.assignmentInstances[0].id;
    if (!instanceId) return Promise.reject();
    return pauseLogging({
      variables: {
        id: instanceId,
      },
      refetchQueries: [{ query: ASSIGNMENTS_QUERY }],
    }).catch((err) => {
      notify({
        variables: { type: ERROR, message: parseGraphqlError(err) },
      });
    });
  };
};

export const FEATURED_ASSIGNMENT_QUERY = gql`
  query FeaturedAssignmentQuery($id: ID!) {
    assignment(id: $id) {
      id
      startTime
      hasTimesheet
      assignedTo
      assignee {
        id
        accountId
      }
      assignedAt
      assignedStatus
      sendStatus
      companyId
      company {
        id
      }
      client
      companyName
      haulerRate
      haulerRateUnit
      hideRateFromAssignee
      quantity
      quantityUnit
      schedule {
        id
        assignments {
          id
          client
          companyName
          dropoffDeparture
          equipment
          equipmentInfo {
            id
            name
          }
          foreman
          jobNumber
          notes
          phase
          pickupArrival
          product
          productInfo {
            id
            name
          }
          haulerRateUnit
          haulerRate
          hideRateFromAssignee
          sendStatus
          startTime
          quantity
          quantityUnit
        }
      }
      equipment
      equipmentInfo {
        id
        name
      }
      phase
      product
      productInfo {
        id
        name
      }
      notes
      pickUp
      pickUpSite {
        id
        name
        notes
        address
        type
        coordinates {
          latitude
          longitude
        }
      }
      dropOff
      dropOffSite {
        id
        name
        notes
        address
        type
        coordinates {
          latitude
          longitude
        }
      }
      assignmentInstances {
        id
        driverTicketFields
        assignmentId
        loggingStart
        loggingStop
        tickets {
          canEdit
          id
          data {
            getURL
            thumbnailURL
            mimeType
            filename
          }
          createdAt
          createdBy {
            id
            firstName
            lastName
            avatar {
              getURL
              thumbnailURL
            }
          }
          ticketNumber
          quantity
          haulerRate
          haulerRateUnit
          gross
          tare
          rotation
          deleted
          notes
          scaleSource
          signoffsRequired
          startTime
          endTime
          signoffs {
            id
            signoffName
            signoffDate
            deleted
          }
          attachments {
            id
            uploadData {
              filename
              getURL
              thumbnailURL
              mimeType
            }
          }
          pickUpArrival
          dropOffDeparture
          pickUp
          dropOff
          dropOffSite {
            id
            name
          }
          phase
          product
          jobNumber
          configurableFields {
            id
            value
          }
        }
      }
      project {
        id
        name
        type
        foreman
        foremanID
        jobNumber
        companyName
        companyId
        signOffRequired
        pickUp
        pickUpSite {
          id
          name
          address
          notes
          type
          coordinates {
            latitude
            longitude
          }
        }
        dropOff
        dropOffSite {
          id
          name
          address
          notes
          type
          coordinates {
            latitude
            longitude
          }
        }
        attachments {
          uploadData{
            filename
            getURL
            mimeType
          }
        }
        configurableFields {
          id
          value
        }
      }
      configurableFields {
        id
        value
      }
    }
  }
`;


export const useGetAssignment = (assignmentId) => {
  const timezone = useTimezone();
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery(FEATURED_ASSIGNMENT_QUERY, {
    variables: {
      id: assignmentId,
    },
    skip: !assignmentId,
    returnPartialData: true,
  });
  return useMemo(() => {
    if (!data || loading || error) return [null, loading, error, refetch];

    const byPickupArrival = (a, b) => moment.tz(a.pickupArrival, timezone).isAfter(moment.tz(b.pickupArrival, timezone));
    let { schedule } = data.assignment;
    if (schedule) {
      schedule = {
        ...schedule,
        assignments: schedule.assignments.sort(byPickupArrival),
      };
    }

    const assignmentInstances = [...data.assignment.assignmentInstances];
    return [{
      ...data.assignment,
      schedule,
      assignmentInstances: assignmentInstances
        .sort((a, b) => b.id - a.id)
        .map((ai) => ({
          ...ai,
          tickets: ai.tickets.filter((t) => !t.deleted).map((t) => ({
            ...t,
            signoffs: t.signoffs.filter((so) => !so.deleted),
          })),
        })),
    },
    loading,
    error,
    refetch];
  }, [loading, error, data, refetch, timezone]);
};

const FOREMAN_ACCOUNTS = gql`
  query foremen($companyID: ID!, $onlyEnabled: Boolean!) {
    foremen(companyID: $companyID, onlyEnabled: $onlyEnabled) {
      id
      firstName
      lastName
    }
  }
`;

export const useForemanAccounts = (companyID) => {
  const { data, loading, error } = useQuery(FOREMAN_ACCOUNTS, {
    variables: { companyID, onlyEnabled: true },
  });
  return useMemo(() => (
    !loading && !error
      ? data.foremen
      : []
  ), [data, loading, error]);
};
