import React from 'react';

const Company = props => (
  <svg width={18} height={21} viewBox="0 0 18 21" {...props}>
    <title>Company</title>
    <path
      d="M7.412 5.294h3.176V2.118H7.412v3.176zm0 2.118v3.176h3.176V7.412H7.412zm8.47-2.118V2.118h-3.176v3.176h3.176zm0 2.118h-3.176v3.176h3.176V7.412zM2.118 5.294h3.176V2.118H2.118v3.176zm0 2.118v3.176h3.176V7.412H2.118zm13.764 5.294H2.118V18h4.998a1.98 1.98 0 0 1-.234-.935v-.248c0-1.1.899-1.993 1.994-1.993h.248c1.101 0 1.994.898 1.994 1.993v.248c0 .338-.085.656-.234.935h4.998v-5.294zM1.06 0H16.94C17.526 0 18 .477 18 1.06v17.997c0 .586-.472 1.06-1.059 1.06H1.06A1.061 1.061 0 0 1 0 19.058V1.06C0 .475.472 0 1.059 0z"
      fill="#000B9D"
      fillRule="evenodd"
    />
  </svg>
);

export default Company;
