// @flow
import React from 'react';
import classNames from 'classnames';
import { Button } from 'common-ui/Button';
import { Signature } from 'common-ui/Icon';
import type { Ticket } from 'common-ui/types';
import { AddEditTicketForm } from 'components/Tickets';
import { useI18n } from 'internationalization';
import { Steps } from '../Tickets/constants';
import styles from './styles.module.scss';

type GetSignOffPageProps = {
  assignment: any,
  instanceId: string,
  ticket: Ticket,
  onRemove: () => void,
  onSignoff: () => void,
};

const GetSignOffPage = ({
  assignment,
  instanceId,
  ticket,
  onRemove,
  onSignoff,
}: GetSignOffPageProps) => {
  const intlz = useI18n();
  return (
    <div className={classNames(styles.signoffPage, styles.getSignoffPage)}>
      <div className={styles.signoffInfoWrapper}>
        <div className={styles.signoffPageText}>
          {intlz('Pass your phone to a foreman to verify everything is correct and get their signature.')}
        </div>
        {!!ticket && (
          <div className={styles.signoffInfo}>
            <AddEditTicketForm
              displayJobInfo
              assignment={assignment}
              instanceId={instanceId}
              ticket={ticket}
              onRemove={onRemove}
              onComplete={() => {}}
              setSelectedTicketId={() => {}}
              currentStep={Steps.getSignature}
            />
          </div>
        )}
      </div>
      <div className={styles.addSignatureButtonWrapper}>
        <Button
          icon={<Signature />}
          className={styles.addSignatureButton}
          onClick={onSignoff}
          text={intlz('Add Signature')}
          fullWidth
          large
        />
      </div>
    </div>
  );
};

export default GetSignOffPage;
