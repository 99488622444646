import { gql } from '@apollo/client';

const UPLOAD_ATTACHMENT_URL = gql`
  mutation UploadAttachment($file: Upload!, $input: UploadInput!) {
    uploadAttachment(file: $file, input: $input) {
      getURL
      expiry
      mimeType
    }
  }
`;

export default UPLOAD_ATTACHMENT_URL;
