import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export const INSTANCE_ROOT_COMPANY_PERMISSONS_QUERY = gql`
  query InstanceRootCompanyPermissions($instanceID: ID!) {
    instanceRootCompanyPermissions(instanceID: $instanceID)
  }
`;

const useInstanceRootCompanyPermissions = (instanceID: string) => {
    const { loading, error, data } = useQuery(INSTANCE_ROOT_COMPANY_PERMISSONS_QUERY, {
      variables: {
        instanceID,
      },
      skip: !instanceID || instanceID === '0',
    });
    return useMemo(() => {
      if (!data || loading || error) {
        return [];
      }
      return data.instanceRootCompanyPermissions;
    }, [loading, error, data]);
  };
  
  export default useInstanceRootCompanyPermissions;