import React from 'react';

const Download = props => (
  <svg width={19} height={21} viewBox="0 0 19 21" {...props}>
    <title>Download</title>
    <path
      d="M6.99 10.75l1.327 1.342V1.2C8.317.538 8.844 0 9.505 0c.655 0 1.187.537 1.187 1.2v10.892l1.328-1.343a1.178 1.178 0 0 1 1.683-.003 1.213 1.213 0 0 1-.004 1.7l-3.351 3.39a1.177 1.177 0 0 1-.843.353 1.166 1.166 0 0 1-.84-.35L5.31 12.447a1.21 1.21 0 0 1-.004-1.701 1.182 1.182 0 0 1 1.683.003zm9.635 7.85v-3.602c0-.663.532-1.2 1.188-1.2.655 0 1.187.537 1.187 1.2v3.601C19 19.923 17.936 21 16.63 21H2.37C1.059 21 0 19.928 0 18.6v-3.602c0-.663.532-1.2 1.188-1.2.655 0 1.187.537 1.187 1.2l-.005 3.601h14.255z"
      fill="#162093"
      fillRule="nonzero"
    />
  </svg>
);

export default Download;
