// @flow
import React from 'react';
import moment from 'moment-timezone';
import { Tag } from 'common-ui/Labels';
import { useTimezone } from 'common-ui/Hooks';
import styles from './styles.module.scss';

type TimeTagProps = {
  startTime: string,
  endTime?: string,
  showAsDate?: boolean,
}

const TimeTag = ({ startTime, endTime, showAsDate }: TimeTagProps) => {
  const timezone = useTimezone();
  const format = !showAsDate ? 'h:mma' : 'ddd MMM D';
  let timeText = moment.tz(startTime, timezone).format(format);
  if (endTime) timeText += ` - ${moment.tz(endTime, timezone).format(format)}`;

  return (
    <Tag text={timeText} className={styles.timeTag} />
  );
};

TimeTag.defaultProps = {
  showAsDate: false,
  endTime: '',
};

export default TimeTag;
