import React from 'react';

type CheckPropTypes = {
  viewBox?: string,
  height?: number,
  width?: number,
};

const Check = (props: CheckPropTypes) => {
  const { viewBox, width, height } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} {...props}>
      <title>Checkmark</title>
      <path
        d="M8.09 14.201L2.493 8.604.587 10.497 8.09 18 24.197 1.893 22.305 0z"
        fill="#00f"
        fillRule="evenodd"
      />
    </svg>
  );
};

Check.defaultProps = {
  viewBox: '0 0 25 18',
  height: 25,
  width: 18,
};

export default Check;
