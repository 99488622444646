// @flow
import React, { useState, useEffect } from 'react';
import { usePageDisplay } from 'common-ui/Hooks';
import { FullScreenPage } from 'components/Common/Page';
import { useI18n } from 'internationalization';
import type { Ticket } from 'common-ui/types';
import { GetSignoffPage, SignaturePage, SignedOffPage } from 'components/Signoff';
import { Steps } from './constants';
import AddEditTicketForm from './AddEditTicketForm';
import styles from './styles.module.scss';

export const useTicketsPageDisplay = () => {
  const [openPageDisplay, pageDisplayProps] = usePageDisplay();
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const openTicketsPageDisplay = (step: string, ticketId?: string | null) => {
    openPageDisplay(step);
    setSelectedTicketId(ticketId || null);
  };
  return [openTicketsPageDisplay, {
    ...pageDisplayProps,
    selectedTicketId,
    setSelectedTicketId,
  }];
};

type TicketsPageDisplayProps = {
  assignment: any, // Used for populating the ticket's rate/unit
  instanceId: string,
  tickets: Ticket[],
  signoffEnabled: boolean,
  onAddTicket?: () => void,

  // Page display props
  selectedTicketId: string | null,
  setSelectedTicketId: (string) => void,
  currentStep: string | null,
  goToStep: (string) => void,
  setVisible: (boolean) => void,
  visible: boolean,
  goBack: () => void,
};

const TicketsPageDisplay = ({
  assignment,
  instanceId,
  tickets,
  signoffEnabled,
  onAddTicket,
  ...pageDisplayProps
}: TicketsPageDisplayProps) => {
  const intlz = useI18n();
  const [signoff, setSignoff] = useState(null);

  const {
    currentStep,
    setVisible,
    goToStep,
    selectedTicketId,
    setSelectedTicketId,
    visible,
  } = pageDisplayProps;

  useEffect(() => {
    if (!visible) {
      setSelectedTicketId(null);
      goToStep(null);
    }
  }, [visible, setSelectedTicketId, goToStep]);

  const currentTicket = tickets.find((t) => t.id === selectedTicketId) || null;

  // TODO send to View Ticket if Scale generated ticket
  const titleForStep = (step: string | null) => {
    switch (step) {
      case Steps.newTicket:
        return intlz('New Ticket');
      case Steps.editTicket:
        return (currentTicket && currentTicket.signoffs.length > 0)
          ? intlz('View Ticket')
          : intlz('Edit Ticket');
      case Steps.getSignature:
        return intlz('Sign Off');
      case Steps.confirmation:
        return intlz('Thank You!');
      default:
        return '';
    }
  };

  return (
    <FullScreenPage
      title={titleForStep(currentStep)}
      onClose={() => setVisible(false)}
      hasBottomGutter={false}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...pageDisplayProps}
    >
      {(currentStep === Steps.newTicket || currentStep === Steps.editTicket) && (
        <div className={styles.ticketPageDisplayContainer}>
          <AddEditTicketForm
            assignment={assignment}
            instanceId={instanceId}
            ticket={currentTicket}
            signoffEnabled={signoffEnabled}
            onComplete={() => {
              setVisible(false);
              onAddTicket();
            }}
            onSignoff={() => {
              goToStep(Steps.getSignature);
              onAddTicket();
            }}
            onRemove={() => setVisible(false)}
            setSelectedTicketId={setSelectedTicketId}
          />
        </div>
      )}
      {currentStep === Steps.getSignature && currentTicket && (
        <GetSignoffPage
          assignment={assignment}
          instanceId={instanceId}
          ticket={currentTicket}
          onSignoff={() => goToStep(Steps.addSignature)}
        />
      )}
      {currentStep === Steps.addSignature && selectedTicketId && (
        <SignaturePage
          instanceId={instanceId}
          instanceAttachmentId={selectedTicketId}
          onClose={() => {
            setVisible(false);
            goToStep(Steps.newTicket);
          }}
          onComplete={(data) => {
            setSignoff(data);
            goToStep(Steps.confirmation);
          }}
        />
      )}
      {currentStep === Steps.confirmation && (
        <SignedOffPage
          signoff={signoff}
          onComplete={() => setVisible(false)}
        />
      )}
    </FullScreenPage>
  );
};

TicketsPageDisplay.defaultProps = {
  onAddTicket: () => { },
};

TicketsPageDisplay.Steps = Steps;

export default TicketsPageDisplay;
