import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Higher Order Components
import {
  compose, branch, mapProps, renderNothing,
} from 'recompose';
import { graphql } from '@apollo/client/react/hoc';

// Components - Quark
import { useI18n } from 'internationalization';
import { Check, Close } from 'common-ui/Icon';
import { Notification } from 'OldCommon/Notification';

// Components - Tuna
import {
  ERROR,
  INVITATION_ACCEPTED,
  INVITATION_DECLINED,
  JOB_ACCEPTED,
  JOB_DECLINED,
  ONBOARDING_COMPLETED,
  PROFILE_UPDATED,
  NOTIFICATIONS_UPDATED,
  RECEIPT_CREATED,
  RECEIPT_UPDATED,
  LOGIN_FAILED,
  CODE_FAILED,
  CODE_TOO_MANY_ATTEMPTS,
  LEAVE_COMPANY_FAILED_IS_ADMIN,
  LEAVE_COMPANY_FAILED_ONLY_COMPANY,
} from 'Notification/NotificationTypes';
import { RETRIEVE_NOTIFICATION, SET_NOTIFICATION } from 'apollo';

import styles from './styles.module.scss';

const LoginFailed = () => (
  <div
    className={`${styles.notification}
      ${styles.mobile}
      ${styles.inline}
    `}
  >
    <div className={styles.label}>
      New to Tread?
      <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/newtotread">
        {' Click here '}
      </a>
      {'to get started >'}
    </div>
  </div>
);

const NullableNotificationBanner = ({ message, type, dismissNotification }) => {
  useEffect(() => {
    setTimeout(dismissNotification, 6000);
  }, [dismissNotification]);

  const intlz = useI18n();

  let icon;
  let msg;
  if (type === LOGIN_FAILED) {
    return (<LoginFailed />);
  }

  switch (type) {
    case ERROR:
      icon = <Close />;
      msg = message || intlz('An error has occured');
      break;
    case INVITATION_ACCEPTED:
      icon = <Check />;
      msg = message || intlz('Joined company');
      break;
    case INVITATION_DECLINED:
      icon = <Check />;
      msg = message || intlz('Invitation to company has been declined');
      break;
    case JOB_ACCEPTED:
      icon = <Check />;
      msg = message || intlz('Job has been added to your list');
      break;
    case JOB_DECLINED:
      icon = <Check />;
      msg = message || intlz('Job has been declined');
      break;
    case ONBOARDING_COMPLETED:
      icon = <Check />;
      msg = message || intlz('Account has been created');
      break;
    case PROFILE_UPDATED:
      icon = <Check />;
      msg = message || intlz('Profile has been updated');
      break;
    case NOTIFICATIONS_UPDATED:
      icon = <Check />;
      msg = message || intlz('Notifications have been updated');
      break;
    case RECEIPT_CREATED:
      icon = <Check />;
      msg = message || intlz('Receipt has been created');
      break;
    case RECEIPT_UPDATED:
      icon = <Check />;
      msg = message || intlz('Receipt has been updated');
      break;
    case CODE_FAILED:
      icon = <Close />;
      msg = message || intlz('Code was incorrect');
      break;
    case CODE_TOO_MANY_ATTEMPTS:
      icon = <Close />;
      msg = message || intlz('Sorry, Too many attempts');
      break;
    case LEAVE_COMPANY_FAILED_IS_ADMIN:
      icon = <Close />;
      msg = message || intlz('You cannot leave a company if you are an admin');
      break;
    case LEAVE_COMPANY_FAILED_ONLY_COMPANY:
      icon = <Close />;
      msg = message || intlz('You cannot leave your only company');
      break;
    default:
      icon = <Check />;
  }

  return (
    <div>
      <Notification
        mobile
        inline
        negative={type === ERROR}
        progress={type !== ERROR}
        leftIcon={icon}
        label={msg}
      />
    </div>
  );
};

NullableNotificationBanner.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  dismissNotification: PropTypes.func.isRequired,
};

NullableNotificationBanner.defaultProps = {
  type: null,
  message: null,
};

export default compose(
  graphql(RETRIEVE_NOTIFICATION),
  branch(
    (props) => props.data.loading
      || !props.data.notification
      || !props.data.notification.type,
    renderNothing,
  ),
  mapProps((props) => ({
    type: props.data.notification.type,
    message: props.data.notification.message,
  })),
  graphql(SET_NOTIFICATION, {
    name: 'dismissNotification',
    options: { variables: { message: null, type: null } },
  }),
)(NullableNotificationBanner);
