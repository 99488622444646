// @flow
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

const ALL_PERMISSIONS = [
  // Project permissions
  'CreateProjects',
  'ViewOwnProjects',
  'EditOwnProjects',
  'RemoveOwnProjects',
  'CopyOwnProjects',
  'ViewAllProjects',
  'EditAllProjects',
  'RemoveAllProjects',
  'CopyAllProjects',
  'DownloadProjects',
  'ShareProjects',
  'CanRetroDispatch',
  // Company permissions
  'AddCompany',
  'EditCompany',
  // Hire permissions
  'ViewAssignableHires',
  'CreateInternalHires',
  'RemoveInternalHires',
  'EditInternalHires',
  'ViewInternalHires',
  // Subcontractors
  'CreateSubcontractors',
  'RemoveSubcontractors',
  'EditSubcontractors',
  'ViewSubcontractors',
  // Admins
  'AddAdmin',
  'RemoveAdmin',
  // Assignment permissions
  'CreateAssignments',
  'EditAssignments',
  'CancelAssignments',
  'SendAssignments',
  'ReassignAssigments',
  // Targets
  'CreateTargets',
  'ViewAllTargets',
  'ViewOwnTargets',
  'EditAllTargets',
  'EditOwnTargets',
  'FinishTargets',
  'RemoveTargets',
  'ViewTargetsAsTagged',
  'ViewTargetsAsClient',
  'ViewTargetsAsCompanyTagged',
  // Requests
  'AddSubdispatchRequests',
  'ViewSubdispatchRequests',
  'AcceptDeclineRequests',
  // Receipts
  'ViewOwnReceipts',
  'ViewAllReceipts',
  // Tickets
  'AddTicket',
  'EditAllTickets',
  'EditOwnTickets',
  'ViewTicketsAsExternalSupervisor',
  'ViewTicketsAsDOT',
  // Timesheets
  'ViewTimesheetsAsExternalSupervisor',
  'ViewTimesheetsAsDOT',
  'ViewAggregatedTimesheets',
  // Internal Requests
  'CreateInternalRequests',
  'CopyInternalRequests',
  'CancelInternalRequests',
  'ReviseInternalRequests',
  'ViewAllInternalRequests',
  'ViewOwnInternalRequests',
  // Targets
  'CreateTargets',
  'EditAllTargets',
  'EditOwnTargets',
  'FinishTargets',
  'RemoveTargets',
  // Share via Email
  'SendEmails',
  // Insights
  'ViewInsightsDash',
  'ViewCompanyComplianceDash',
  'ViewWeeklyActivityDash',
  'ViewCycleTimeDash',
  'ViewSiteGeofenceDash',
  'ViewAlertsDash',
  'ViewBillingDash',
  'ViewHirePerfDash',
  'ViewInternalSummaryDash',
  'ViewProgressDash',
  'ViewScheduleSummaryDash',
  'ViewDailyHiresDash',
  'ViewHirePerfAndSafetyDash',
  'ViewSafetyDash',
  'ViewSiteTrafficDash',
  'ViewSiteActivityBeta',
  'ViewDriverLocationsDash',
  'ViewTicketToTicketDash',
  'ViewProjectSummaryDash',
  'ViewDriverSummaryDash',
  'ViewUtilizationDash',
  'ViewPaySummaryDash',
  'ViewDriverToPaverDash',
  // Digital Signoff
  'DigitalSignoff',
  // Smart Scheduler
  'SmartScheduler',
  'CancelAllProjects',
  // Company Settings
  'ViewCompanySettings',
  'EditCompanySettings',
  'AddCompanyLogo',
  // Sites
  'ViewAllSites',
  'ViewOwnSites',
  'EditAllSites',
  'EditOwnSites',
  'CreateSites',
  'ViewDriverRoute',
  // CSV Receipts
  'DownloadTicketsCSV',
  'GlobalSearch',
  // Modules
  'ViewDispatchModule',
  'ViewLiveModule',
  'ViewPeopleModule',
  'ViewTargetsModule',
  'ViewSitesModule',
  'ViewHistoryModule',
  'ViewRequestsModule',
  'ViewTicketsModule',
  'ViewSettingsModule',
  'ViewDOTPortalModule',
  'ViewQCVTModule',
  'ViewPaymentsModule',
  'ViewTimesheetsModule',

  // Clients
  'AddCompanyClient',
  'ViewCompanyClients',
  'EditCompanyClients',
  // Self Log
  'CanSelfLog',
  'ViewEstimatedDuration',
  // ExternalRequests
  'CreateExternalRequests',
  'CancelExternalRequests',
  'ReviseExternalRequests',
  'ViewAllExternalRequests',
  'ViewOwnExternalRequests',
  'ViewHoursScheduled',
  'ViewLiveCycleInsights',

  // Invoice and Billing
  'CreateSalesInvoice',
  'MoveTickets',
  'CanEndDriverLog',
  // Data Settings
  'EditDataSettings',
  'EditEquipmentTypes',
  // Configurable Fields
  'CreateConfigurableField',

  'CanHotDispatch',

  'ViewDriverTripTimesDash',
  'DisableDriverDeclineOption',
  'RequireExternalTargets',

  // In-Field Dispatch
  'TimesheetDispatch',
  'TimesheetDispatchWithForeman',
];

const PERMISSIONS_MAP = gql`
  query permissionsMap($permissions: [String!]!, $companyID: ID) {
    permissionsMap(permissions: $permissions, companyID: $companyID) {
      permKey,
      hasPermission
    }
  }
`;

const usePermissions = (companyID = null) => {
  const { data, loading, error } = useQuery(
    PERMISSIONS_MAP,
    {
      variables: {
        permissions: ALL_PERMISSIONS,
        companyID,
      },
      // Fetch from cache since this hook can be used multiple times in a page
      fetchPolicy: 'cache-first',
      skip: companyID === 0,
    },
  );

  return useMemo(() => {
    if (loading || error || !data) {
      // Default permissions to false to avoid flashing functionality, then
      // later removing it.
      return ALL_PERMISSIONS.reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    }

    const { permissionsMap } = data;

    return permissionsMap.reduce((acc, permission) => {
      const { permKey, hasPermission } = permission;
      acc[permKey] = hasPermission;
      return acc;
    }, {});
  }, [data, loading, error]);
};

export default usePermissions;
