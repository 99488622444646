// @flow
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import useGetTicketNotifications from './useGetTicketNotifications';

const CLEAR_NOTIFICATIONS_MUTATION = gql`
  mutation ClearNotifications($ids: [ID!]!) {
    clearNotifications(ids: $ids) {
      id
      clearedAt
    }
  }
`;

const useClearAssignmentNotifications = () => {
  const [notifications] = useGetTicketNotifications();
  const [clearNotifications] = useMutation(CLEAR_NOTIFICATIONS_MUTATION);

  return useCallback((assignmentId: string) => {
    const idsToClear = notifications[assignmentId];
    if (!idsToClear) return [];
    return clearNotifications({
      variables: {
        ids: idsToClear,
      },
    }).then((data) => data.clearNotifications);
  }, [clearNotifications, notifications]);
};

export default useClearAssignmentNotifications;
