// @flow
import React from 'react';
import moment from 'moment-timezone';
import { useTimezone } from 'common-ui/Hooks';
import styles from './styles.module.scss';

type DateDisplayProps = {
  date: string,
};

const DateDisplay = ({ date }: DateDisplayProps) => {
  const timezone = useTimezone();

  return (
    <div className={styles.dateDisplay}>
      <div className={styles.dateMonth}>
        {moment.tz(date, timezone).format('MMM')}
      </div>
      <div className={styles.dateNumber}>
        {moment.tz(date, timezone).format('D')}
      </div>
      <div className={styles.dateWeekday}>
        {moment.tz(date, timezone).format('ddd')}
      </div>
    </div>
  );
};

export default DateDisplay;
