import moment from 'moment-timezone';

export const GetComponentKey = () => {
  const key = Math.random();
  return `${key}`;
};

export const SignedOffStatusMap = {
  NotSignedOff: 'NotSignedOff',
  SignOffRequested: 'SignOffRequested',
  SignedOff: 'SignedOff',
};

export const FetchPolicy = {
  Network: 'Network',
  Cache: 'Cache',
};

export const GetDisplayText = (value) => {
  if (!value) return "N/A";
  return value;
}

export const DisplayTimeText = (time, tz) => {
  if (!time) return '';
  return moment.tz(time, tz).format('h:mm a');
}