export const ERROR = 'ERROR';
export const INVITATION_ACCEPTED = 'INVITATION_ACCEPTED';
export const INVITATION_DECLINED = 'INVITATION_DECLINED';
export const JOB_ACCEPTED = 'JOB_ACCEPTED';
export const JOB_DECLINED = 'JOB_DECLINED';
export const ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED';
export const RECEIPT_CREATED = 'RECEIPT_CREATED';
export const RECEIPT_UPDATED = 'RECEIPT_UPDATED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const CODE_FAILED = 'CODE_FAILED';
export const CODE_TOO_MANY_ATTEMPTS = 'CODE_TOO_MANY_ATTEMPTS';
export const LEAVE_COMPANY_FAILED_IS_ADMIN = 'LEAVE_COMPANY_FAILED_IS_ADMIN';
export const LEAVE_COMPANY_FAILED_ONLY_COMPANY = 'LEAVE_COMPANY_FAILED_ONLY_COMPANY';
