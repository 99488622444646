import React, { useState } from 'react';

// Higher Order Components
import { compose, withHandlers } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';

import { post } from 'utils/axios';

// Components - Quark
import {
  Input, withFormik, Form, connect,
} from 'OldCommon/Form';
import { Button } from 'OldCommon/Button';
import ID from 'common-ui/ConsistentIDs';
import { useI18n } from 'internationalization';
import { SET_NOTIFICATION } from 'apollo';
import { LOGIN_FAILED } from 'Notification/NotificationTypes';
import { Formik } from 'formik';
import styles from './styles.module.scss';


const RequestCodeFormProps = {
  formik: Formik,
};

const RequestCodeForm = ({
  formik,
}: RequestCodeFormProps) => {
  const intlz = useI18n();

  // Show customized error message that is translated
  const { phoneNumber } = formik.values;
  const phoneNumberIsValid = phoneNumber.length > 4 && /^[\d()\-+\s]+$/.test(phoneNumber);
  const [hasHitGetCode, setHasHitGetCode] = useState(false); // Only show once message is set

  return (
    <Form>
      <div className={styles.phoneNumberInput}>
        <Input
          labelClassName={styles.phoneNumberInputLabel}
          label={intlz('Phone Number')}
          type="tel"
          name="phoneNumber"
          placeholder="ex. 416 555 5555"
          id={ID.create(ID.Areas.Login, ID.Types.Input, ID.Labels.PhoneNumber)}
          autoFocus
        />
        { !phoneNumberIsValid && hasHitGetCode && (
        <div className={styles.phoneNumberErrorMessage}>
          {intlz('Please enter a valid phone number.')}
        </div>
        )}
      </div>
      <Button
        type="submit"
        onClick={() => setHasHitGetCode(true)}
        primary
        label={intlz('Get Code')}
        fullWidth
        pad
        id={ID.create(ID.Areas.Login, ID.Types.Button, ID.Labels.VerificationCode)}
        className={styles.getCodeButton}
      />
    </Form>
  );
};

const formConfig = {
  initialValues: { phoneNumber: '' },
  mapPropsToValues: () => ({ phoneNumber: '' }),
  validate: (values) => {
    const errors = {};
    if (values.phoneNumber.length < 4 || !/^[\d()\-+\s]+$/.test(values.phoneNumber)) {
      errors.phoneNumber = '';
    }
    return errors;
  },
  handleSubmit: ({ phoneNumber }, { props, setErrors, setSubmitting }) => {
    setSubmitting(true);
    props
      .requestLoginCode(phoneNumber)
      .then(() => {
        props.onSuccess();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response && error.response.data) {
          props.notify({
            variables: { type: LOGIN_FAILED, message: 'Login Failed' },
          });

          const {
            response: {
              data,
            },
          } = error;

          if (data.msg) {
            setErrors({ phoneNumber: data.msg });
          } else {
            setErrors({ phoneNumber: data.toString() });
          }
        }
      });
  },
};

export default compose(
  withHandlers({
    requestLoginCode: () => (phoneNumber) => {
      const params = new URLSearchParams();
      params.append('phone_number', `+1${phoneNumber}`);
      params.append('source', 'operator_app');
      return post('token/code', params);
    },
  }),
  graphql(SET_NOTIFICATION, { name: 'notify' }),
  withFormik(formConfig),
)(connect(RequestCodeForm));
