import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { gql, useQuery } from '@apollo/client';
import {
  CANCELLED, LOGGING, ACCEPTED, PENDING, PAUSED,
} from 'utils/constants/job';
import { useTimezone } from 'common-ui/Hooks';

export const ASSIGNMENTS_QUERY = gql`
  query assignmentsByAssignedQuery {
    myAssignments {
      id
      startTime
      assignedStatus
      sendStatus
      schedule {
        id
        assignments {
          id
          startTime
          assignedStatus
          sendStatus
          pickupArrival
          dropoffDeparture
          companyId
          company {
            id
          }
          pickUp
          pickUpSite {
            id
            name
            address
            notes
            type
            coordinates {
              latitude
              longitude
            }
          }
          dropOff
          dropOffSite {
            id
            name
            address
            notes
            type
            coordinates {
              latitude
              longitude
            }
          }
          client
          companyName
          haulerRate
          haulerRateUnit
          quantity
          quantityUnit
          assignmentInstances {
            id
          }
          project {
            id
            name
          }
          configurableFields {
            id
            value
          }
        }
      }
      pickupArrival
      dropoffDeparture
      companyId
      company {
        id
      }
      pickUp
      pickUpSite {
        id
        name
        address
        notes
        type
        coordinates {
          latitude
          longitude
        }
      }
      dropOff
      dropOffSite {
        id
        name
        address
        notes
        type
        coordinates {
          latitude
          longitude
        }
      }
      client
      companyName
      haulerRate
      haulerRateUnit
      assignmentInstances {
        id
      }
      project {
        id
        name
        type
        pickUp
        pickUpSite {
          id
          name
          address
          notes
          type
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
    whoami {
      id
      timezone
    }
  }
`;

export const useGetAllAssignments = () => {
  const {
    loading, error, data, refetch,
  } = useQuery(ASSIGNMENTS_QUERY);
  const timezone = useTimezone();

  return useMemo(() => {
    if (loading || error) return [[], refetch];
    const byPickupArrival = (a, b) => moment.tz(a.pickupArrival, timezone).isAfter(moment.tz(b.pickupArrival, timezone));

    const assignments = data.myAssignments
      .map((a) => {
        if (a.schedule) {
          const assignments = a.schedule.assignments.sort(byPickupArrival);
          return {
            ...a,
            schedule: {
              ...a.schedule,
              assignments,
            },
          };
        }
        return a;
      });

    return [assignments, refetch];
  }, [loading, error, data, refetch, timezone]);
};

// Returns the assignment id of the featured assignment, or null if it doesn't exist
export const useFeaturedAssignmentId = () => {
  const [assignments] = useGetAllAssignments();
  const timezone = useTimezone();
  return useMemo(() => {
    if (!assignments) return null;

    const loggableAssignments = assignments
      .filter((a) => (
        a.sendStatus === LOGGING
        || (
          a.assignedStatus !== CANCELLED
          && a.sendStatus === ACCEPTED
          && moment.tz(a.startTime, timezone).isSameOrAfter(moment.tz(timezone).subtract(24, 'hours'))
          && moment.tz(a.startTime, timezone).isSameOrBefore(moment.tz(timezone).add(12, 'hour'), 'minute')
        )
      ))
      .sort((a, b) => (moment(a.startTime).isBefore(b.startTime) ? -1 : 1));

    const loggingAssignment = loggableAssignments.find((a) => a.sendStatus === LOGGING);

    const nextAcceptedAssignment = loggableAssignments[0];

    const featuredAssignment = loggingAssignment || nextAcceptedAssignment || null;
    return featuredAssignment && featuredAssignment.id;
  }, [assignments, timezone]);
};

function bySchedule({ id, schedule }) {
  return (schedule && schedule.id) || `job-${id}`;
}

export const useGroupedAssignments = () => {
  const [assignments] = useGetAllAssignments();
  const timezone = useTimezone();
  return useMemo(() => {
    if (!assignments) return [null, null];

    const pendingJobs = uniqBy(assignments.filter((a) => a.sendStatus === PENDING), bySchedule);
    const acceptedJobsGroupedByDay = {};
    // Add an entry for today (shows up regardless of having jobs or not)
    const todayKey = moment.tz(timezone).format('YYYY-MM-DD');
    acceptedJobsGroupedByDay[todayKey] = [];
    // Group the assignments into arrays based on their start date
    assignments
      .filter((a) => a.sendStatus === LOGGING || a.sendStatus === ACCEPTED || a.sendStatus === PAUSED)
      .forEach((assignment) => {
        const assignmentDate = moment.tz(assignment.startTime, timezone).format('YYYY-MM-DD');
        const dateArray = acceptedJobsGroupedByDay[assignmentDate] || [];
        dateArray.push(assignment);
        acceptedJobsGroupedByDay[assignmentDate] = dateArray;
      });
    // Sort the assignment arrays by time (with logging ones first in a tie)
    Object.keys(acceptedJobsGroupedByDay).forEach((date) => {
      const dateArray = acceptedJobsGroupedByDay[date];
      const sortedDateArray = dateArray.sort((a, b) => {
        const aStart = moment.tz(a.startTime, timezone);
        const bStart = moment.tz(b.startTime, timezone);
        if (aStart.isSame(bStart, 'minute')) return a.sendStatus === LOGGING ? -1 : 1;
        return aStart.isBefore(bStart) ? -1 : 1;
      });
      acceptedJobsGroupedByDay[date] = sortedDateArray;
    });
    return [pendingJobs, acceptedJobsGroupedByDay];
  }, [assignments, timezone]);
};
