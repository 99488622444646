import React from 'react';

const OneDot = (props) => {
  const color = props.color ? props.color : "#106FEE";

  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3.5" fill={color} stroke={color}/>
    </svg>
  );
};

export default OneDot;
