// @flow
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

const WHOAMI_QUERY = gql`
  query whoami {
    whoami {
      id
      firstName
      lastName
      avatar {
        thumbnailURL
        getURL
      }
    }
  }
`;

export const useCurrentUser = () => {
  const { loading, error, data } = useQuery(WHOAMI_QUERY);
  return useMemo(() => (
    (!loading && !error) ? data.whoami : null
  ), [loading, error, data]);
};

export default useCurrentUser;
