// @flow
import React, { useState } from 'react';
import { Close } from 'common-ui/Icon';
import { BaseModal } from 'components/OldCommon/Modal';
import { Button, BackButtonTransparent } from 'common-ui/Button';
import { useI18n } from 'internationalization';
import styles from './styles.module.scss';

type DeclineJobModalProps = {
  visible: boolean,
  onClose: () => void,
  onDecline: () => void,
  companyName: string,
}

const DeclineJobModal = ({
  visible,
  onClose,
  onDecline,
  companyName,
}: DeclineJobModalProps) => {
  const intlz = useI18n();
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseModal
      isOpen={visible}
      heading={intlz('Are you sure?')}
      handleClose={onClose}
    >
      <div className={styles.textBody}>
        {intlz('Declining the job will notify')}
        &nbsp;
        <span className={styles.textBold}>{companyName}</span>
      </div>
      <div className={styles.modalButtons}>
        <Button
          fullWidth
          disabled={disabled}
          large
          styleName="destructive"
          icon={<Close />}
          text={intlz('Decline job')}
          onClick={() => {
            setDisabled(true);
            onDecline()
              .finally(() => setDisabled(false));
          }}
        />
        <BackButtonTransparent
          styleName="neutral"
          text={intlz('Back')}
          onClick={onClose}
        />
      </div>
    </BaseModal>
  );
};


export default DeclineJobModal;
