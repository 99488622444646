// @flow
import React from 'react';
import { Attach } from 'common-ui/Icon';
import ClickableDiv from 'common-ui/ClickableDiv';
import AttachmentsModal, { useAttachmentsModal } from './AttachmentsModal';
import styles from './styles.module.scss';
import type { Attachment } from './types';

type AttachmentsListProps = {
  attachments: Attachment[],
};

const AttachmentsList = ({ attachments }: AttachmentsListProps) => {
  const [openModal, attachmentsModalProps] = useAttachmentsModal(attachments);
  if (!attachments) return null;
  return (
    <>
      {attachments.map((a, i) => (
        <ClickableDiv
          key={a.uploadData.getURL}
          className={styles.attachmentItem}
          onClick={() => openModal(i)}
        >
          <Attach className={styles.attachmentItemIcon} />
          {a.uploadData.filename}
        </ClickableDiv>
      ))}
      <AttachmentsModal {...attachmentsModalProps} />
    </>
  );
};

export default AttachmentsList;
