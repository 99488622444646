import React from 'react';

type RightArrowPropTypes = {
  viewBox?: string,
  height?: number,
  width?: number,
};

const RightArrow = (props: RightArrowPropTypes) => {
  const { viewBox, width, height } = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            {...props}
        >
            <title>RightArrow</title>
            <path
                d="M4.66658 10L16.3333 10"
                stroke="#490BF4"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.4997 15.8332L16.333 9.99984L10.4997 4.1665"
                stroke="#490BF4"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
    </svg>
  );
};

RightArrow.defaultProps = {
    viewBox: '0 0 21 20',
    width: 21,
    height: 20,
};

export default RightArrow;
