import { Heading } from 'OldCommon/Text';
import { useI18n } from 'internationalization';
import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import {
  PaginationNext,
  PaginationPrev,
} from 'common-ui/Icon';
import _ from 'lodash';
import styles from './styles.module.scss';
import JobAndReceiptTopNav from '../Common/JobAndReceiptTopNav';
import PullToRefresh from '../Common/PullToRefresh';
import NoTimesheetsSVG from './NoTimesheetsSVG';
import TimesheetsByDate from './TimesheetsByDate/TimesheetsByDate';
import useCompanies from './Hooks/useCompanies';
import { useTimesheets, PAGE_LIMIT } from './Hooks/useTimesheets';
import { GetComponentKey, FetchPolicy } from './utils';
import usePageURL from './Hooks/usePageURL';
import { useTimezone } from 'common-ui/Hooks';

const TimesheetsOverview = ({ onRefresh }) => {
  const intlz = useI18n();
  const companies = useCompanies();

  const companyIDs = [];
  if (companies) {
    const cIDs = companies.map((c) => c.id);
    cIDs.forEach((id) => companyIDs.push(id));
  }

  const [page, setPage] = usePageURL();
  const [pageState, setPageState] = useState(null);
  const [fetchPolicy, setFetchPolicy] = useState(FetchPolicy.Network);
  const timezone = useTimezone();
  const [tsData, { loading, error }] = useTimesheets(companyIDs, page, { fetchPolicy, timezone });

  const timesheetsByDate = [];
  const paginationData = {
    total: 0,
  };

  if (!loading && !error) {
    tsData.timesheetsGroupByDate.forEach((t) => timesheetsByDate.push(t));
    paginationData.total = tsData.total;
  }

  useEffect(() => {
    setFetchPolicy(FetchPolicy.Cache);
  }, [pageState]);

  useEffect(() => {
    if (!_.isEqual(page, pageState)) {
      setPageState(page);
    }
  }, [page]);

  return (
    <>
      <JobAndReceiptTopNav />
      <PullToRefresh onRefresh={onRefresh}>
        <div className={styles.wrap}>
          <div className={styles.title}>
            <Heading>{intlz('Timesheets')}</Heading>
          </div>
          {loading ? (
            <div className={styles.timesheetsContainer}>
              <div className={styles.loadingSpinnerContainer}>
                <div className={styles.loadingSpinner} />
              </div>
            </div>
          ) : (
            <div className={styles.timesheetsContainer}>
              {timesheetsByDate.length > 0
                ? (timesheetsByDate.map((ts) => (
                  <TimesheetsByDate
                    key={GetComponentKey()}
                    date={ts.date}
                    timesheets={ts.timesheets}
                  />
                )))
                : (
                  <div className={styles.emptyState}>
                    <NoTimesheetsSVG />
                    <div className={styles.textBold}>{intlz('There aren’t any timesheets here yet.')}</div>
                    <div className={styles.text}>{intlz('Once you complete a job, you’ll see your timesheets here.')}</div>
                  </div>
                )}
              <div className={styles.paginationControls}>
                <Pagination
                  showLessItems
                  hideOnSinglePage
                  current={parseInt(page, 10)}
                  total={paginationData.total}
                  pageSize={PAGE_LIMIT}
                  onChange={setPage}
                  prevIcon={<PaginationPrev />}
                  nextIcon={<PaginationNext />}
                />
              </div>
            </div>
          )}

        </div>

      </PullToRefresh>
    </>
  );
};
export default TimesheetsOverview;
