/**
 * These are the types for the interaction levels used in TreadWebID
 *
 * If there are any significant changes please update ReadMe.md in this directory
 */
// eslint-disable-next-line import/prefer-default-export
export const Types = {
  Button: 'Button',
  Input: 'Input',
  Dropdown: 'Dropdown',
  ReadOnly: 'ReadOnly',
  Option: 'Option',
  Link: 'Link',
  Alert: 'Alert',
};

export type Type = $Keys<typeof Types>
