import React from 'react';

const CycleSite = ({ dropoff, ...rest }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="#ADADAD" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect x="1" y="1" width="24" height="24" rx="13" />
    <path d="M13.6497 6.50008C13.6497 6.00302 13.2467 5.60008 12.7497 5.60008C12.2526 5.60008 11.8497 6.00302 11.8497 6.50008L13.6497 6.50008ZM12.7497 18.4167L12.1275 19.0671C12.4755 19.4 13.0239 19.4 13.3718 19.0671L12.7497 18.4167ZM17.9552 14.6821C18.3143 14.3385 18.3269 13.7688 17.9833 13.4097C17.6397 13.0505 17.07 13.0379 16.7108 13.3815L17.9552 14.6821ZM8.78851 13.3815C8.42935 13.0379 7.85964 13.0505 7.51603 13.4097C7.17241 13.7688 7.18502 14.3385 7.54418 14.6821L8.78851 13.3815ZM11.8497 6.50008L11.8497 18.4167L13.6497 18.4167L13.6497 6.50008L11.8497 6.50008ZM13.3718 19.0671L17.9552 14.6821L16.7108 13.3815L12.1275 17.7664L13.3718 19.0671ZM13.3718 17.7664L8.78851 13.3815L7.54418 14.6821L12.1275 19.0671L13.3718 17.7664Z" fill="white" transform={dropoff ? '' : 'rotate(180 13 13)'} />
  </svg>
);

CycleSite.defaultProps = {
  dropoff: false,
};

export default CycleSite;
