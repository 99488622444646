// @flow
import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import { useI18n } from 'internationalization';
import { Button } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import ConfigurableFields from 'common-ui/ConfigurableFields';
import { Close } from 'common-ui/Icon';
import styles from './styles.module.scss';
import useUpdateTimesheetConfigurableFields from '../Hooks/useUpdateTimesheetConfigurableFields';

type TimesheetRequestedInfoFormProps = {
  setBottomSheetVisible: boolean => void,
  configurableFields: any,
  configurableFieldValues: { id: string, value: string }[],
  readOnly: boolean,
  timesheetID: string
};

const CONFIG_FIELD_KEY = 'tsConfigurableFields';

const TimesheetRequestedInfoForm = ({
  setBottomSheetVisible,
  configurableFields,
  configurableFieldValues,
  readOnly,
  timesheetID
}: TimesheetRequestedInfoFormProps) => {
  const intlz = useI18n();
  const formikRef = useRef();
  const updateTimesheetConfigurableFields = useUpdateTimesheetConfigurableFields();
  const fieldValues = configurableFieldValues.reduce((acc, field) => {
    const { id, value } = field;
    return { ...acc, [`${CONFIG_FIELD_KEY}-${id}`]: value };
  }, {});

  const handleSubmit = (values, formik) => {
    const formikRefVals = formikRef.current.values;
    const allConfigFields = [];
    for (const v in formikRefVals) {
      if (!v) continue;
      const name = v.split("-")[0];
      const id = v.split("-")[1];
      if (!id || name != "tsConfigurableFields") continue;
      const val = formikRefVals[v];
      allConfigFields.push({
        id: id,
        value: val,
      })
    }
    if (values && values.tsConfigurableFields) {
      updateTimesheetConfigurableFields(
        timesheetID,
        allConfigFields
      );
      setBottomSheetVisible(false);
    }
  };

  return (
    <Formik innerRef={formikRef} initialValues={fieldValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue, touched, setFieldTouched, dirty }) => (
        <Form>
          <div className={styles.requestedInfoHeader}>
            <div className={styles.textHeader}>
              {intlz('Requested Info from Dispatcher')}
            </div>
            <ClickableDiv onClick={() => setBottomSheetVisible(false)}>
              <Close />
            </ClickableDiv>
          </div>

          {!readOnly && (
            <div className={styles.requestedInfoSubHeader}>
              {intlz('Please fill out fields below as requested by your employer to complete your timesheet.')}
            </div>
          )}

          <div className={styles.requestedInfoFields}>
            <ConfigurableFields
              name={CONFIG_FIELD_KEY}
              fields={configurableFields}
              readOnly={readOnly}
              formikValues={values}
              formikSetFieldValue={setFieldValue}
              formikTouched={touched}
              formikSetFieldTouched={setFieldTouched}
            />
          </div>

          {!readOnly && (
            <Button
              fullWidth
              large
              className={styles.requestedInfoButton}
              text={intlz('Save Changes')}
              type='submit'
              disabled={!dirty}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default TimesheetRequestedInfoForm;
