// @flow
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

const usePageURL = () => {
  const history = useHistory();
  const location = useLocation();
  const urlPage = queryString.parse(location.search || '').page;
  const page = parseInt(urlPage, 10) || 1;

  const setPage = (newPage: number) => {
    const formattedURL = queryString.parse(location.search || '');
    history.push({
      search: queryString.stringify({
        ...formattedURL,
        page: newPage,
      }),
    });
  };
  return [page, setPage];
};

export default usePageURL;
