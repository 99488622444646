import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useI18n } from 'internationalization';
import Page from 'components/Common/Page';
import { Redirect, useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import type { Match } from 'react-router';
import { GetComponentKey } from '../../utils';
import TicketCard from '../TicketCard/TicketCard';
import { SignedOffStatusMap, DisplayTimeText } from '../../utils';
import { Button } from 'common-ui/Button';
import {
    Add,
} from 'common-ui/Icon';
import useTimesheet from '../../Hooks/useTimesheet';
import TicketsPageDisplay, { useTicketsPageDisplay } from '../../../../components/Tickets/TicketsPageDisplay';
import { useTimezone } from 'common-ui/Hooks';

type TicketsProps = {
    match: Match,
}

const Tickets = ({ match }: TicketsProps) => {
    const history = useHistory();
    const intlz = useI18n();

    const timesheetID = match.params.id;
    const companyID = match.params.companyId;
    const assignmentInstanceID = match.params.assignmentInstanceId;

    const [timesheet, { loading, error }] = useTimesheet(timesheetID, companyID, true);
    const timezone = useTimezone();

    const [openTicketsPage, pageDisplayProps] = useTicketsPageDisplay();

    if (loading) return <div className={styles.loadingSpinner} />;
    if (error || !timesheet) return <Redirect to={`/timesheets`} />;

    const timesheetAssignmentInstance = timesheet.assignmentInstances.filter((i) => i.id === assignmentInstanceID);

    if (timesheetAssignmentInstance.length === 0) return <Redirect to={`/timesheets`} />;

    const assignmentInstance = timesheetAssignmentInstance[0];

    const jobNumber = assignmentInstance.assignment.project.jobNumber;
    const projectName = assignmentInstance.assignment.project.name;

    const tickets = assignmentInstance.tickets.map((t) => {
        return {
            ticketID: t.id,
            startTime: DisplayTimeText(t.startTime, timezone),
            endTime: DisplayTimeText(t.endTime, timezone),
            number: t.ticketNumber,
            quantity: t.quantity,
            unit: t.haulerRateUnit,
            product: t.product,
            status: t.signoffs.filter(s => !s.deleted).length > 0 ? SignedOffStatusMap.SignedOff : SignedOffStatusMap.NotSignedOff,
            instance: t,
        }
    });

    tickets.sort((a, b) => {
        const aTime = moment(a.startTime, 'h:mma');
        const bTime = moment(b.startTime, 'h:mma');
        if (aTime.isAfter(bTime)) {
            return 1;
        } else if (bTime.isAfter(aTime)) {
            return -1;
        }
        return 0;
    })

    const assignment = { ...assignmentInstance.assignment, assignmentInstances: [timesheetAssignmentInstance[0]] };

    const projectSignOffRequired = assignmentInstance.assignment.project.signOffRequired;

    return (
        <Page title="" onBack={() => history.push(`/timesheets/${companyID}/${timesheetID}`)}>
            <div className={styles.tickets}>
                <div className={styles.jobInfo}>
                    <div className={styles.title}>{intlz('Tickets for')} :</div>
                    <div>{`${intlz('Job No.')} : ${jobNumber}`}</div>
                    <div>{`${intlz('Project')} : ${projectName}`}</div>
                </div>
                <div className={styles.ticketsContainer}>
                    {tickets.map((ticket) => (
                        <TicketCard
                            key={GetComponentKey()}
                            match={match}
                            ticketID={ticket.ticketID}
                            startTime={ticket.startTime}
                            endTime={ticket.endTime}
                            number={ticket.number}
                            status={ticket.status}
                            quantity={ticket.quantity}
                            unit={ticket.unit}
                            product={ticket.product}
                            clickEvent={() => {
                                openTicketsPage(TicketsPageDisplay.Steps.editTicket, ticket.ticketID)
                            }}
                            assignment={assignment}
                            ticket={ticket.instance}
                        />
                    ))}

                    <Button
                        text={intlz('Add Ticket')}
                        icon={<Add />}
                        className={styles.button}
                        fullWidth
                        onClick={() => openTicketsPage(TicketsPageDisplay.Steps.newTicket, null)}
                    />

                    <TicketsPageDisplay
                        assignment={assignment}
                        instanceId={assignmentInstance.id}
                        tickets={assignmentInstance.tickets}
                        signoffEnabled={projectSignOffRequired}
                        {...pageDisplayProps}
                    />
                </div>
            </div>
        </Page>
    );
};

export default Tickets;
