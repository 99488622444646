// @flow
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export const AVATAR_QUERY = gql`
  query getAccount($id: ID!) {
    account(id: $id) {
      avatar {
        getURL
        thumbnailURL
      }
    }
  }
`;

const useGetPhoto = (accountId?: number | string | null) => {
  const { loading, error, data } = useQuery(AVATAR_QUERY, {
    variables: {
      id: String(accountId),
    },
    skip: !accountId,
  });
  return useMemo(() => (
    (!loading && !error && data && data.account && data.account.avatar)
      ? (data.account.avatar.thumbnailURL || data.account.avatar.getURL)
      : null
  ), [loading, error, data]);
};

export default useGetPhoto;
