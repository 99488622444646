// @flow
import React, { useState } from 'react';
import { BaseModal } from 'OldCommon/Modal';
import { SmallButton } from 'common-ui/Button';
import { ChevronLeft, ChevronRight, Download } from 'common-ui/Icon';
import styles from './styles.module.scss';
import type { Attachment } from './types';

export const useAttachmentsModal = (attachments: Attachment[]) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const openModal = (newSelectedId: number) => {
    setVisible(true);
    setSelectedId(newSelectedId || 0);
  };
  return [openModal, {
    attachments,
    selectedId,
    setSelectedId,
    visible,
    setVisible,
  }];
};

type AttachmentsModalProps = {
  attachments: Attachment[],
  selectedId: number,
  setSelectedId: (number) => void,
  visible: boolean,
  setVisible: (boolean) => void,
};

const AttachmentsModal = ({
  attachments,
  selectedId,
  setSelectedId,
  visible,
  setVisible,
}: AttachmentsModalProps) => (
  <BaseModal isOpen={visible} handleClose={() => setVisible(false)}>
    {attachments[selectedId].uploadData.mimeType && attachments[selectedId].uploadData.mimeType.includes('image') ? (
      <img
        className={styles.img}
        src={attachments[selectedId].uploadData.getURL}
        alt="Attachment"
      />
    ) : (
      <a className={styles.pdfDownload} href={attachments[selectedId].uploadData.getURL} download>
        <Download />
        {attachments[selectedId].uploadData.filename}
      </a>
    )}
    <div className={styles.attachmentsModalControls}>
      <SmallButton
        styleName="secondary"
        icon={<ChevronLeft />}
        onClick={() => setSelectedId(((selectedId - 1 + attachments.length) % attachments.length))}
      />
      {`${selectedId + 1}/${attachments.length}`}
      <SmallButton
        styleName="secondary"
        icon={<ChevronRight />}
        onClick={() => setSelectedId((selectedId + 1) % attachments.length)}
      />
    </div>
  </BaseModal>
);

export default AttachmentsModal;
