// @flow
import React, { type Node } from 'react';
import styles from './styles.module.scss';

type DetailsTableProps = {
  children: Node,
};

const DetailsTable = ({ children }: DetailsTableProps) => (
  <div className={styles.detailsTable}>
    {children}
  </div>
);

export default DetailsTable;
