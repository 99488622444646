/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type SingeIconButtonProps = {
    className?: string,
    iconClassName?: string,
    icon: Node,
    onClick?: () => void,
    text?: string,
}

/**
 * Singe Icon Buttons
 * Guidance for user:
 * most icon-only buttons will be circular when they're
 * used independently
 *
 * Figma Spec:
 * https://www.figma.com/file/r4a5YQZUT4eZxaGf8dAcxt/Operator-Kit?node-id=4%3A16
 */
const SingleIconButton = (
  {
    className,
    iconClassName,
    // this is the styling that applies to icon
    icon,
    onClick,
    text
  }: SingeIconButtonProps,
) => {
  const {
    button,
    outerCircleParent,
    iconStyle,
    relativeParent,
    textStyle,
  } = styles;
  return (
    <button
      type="button"
      className={button}
      onClick={onClick}
    >
      <div className={classNames(outerCircleParent, className)}>
        <div className={relativeParent}>
          <div className={classNames(iconStyle, iconClassName)}>
            { icon }
          </div>
          {text && (<div className={textStyle}>{text}</div>)}
        </div>
      </div>
    </button>
  );
};

SingleIconButton.defaultProps = {
  className: undefined,
  iconClassName: undefined,
  onClick: () => {},
  text: null,
};

export default SingleIconButton;
