import { compose, branch, renderNothing } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';

const withQuery = (query, opts) => (WrappedComponent) => {
  const dataPropName = !!opts && !!opts.name ? opts.name : 'data';

  return compose(
    graphql(query, opts),
    branch(props => !props[dataPropName], renderNothing),
    branch(props => props[dataPropName].loading, renderNothing),
  )(WrappedComponent);
};

export default withQuery;
