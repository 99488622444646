// @flow
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { ERROR } from 'components/Notification/NotificationTypes';
import parseGraphqlError from 'utils/Error';
import { ASSIGNMENTS_QUERY } from 'components/Jobs/graphql';
import { SET_NOTIFICATION } from 'apollo';

const ACCEPT_JOB_MUTATION = gql`
  mutation AcceptJobMutation($id: ID!) {
    acceptAssignment(id: $id) {
      id
      sendStatus
      assignmentInstances {
        id
        assignmentId
        loggingStart
        loggingStop
        info
        tickets {
          id
          data {
            getURL
            expiry
            mimeType
            filename
          }
          deleted
        }
      }
    }
  }
`;

export const useAcceptJob = () => {
  const [acceptJob] = useMutation(ACCEPT_JOB_MUTATION);
  const [notify] = useMutation(SET_NOTIFICATION);
  return useCallback((jobId: string) => acceptJob({
    variables: {
      id: jobId,
    },
    refetchQueries: [{ query: ASSIGNMENTS_QUERY }],
  }).catch((err) => {
    notify({
      variables: { type: ERROR, message: parseGraphqlError(err) },
    });
  }), [acceptJob, notify]);
};

const REJECT_JOB_MUTATION = gql`
  mutation RejectJobMutation($id: ID!) {
    rejectAssignment(id: $id) {
      id
      sendStatus
      assignmentInstances {
        id
      }
    }
  }
`;

export const useDeclineJob = () => {
  const [declineJob] = useMutation(REJECT_JOB_MUTATION);
  const [notify] = useMutation(SET_NOTIFICATION);
  return useCallback((jobId: string) => declineJob({
    variables: {
      id: jobId,
    },
    refetchQueries: [{ query: ASSIGNMENTS_QUERY }],
  }).catch((err) => {
    notify({
      variables: { type: ERROR, message: parseGraphqlError(err) },
    });
  }), [declineJob, notify]);
};
