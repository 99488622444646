import moment from 'moment-timezone';
import _ from 'lodash';

const sendStatuses = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  LOGGING: 'LOGGING',
  PAUSED: 'PAUSED',
  DONE: 'DONE',
  TERMINATED: 'TERMINATED',
};

export const getLoggingSegments = (assignment) => {
  const activities = _.sortBy(assignment.activity, activity => activity.timestamp);

  const segments = activities.filter(a => a.fieldName === 'OperatorStatus'
    && (a.from === sendStatuses.LOGGING
      || a.to === sendStatuses.LOGGING || a.to === sendStatuses.DONE));
  const loggingSegments = _.chunk(segments, 2);

  return loggingSegments;
};

export type SecondsMinutesHours = { minutes: number, hours: number, seconds: number }

type calculateAssignmentPauseReturn = {
  totalPauseTime: SecondsMinutesHours,
  totalLoggedTime: SecondsMinutesHours,
}
export const calculateAssignmentPause = (
  assignment, instance, timezone,
): calculateAssignmentPauseReturn => {
  const activities = _.sortBy(assignment.activity, activity => activity.timestamp);

  const pausedTimes = activities.filter(a => a.fieldName === 'OperatorStatus'
    && (a.from === sendStatuses.PAUSED || a.to === sendStatuses.PAUSED));
  const pausedSegments = _.chunk(pausedTimes, 2);

  const totalPauseTimeSeconds = pausedSegments.reduce((acc, curr) => {
    let newAcc = acc;
    if (curr.length === 2) {
      const diff = moment(curr[1].timestamp).diff(moment(curr[0].timestamp), 'seconds');
      newAcc += diff;
    } else {
      const diff = moment().diff(moment(curr[0].timestamp), 'seconds');
      newAcc += diff;
    }

    return newAcc;
  }, 0);

  const startTime = moment.tz(instance.loggingStart, timezone);
  const stopTime = moment.tz(instance.loggingStop, timezone);
  const endTime = stopTime.isValid() ? stopTime : moment.tz(timezone);

  const totalDuration = moment.duration(endTime.diff(startTime, 'seconds'));
  const totalPauseTimeMinutes = Math.floor((totalPauseTimeSeconds / 60));
  const totalLoggedTimeSeconds = totalDuration - totalPauseTimeSeconds;
  const totalLoggedTimeMinutes = Math.floor((totalLoggedTimeSeconds / 60));

  const totalPauseDuration = moment.duration(totalPauseTimeMinutes, 'minutes');
  const totalLoggedDuration = moment.duration(totalLoggedTimeMinutes, 'minutes');

  return {
    totalPauseTime: {
      seconds: totalPauseTimeSeconds,
      minutes: totalPauseDuration.minutes(),
      hours: totalPauseDuration.hours(),
    },
    totalLoggedTime: {
      seconds: totalLoggedTimeSeconds,
      minutes: totalLoggedDuration.minutes(),
      hours: totalLoggedDuration.hours(),
    },
  };
};

export const formatWithTimezone = (
  time: ?string,
  timezone: string,
) => (moment.tz(time, timezone).isValid()
  ? `${moment.tz(time, timezone).format('h:mma')} `
  : '');

export const formatStartStopTime = (
  loggingStart: ?string,
  loggingStop: ?string,
  timezone: string,
) => {
  const formattedStart = moment.tz(loggingStart, timezone).isValid()
    ? `${moment.tz(loggingStart, timezone).format('h:mma')} `
    : '';
  const formattedStop = moment.tz(loggingStop, timezone).isValid()
    ? ` ${moment.tz(loggingStop, timezone).format('h:mma')}`
    : '';
  return `${formattedStart}-${formattedStop}`;
};

export const formatLogDuration = (time: moment | SecondsMinutesHours) => moment.duration(time.seconds, 'seconds').format('h[h] mm[m]');

export const renderTimePickerWarning = (
  startTime: moment,
  endTime: moment,
  warning: string,
) => {
  if (!!startTime && !!endTime) {
    const start = moment(startTime);
    const end = moment(endTime);
    const startMinutes = start.minutes() + start.hours() * 60;
    const endMinutes = end.minutes() + end.hours() * 60;
    const isAfter = endMinutes > startMinutes;
    if (!isAfter) {
      return warning;
    }
    return '';
  }
  return '';
};

export const isAfter = (startTime: string, endTime: string): boolean => {
  if (!!startTime && !!endTime) {
    const start = moment(startTime);
    const end = moment(endTime);
    const startMinutes = start.minutes() + start.hours() * 60;
    const endMinutes = end.minutes() + end.hours() * 60;
    return startMinutes > endMinutes;
  }
};

// Returns true for these inputs: 123, 123.1, 0.123, .123
export const isValidFloat = (input) => {
  return /^(?=[\S])\d*(\.\d+)?$/.test(input);
};

export const formatDatetime = (value: string, timezone: string, defaultString: string = '') => (
  moment.tz(value, timezone).isValid()
    ? `${moment.tz(value, timezone).format('ddd MMM D, YYYY, h:mma')} `
    : defaultString
);

export const configurableFieldTypeValues = {
  TEXT: 'TEXT',
  DATETIME: 'DATETIME',
  DROPDOWN: 'DROPDOWN',
};

export const formatConfigField = (cf, value, timezone) => {
  if (cf.type === configurableFieldTypeValues.DATETIME) {
    return formatDatetime(value, timezone);
  }
  return value;
};
