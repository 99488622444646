import React from 'react';

const Close = props => (
  <svg width={25} height={25} viewBox="0 0 25 25" {...props}>
    <title>Close</title>
    <path
      d="M12.513 11.01L19.2 4.324a1.062 1.062 0 0 1 1.503 1.503l-6.687 6.686 6.687 6.686a1.062 1.062 0 0 1-1.503 1.502l-6.686-6.685-6.686 6.685A1.062 1.062 0 0 1 4.324 19.2l6.687-6.686-6.687-6.686a1.062 1.062 0 1 1 1.503-1.503l6.686 6.686z"
      fill="#FF4646"
      fillRule="evenodd"
    />
  </svg>
);

export default Close;
