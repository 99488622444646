// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type TagProps = {
  text: string | Node,
  leftIcon?: Node,
  rightIcon?: Node,
  className?: string
};

const Tag = ({
  text, leftIcon, rightIcon, className,
}: TagProps) => (
  <div className={classNames(styles.tag, className)}>
    {!!leftIcon && <span className={styles.tagIcon}>{leftIcon}</span>}
    <span className={styles.tagText}>{text}</span>
    {!!rightIcon && <span className={styles.tagIcon}>{rightIcon}</span>}
  </div>
);

Tag.defaultProps = {
  leftIcon: null,
  rightIcon: null,
  className: styles.defaultTag,
};

export default Tag;
