import React from 'react';
import { CycleSite, BothSite } from '../Icon';
import { SiteTypes, getColourForSiteType } from './utils';

const SiteIcon = ({
  type,
  ...rest
}) => {
  const siteColour = getColourForSiteType(type);
  if (type === SiteTypes.SOURCE || type === SiteTypes.RECEIVING) {
    return (
      <CycleSite
        fill={siteColour}
        dropoff={type === SiteTypes.RECEIVING}
        {...rest}
      />
    );
  }
  return <BothSite {...rest} />;
};

export default SiteIcon;
