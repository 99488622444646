// @flow
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

const COMPANIES_QUERY = gql`
  query CompaniesQuery {
    companies {
      id
      name
    }
  }
`;

const useCompanies = () => {
  const { loading, error, data } = useQuery(COMPANIES_QUERY);
  return useMemo(() => {
    if (loading || error) return null;
    return data.companies;
  }, [loading, error, data]);
};

export default useCompanies;
