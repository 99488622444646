import React, { Component } from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";
import { connect } from 'formik';

class TextArea extends Component {
  static contextTypes = {
    formik: PropTypes.object
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    hint: PropTypes.string,
  };

  render() {
    const { name, formik, className, label, hint, dollar, ...props } = this.props;

    // form states
    const value = formik.values[name] || "";
    const touched = formik.touched[name];
    const error = formik.errors[name];

    // determine container class
    let containerClass = styles.inputTextArea;
    let shouldShowError = touched && !!error;
    if (shouldShowError) {
      containerClass = `${containerClass} ${
        !!error ? styles.error : styles.success
      }`;
    }

    // render textarea
    return (
      <div className={containerClass}>
        <div className={styles.labelRow}>
          {!!label && <span className={styles.textLabel}>{label}</span>}
          {!!hint && <span className={styles.textHint}>{hint}</span>}
        </div>
        <div className={styles.input}>
          <textarea
            name={name}
            value={value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...props}
          />
        </div>
        {shouldShowError && <div className={styles.errorMessage}>{error}</div>}
      </div>
    );
  }
}

export default connect(TextArea);
