import PropTypes from 'prop-types';

// Higher Order Components
import { withQuery } from 'utils/HOC';

import {
  compose, withProps, mapProps, withHandlers, withStateHandlers,
} from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { withRouter } from 'react-router-dom';

// Components - Tuna
import { LOGGING, ACCEPTED, PAUSED } from 'utils/constants/job';
import { ERROR } from 'Notification/NotificationTypes';
import parseGraphqlError from 'utils/Error';
import { SET_NOTIFICATION } from 'apollo';

import { jobIsInLoggingWindow } from 'Jobs/utils/getLatestJob';

import LogButtonUI from './LogButton.ui';

const LOG_BUTTON_QUERY = gql`
  query AssignmentDetailQuery($id: ID!){
    assignment(id: $id) {
      id
      startTime
      assignedTo
      assignee {
        id
        accountId
      }
      assignedAt
      assignedStatus
      sendStatus
      equipment
      equipmentInfo {
        id
        name
      }
      haulerRate
      haulerRateUnit
      phase
      product
      productInfo {
        id
        name
      }
      projectId
      project {
        id
        companyId
      }
      client
      notes
      pickUp
      dropOff
      assignmentInstances {
        id
        assignmentId
        loggingStart
        loggingStop
        companyName
      }
    }
  }
`;

const START_LOGGING_MUTATION = gql`
  mutation StartLoggingMutation($id: ID!) {
    startLogging(id: $id) {
      id
      loggingStart
    }
  }
`;

const PAUSE_LOGGING_MUTATION = gql`
mutation PauseLoggingMutation($id: ID!) {
  pauseLogging(id: $id) {
    id
  }
}
`;

const RESUME_LOGGING_MUTATION = gql`
mutation ResumeLoggingMutation($id: ID!) {
   resumeLogging(id: $id) {
     id
   }
 }
`;

const STOP_LOGGING_MUTATION = gql`
  mutation StopLoggingMutation($id: ID!, $companyID: ID) {
    stopLogging(id: $id, companyID: $companyID) {
      id
      loggingStop
    }
  }
`;

const FINISH_JOB_MUTATION = gql`
  mutation FinishJobMutation($input: FinishJobInput!) {
    finishJob(input: $input) {
      id
      attachments {
        id
        url
      }
      driverNotes
      status
      tickets {
        id
        quantity
        haulerRate
        haulerTotal
        unit
      }
    }
  }
`;

const JobLogButton = compose(
  withRouter,
  graphql(START_LOGGING_MUTATION, {
    name: 'startLogging',
    options: (props) => ({ variables: { id: props.jobId } }),
  }),
  graphql(PAUSE_LOGGING_MUTATION, {
    name: 'pauseLogging',
  }),
  graphql(RESUME_LOGGING_MUTATION, {
    name: 'resumeLogging',
  }),
  graphql(STOP_LOGGING_MUTATION, {
    name: 'stopLogging',
  }),
  graphql(FINISH_JOB_MUTATION, {
    name: 'finishJob',
  }),
  graphql(SET_NOTIFICATION, { name: 'notify' }),
  withStateHandlers(
    {
      endJobModalOpen: false,
    },
    {
      showEndJobModal: () => () => ({
        endJobModalOpen: true,
      }),
      dismissAllModals: () => () => ({
        endJobModalOpen: false,
      }),
    },
  ),
  withQuery(LOG_BUTTON_QUERY, {
    options: (props) => ({ variables: { id: props.focusedJobID } }),
  }),
  mapProps(({ data, ...props }) => ({
    data: {
      user: {
        jobs: [
          data.assignment,
        ],
      },
    },
    refetchQuery: data.refetch,
    ...props,
  })),
  withProps(
    ({
      data: { user }, focusedJobID, isCurrentlyLogging, prefTimeZone, assignmentStatus,
    }) => {
      let scheduledJob = null;
      let jobId = null;
      let jobStarted = false;
      let jobStartTime = null;
      let cantLogNotCurrentDay = false;
      let cantLogCurrentlyLogging = false;
      let instanceId = null;
      let companyID = null;

      // if focusedJobId is specified, overides natural activeJob
      const activeJobID = focusedJobID;
      // check if on or off
      if (!!user.jobs && user.jobs.length > 0) {
        const selectedJob = user.jobs[0];

        jobId = selectedJob.id;

        jobStartTime = selectedJob.startTime;

        const isAcceptedOrPaused = (selectedJob.sendStatus === ACCEPTED || selectedJob.sendStatus === PAUSED);

        jobStarted = selectedJob.sendStatus === LOGGING;

        cantLogCurrentlyLogging = isAcceptedOrPaused && !!isCurrentlyLogging && !jobStarted;

        cantLogNotCurrentDay = selectedJob.sendStatus === ACCEPTED && !jobIsInLoggingWindow(selectedJob);

        scheduledJob = selectedJob;

        instanceId = selectedJob.assignmentInstances
          && selectedJob.assignmentInstances[0]
          && selectedJob.assignmentInstances[0].id;

        companyID = selectedJob.project && selectedJob.project.companyId.toString();
      }

      return {
        cantLogNotCurrentDay,
        cantLogCurrentlyLogging,
        activeJobID,
        jobId,
        instanceId,
        jobStarted,
        jobStartTime,
        focusedJobID,
        scheduledJob,
        companies: [],
        prefTimeZone,
        assignmentStatus,
        companyID,
      };
    },
  ),
  withHandlers({
    handleButtonClick: ({
      jobStarted,
      jobId,
      instanceId,
      notify,
      scheduledJob,
      startLogging,
      showEndJobModal,
      dismissAllModals,
      refetchQuery,
    }) => (event) => {
      event.preventDefault();

      // if active job, stop logging
      if (jobStarted) {
        showEndJobModal();
        return Promise.resolve();
      }
      if (scheduledJob) {
        return startLogging({
          variables: { id: instanceId },
          refetchQueries: [{ query: LOG_BUTTON_QUERY, variables: { id: jobId } }],
        })
          .then(() => {
            dismissAllModals();
          })
          .catch((err) => {
            notify({
              variables: { type: ERROR, message: parseGraphqlError(err) },
            });
            refetchQuery();
          });
      }
    },
    cancelAction: ({ dismissAllModals }) => (event) => {
      event.preventDefault();
      dismissAllModals();
    },
    pauseJob: ({
      dismissAllModals,
      notify,
      instanceId,
      pauseLogging,
      jobId,
    }) => (event) => {
      event.preventDefault();
      dismissAllModals();

      return pauseLogging({
        variables: { id: instanceId },
        refetchQueries: [{ query: LOG_BUTTON_QUERY, variables: { id: jobId } }],
      })
        .catch((err) => {
          notify({
            variables: { type: ERROR, message: parseGraphqlError(err) },
          });
        });
    },
    resumeJob: ({
      dismissAllModals,
      notify,
      instanceId,
      resumeLogging,
      jobId,
    }) => (event) => {
      event.preventDefault();
      dismissAllModals();

      return resumeLogging({
        variables: { id: instanceId },
        refetchQueries: [{ query: LOG_BUTTON_QUERY, variables: { id: jobId } }],
      })
        .catch((err) => {
          notify({
            variables: { type: ERROR, message: parseGraphqlError(err) },
          });
        });
    },
    completeJob: ({
      dismissAllModals,
      notify,
      instanceId,
      stopLogging,
      jobId,
      companyID,
    }) => (event) => {
      event.preventDefault();
      dismissAllModals();

      return stopLogging({
        variables: { id: instanceId, companyID },
        refetchQueries: [{ query: LOG_BUTTON_QUERY, variables: { id: jobId } }],
      })
        .catch((err) => {
          notify({
            variables: { type: ERROR, message: parseGraphqlError(err) },
          });
        });
    },
  }),
)(LogButtonUI);

JobLogButton.propTypes = {
  focusedJobID: PropTypes.string.isRequired,
  isCurrentlyLogging: PropTypes.bool,
  prefTimeZone: PropTypes.string,
};

export default JobLogButton;
