// @flow
import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'common-ui/Button';
import { useI18n } from 'internationalization';
import PropTypes from 'prop-types';
import {
  CheckInCircle,
  RightArrow,
  Pen,
} from 'common-ui/Icon';
import styles from './styles.module.scss';
import ClockSVG from './ClockSVG';
import { SignedOffStatusMap } from '../utils';

type TimesheetsCardProps = {
  id: string,
  company: string,
  companyID: string,
  time: string,
  lastDropOff: string,
  signedOffStatus: string,
};

const ViewTimesheetAndStatus = ({ status, timesheetID, companyID }) => {
  const intlz = useI18n();

  switch (status) {
    case SignedOffStatusMap.SignedOff:
      return (
        <div className={styles.viewTimesheetSignedOff}>
          <Button
            disabled
            text={intlz('Signed Off')}
            icon={<CheckInCircle />}
            className={styles.check}
            onClick={() => {
              console.log(`${status}`);
            }}
          />
          <Link key={timesheetID} to={`/timesheets/${companyID}/${timesheetID}`}>
            <Button
              rightIcon
              text={intlz('View Timesheet')}
              icon={<RightArrow />}
              className={styles.button}
            />
          </Link>
        </div>
      );

    case SignedOffStatusMap.SignOffRequested:
      return (
        <div className={styles.viewTimesheetSignOffRequested}>
          <Button
            disabled
            text={intlz('Sign Off has been requested')}
            icon={<Pen />}
            className={styles.pen}
            onClick={() => {
              console.log(`${status}`);
            }}
          />
          <Link key={timesheetID} to={`/timesheets/${companyID}/${timesheetID}`}>
            <Button
              rightIcon
              text={intlz('View Timesheet')}
              icon={<RightArrow />}
              className={styles.button}
              fullWidth
            />
          </Link>
        </div>
      );

    default:
      return (
        <Link key={timesheetID} to={`/timesheets/${companyID}/${timesheetID}`}>
          <Button
            text={intlz('View Timesheet')}
            className={styles.viewTimesheetBlackButton}
            fullWidth
          />
        </Link>
      );
  }
};

ViewTimesheetAndStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

const TimesheetsCard = ({
  id,
  company,
  companyID,
  time,
  lastDropOff,
  signedOffStatus,
}: TimesheetsCardProps) => {
  const intlz = useI18n();

  const lastDropOffText = `${intlz('Last Drop Off')} : ${lastDropOff || 'N/A'}`;
  return (
    <div className={styles.timesheetCardContainer}>
      <div className={styles.companyText}>{company}</div>
      <div className={styles.clock}>
        <ClockSVG />
      </div>
      <div className={styles.timeText}>{time}</div>
      <div className={styles.dropOffText}>
        {lastDropOffText}
      </div>
      <ViewTimesheetAndStatus status={signedOffStatus} timesheetID={id} companyID={companyID} />
    </div>
  );
};

export default TimesheetsCard;
