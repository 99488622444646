import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useTimezone } from 'common-ui/Hooks';
import AssignmentTag from 'components/Common/AssignmentTag';
import { Text } from 'OldCommon/Text';
import { TimeTag } from 'components/Common/JobsHeader';
import { PENDING } from 'utils/constants/job';
import { getPickUpSite, getPickUpAddress } from 'utils/sites';
import { useI18n } from 'internationalization';
import styles from './styles.module.scss';

const AssignmentInfoCard = ({ assignment, isFeatured }) => {
  const isSelfLog = !assignment.client;
  const intlz = useI18n();
  const timezone = useTimezone();

  const assignments = assignment.schedule && assignment.schedule.assignments;
  const start = assignments && moment.tz(assignments[0].pickupArrival, timezone);
  const end = assignments && moment.tz(assignments[assignments.length - 1].dropoffDeparture, timezone);
  const hours = assignments && Math.ceil(moment.duration(end.diff(start)).as('hours'));
  const isPendingSchedule = assignment.sendStatus === PENDING && assignment.schedule;
  const linkId = isPendingSchedule ? assignments[0].id : assignment.id;

  const pickUpSite = getPickUpSite(assignment, assignment.project);
  const pickUpAddress = getPickUpAddress(assignment, assignment.project);
  const pickUpText = pickUpSite ? pickUpSite.name : pickUpAddress;

  return (
    <Link to={`/jobs/${linkId || ''}`}>
      <div className={styles.assignmentTags}>
        <TimeTag
          startTime={assignment.startTime}
          showAsDate={assignment.sendStatus === PENDING}
        />
        <AssignmentTag
          schedule={assignment.schedule}
          sendStatus={assignment.sendStatus}
          isFeatured={isFeatured}
          assignedStatus={assignment.assignedStatus}
        />
      </div>
      <Text bold>{isSelfLog || isPendingSchedule ? assignment.companyName : assignment.project.name}</Text>
      {isPendingSchedule && <Text>{`${start.format('h:mma')} - ${end.format('h:mma')} (${hours} hrs)`}</Text>}
      <Text>{isSelfLog ? intlz('Created by You') : pickUpText}</Text>
    </Link>
  );
};

export default AssignmentInfoCard;
