import { useCompanyTicketQuantityDefaults } from 'components/Tickets/gql';

const readableRateUnits = {
  LOAD: 'Load',
  TONNE: 'Tonne',
  TON: 'Ton',
  YARD: 'Yard',
  METER: 'Meter',
  FOOT: 'Foot',
  LB: 'Pound',
  KG: 'Kilogram',
  LITER: 'Liter',
  HOUR: 'Hour',
  WAIT: 'Wait Hour',
  BUSHEL: 'Bushel',
  GALLON: 'Gallon',
  CUBICMETER: 'Cubic Meter',
};

export function formatPayUnit(unit) {
  return readableRateUnits[unit] || unit;
}

const assignmentQuantityUnits = {
  LOAD: 'LOAD',
  TONNE: 'TONNE',
  TON: 'TON',
  YARD: 'YARD',
  METER: 'METER',
  FOOT: 'FOOT',
  LITER: 'LITER',
  BUSHEL: 'BUSHEL',
  GALLON: 'GALLON',
  CUBICMETER: 'CUBICMETER',
};
export function formatAssignmentQuantityIntlz({ quantity, quantityUnit }, intlz) {
  let ret = '-';
  if (quantity > 0 && quantityUnit === assignmentQuantityUnits.LOAD) {
    ret = `${quantity} ${intlz('Loads')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.TONNE) {
    ret = `${quantity} ${intlz('Tonnes')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.TON) {
    ret = `${quantity} ${intlz('Tons')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.YARD) {
    ret = `${quantity} ${intlz('Yards')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.METER) {
    ret = `${quantity} ${intlz('Meters')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.FOOT) {
    ret = `${quantity} ${intlz('Feet')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.LITER) {
    ret = `${quantity} ${intlz('Liters')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.BUSHEL) {
    ret = `${quantity} ${intlz('Bushels')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.GALLON) {
    ret = `${quantity} ${intlz('Gallons')}`;
  } else if (quantity > 0 && quantityUnit === assignmentQuantityUnits.CUBICMETER) {
    ret = `${quantity} ${intlz('Cubic Meters')}`;
  }
  return ret;
};

export const rateUnitOptions = Object.keys(readableRateUnits).map(unit => ({
  label: readableRateUnits[unit],
  value: unit,
}));

const useDefaultQuantityPerRateUnitMap = (ticket, assignment) => {
  const companyId = (assignment && assignment.companyId) || 0;
  const assignmentId = (assignment && assignment.id) || 0;

  const rateUnit = (ticket && ticket.haulerRateUnit) || (assignment && assignment.haulerRateUnit) || '';

  const defaultQuantityPerUnitRate = useCompanyTicketQuantityDefaults(
    companyId,
    assignmentId,
    (
      !companyId
      || !assignmentId
    ),
  );

  const defaultQuantityPerRateUnitMap = Object
    .keys(readableRateUnits)
    .reduce((map, _rateUnit) => {
      const _defaultQuantity = _rateUnit === rateUnit
        ? (
          defaultQuantityPerUnitRate[rateUnit]
          && defaultQuantityPerUnitRate[rateUnit].toString()
        ) : '';
      map[_rateUnit] = _defaultQuantity;
      return map;
    }, {});

  return defaultQuantityPerRateUnitMap;
};

export const useGetRateAndQuantity = (ticket, assignment) => {
  const defaultQuantityPerRateUnitMap = useDefaultQuantityPerRateUnitMap(ticket, assignment);

  const rateUnit = (ticket && ticket.haulerRateUnit) || (assignment && assignment.haulerRateUnit) || null;
  const quantity = (ticket && ticket.quantity) || defaultQuantityPerRateUnitMap[rateUnit] || '';
  return [rateUnit, quantity, defaultQuantityPerRateUnitMap];
};
