// @flow
import React, { forwardRef } from 'react';

type ClickableDivProps = {
  onClick: (Event) => void,
  disabled?: boolean,
  id?: undefined
};

const ClickableDiv = forwardRef(({
  onClick,
  disabled,
  ...restProps
}: ClickableDivProps, ref) => (
  <div
    ref={ref}
    onClick={(...args) => { if (!disabled) { onClick(...args); } }}
    onKeyPress={(...args) => { if (!disabled) { onClick(...args); } }}
    role="button"
    tabIndex={0}
    {...restProps}
  />
));

ClickableDiv.defaultProps = {
  disabled: false,
};

export default ClickableDiv;
