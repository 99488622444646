// @flow
import { useMemo, useState } from 'react';
import { useQuery, gql } from '@apollo/client';

export const TIMESHEET_QUERY = gql`
  query TimesheetQuery($companyID: ID!, $timesheetID: ID!) {
    timesheet(
      companyID: $companyID
      timesheetID: $timesheetID
    ) {
      id
      canEditTimesheet
      invoiceTotal
      showInvoiceTotal
      haulerTotal
      showHaulerTotal
      date
      adjustments {
        id
        minutes
        comments
        type
        timing
        assignmentInstanceID
      }
      configurableFields {
        id
        value
      }
      adjustmentCalculation {
        totalPauseTime
        totalTime
        totalAdjustmentTime
        startTimeAdjusted
        endTimeAdjusted
      }
      timesheetAttachment {
        id
        uploadData {
          getURL
          filename
        }
      }
      signoff {
        id
        signoffName
        signoffPersona
        signoffDate
        signoffCompanyID
        deleted
      }
      assignmentInstances {
        driverTicketFields
        id
        status
        gpsData {
          id
          latitude
          longitude
          bearing
          idleSince
          lastPing
          isIdle
          isDisconnected
        }
        assignee {
          id
          firstName
          lastName
          phoneNumber
        }
        loggingStart
        loggingStop
        truckNumber
        trailerNumber
        tickets {
          id
          canEdit
          ticketNumber
          quantity
          invoiceRate
          configurableFields {
            id
            value
          }
          invoiceRateUnit
          invoiceTotal
          haulerRate
          haulerRateUnit
          haulerTotal
          product
          phase
          pickUp
          dropOff
          pickUpSite {
            id
            address
            name
          }
          dropOffSite {
            id
            address
            name
          }
          jobNumber
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          signoffs {
            id
            signoffName
            signoffPersona
            signoffDate
            deleted
          }
          startTime
          endTime
          movedBy {
            id
            firstName
            lastName
          }
          movedAt
          tare
          gross
          pickUpArrival
          dropOffDeparture
          data {
            filename
            getURL
            thumbnailURL
            mimeType
          }
          attachments {
            id
            uploadData {
              filename
              getURL
              thumbnailURL
              mimeType
            }
          }
          deleted
          notes
          data {
            filename
            getURL
            thumbnailURL
            mimeType
          }
          rotation
          scaleSource
        }
        assignment {
          hasTimesheet
          id
          quantity
          quantityUnit
          notes
          equipment
          phase
          startTime
          product
          sendStatus
          invoiceRate
          invoiceRateUnit
          haulerRate
          haulerRateUnit
          pickUp
          sendStatus
          assignedStatus
          activity {
            ... on AssignmentChangelog {
              id
              from
              to
              fieldName
              timestamp
            }
          }
          configurableFields {
            id
            value
          }
          pickUpSite {
            id
            name
            address
            type
            coordinates {
              latitude
              longitude
            }
          }
          dropOff
          dropOffSite {
            id
            name
            address
            type
            coordinates {
              latitude
              longitude
            }
          }
          subcontractor {
            id
            name
          }
          project {
            parentId
            signOffRequired
            subcontractingCompanyName
            id
            parentId
            quantity
            quantityUnit
            type
            notes
            name
            companyName
            companyId
            retroactive
            configurableFields {
              id
              value
            }
            collaborators {
              accountId
              roles
            }
            foremanInfo {
              id
              firstName
              lastName
            }
            acceptedBy {
              id
              firstName
              lastName
            }
            createdBy {
              id
              firstName
              lastName
            }
            client
            selfLog
            jobNumber
            foreman
            pickUp
            attachments {
              id
              uploadData {
                filename
                getURL
                thumbnailURL
                mimeType
              }
            }
            pickUpSite {
              id
              name
              address
              type
              coordinates {
                latitude
                longitude
              }
            }
            dropOff
            dropOffSite {
              id
              name
              address
              type
              coordinates {
                latitude
                longitude
              }
            }
            target {
              id
              targetName
              tags
            }
            productInfo {
              id
              name
            }
          }
          invoiceRateUnit
        }
        loadCount
      }
      signoffStatus
      signoffStatusUpdatedAt
      timesheetNumber
    }
  }
`;

export const useTimesheet = (timesheetID: ?string, timesheetCompanyID: ?string, fromNetwork?: boolean) => {
  const { loading, error, data } = useQuery(TIMESHEET_QUERY, {
    variables: {
      timesheetID,
      companyID: timesheetCompanyID,
    },
    skip:
      !timesheetID
      || !timesheetCompanyID
      || timesheetCompanyID === '0',
    returnPartialData: true,
    fetchPolicy: fromNetwork && 'network-only',
  });

  return useMemo(() => {
    const meta = { loading, error };
    if (loading || error || !data) return [null, meta];
    return [data.timesheet, meta];
  }, [loading, error, data]);
};

export default useTimesheet;
