export const getPickUpSite = (assignment, project) => (
  assignment.pickUpSite || (assignment.pickUp === project.pickUp && project.pickUpSite)
);

export const getReadableSiteAddress = (site) => {
  if (site && site.address) {
    return `${site.address}`;
  }
  if (site && site.coordinates) {
    return `${site.coordinates[0].latitude}, ${site.coordinates[0].longitude} `;
  }
  return '';
};

export const getPickUpAddress = (assignment, project) => {
  const pickUpSite = getPickUpSite(assignment, project);
  return getReadableSiteAddress(pickUpSite) || assignment.pickUp || project.pickUp;
};

// We can assume assignment dropOffSite is the only possible site, since project
// drop-off sites were migrates to assignments in the 2021-10-27 release.
export const getDropOffAddress = (assignment, project) => {
  return getReadableSiteAddress(assignment.dropOffSite) || assignment.dropOff || project.dropOff;
};
