import React from 'react';
import ReactDOM from 'react-dom';

// Higher Order Components
import { BrowserRouter, withRouter } from 'react-router-dom';
import { compose, shouldUpdate } from 'recompose';

// Components
import App from 'App';
import { RouterAwareApolloProvider } from 'apollo';
import 'styles.scss';

// Third Party
import Raven from 'raven-js';

// Style
import 'common-ui/base.scss';

// Set Up Raven (Error Reporting)
if (process.env.REACT_APP_SENTRY_DSN) {
  const config = { environment: process.env.REACT_APP_ENV };
  Raven.config(process.env.REACT_APP_SENTRY_DSN, config).install();
}

// set up component that implements router should update logic
const ChildRenderer = props => props.children;
const RouterPureComponent = compose(
  withRouter,
  shouldUpdate(
    (prev, next) => prev.location.pathname !== next.location.pathname
      || prev.location.search !== next.location.search,
  ),
)(ChildRenderer);

ReactDOM.render(
  <BrowserRouter>
    <RouterAwareApolloProvider>
      <RouterPureComponent>
        <App />
      </RouterPureComponent>
    </RouterAwareApolloProvider>
  </BrowserRouter>,
  document.getElementById('app'),
);
