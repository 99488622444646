import React from 'react';
import classNames from 'classnames';
import AssignmentInfoCard from 'components/Common/AssignmentInfoCard';
import {
  LOGGING, PENDING, ACCEPTED, PAUSED,
} from 'utils/constants/job';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import ClickableDiv from 'common-ui/ClickableDiv';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import ID from 'common-ui/ConsistentIDs';

const trackAssignmentClick = (sendStatus, startTime) => {
  if (sendStatus === PENDING) {
    GA.trackClick(GAActions.requestOpen);
  } else if (sendStatus === ACCEPTED && moment(startTime).isSame(moment(), 'day')) {
    GA.trackClick(GAActions.jobNew);
  } else if (sendStatus === ACCEPTED && moment(startTime).isAfter(moment(), 'day')) {
    GA.trackClick(GAActions.jobFuture);
  } else if (sendStatus === LOGGING) {
    GA.trackClick(GAActions.jobLogging);
  } else if (sendStatus === PAUSED) {
    GA.trackClick(GAActions.jobPaused);
  }
};

const AssignmentCard = ({ assignment, isFeatured }) => (
  <div
    className={classNames(styles.assignmentCard, {
      [styles.logging]: assignment.sendStatus === LOGGING,
      [styles.request]: assignment.sendStatus === PENDING,
      [styles.next]: assignment.sendStatus === ACCEPTED && isFeatured,
      [styles.scheduleGroupShadow]: assignment.sendStatus === PENDING && assignment.schedule,
    })}
  >
    {assignment.sendStatus === PENDING && <div className={styles.requestIndicator} />}
    <ClickableDiv
      id={ID.create(
        ID.Areas.Jobs,
        ID.Types.Button,
        ID.Labels.JobCard,
      )}
      className={styles.assignmentCardInfo}
      onClick={() => trackAssignmentClick(assignment.sendStatus, assignment.startTime)}
    >
      <AssignmentInfoCard assignment={assignment} isFeatured={isFeatured} />
    </ClickableDiv>
  </div>
);

AssignmentCard.propTypes = {
  assignment: PropTypes.shape({
    sendStatus: PropTypes.string,
    startTime: PropTypes.string,
  }).isRequired,
  isFeatured: PropTypes.bool,
};

export default AssignmentCard;
