import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

// Components
import TimesheetsOverview from './TimesheetsOverview';
import TimesheetDetail from './TimesheetDetail/TimesheetDetail';
import Tickets from './TimesheetDetail/Tickets/Tickets';
import AddEditTicketForm from '../Tickets/AddEditTicketForm';

const Timesheets = () => (
  <Switch>
    <Route exact path="/timesheets" component={TimesheetsOverview} />
    <Route exact path="/timesheets/:companyId/:id/" component={TimesheetDetail} />
    <Route exact path="/timesheets/:companyId/:id/tickets/:assignmentInstanceId" component={Tickets} />
    <Route exact path="/timesheets/:companyId/:id/tickets/:assignmentId/:assignmentInstanceId/:ticketId" component={AddEditTicketForm} />
    <Route component={TimesheetsOverview} />
  </Switch>
);

export default Timesheets;
