import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const EmptyState = ({ active, icon, heading, subheading, action }) => (
  <div className={active ? styles.emptyStateActive : styles.emptyStateInactive}>
    <div className={styles.emptyIcon}>{icon}</div>
    <div className={styles.emptyBigText}>{heading}</div>
    <div className={styles.emptySmallText}>{subheading}</div>
    <div className={styles.emptyButton}>{action}</div>
  </div>
);

EmptyState.defaultProps = {
  active: false
};

EmptyState.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.node,
  heading: PropTypes.node,
  subheading: PropTypes.node,
  action: PropTypes.node
};

export default EmptyState;
