// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type ContextSwitcherItemProps = {
  label: string,
  isActive: boolean,
  onClick?: () => void,
  icon?: Node,
  isOperatorStyle?: boolean,
}

const ContextSwitcherItem = ({
  label, isActive, onClick, icon, isOperatorStyle
}: ContextSwitcherItemProps) => (
  <button
    id={label}
    className={classNames(styles.unselectedItem, {
      [styles.selectedItem]: isActive,
      [styles.operatorStyle]: isOperatorStyle,
    })}
    type="button"
    onClick={onClick}
  >
    {icon && (
      <div className={classNames({
        [styles.buttonIconOperatorStyle]: isOperatorStyle
      })}>
        {icon}
      </div>
    )}
    <span className={styles.buttonLabel}>{label}</span>
  </button>
);

ContextSwitcherItem.defaultProps = {
  onClick: () => { },
  icon: null,
  isOperatorStyle: false,
};

export default ContextSwitcherItem;
