// @flow
import React from 'react';
import { useI18n } from 'internationalization';
import JobInfoDetails from './JobInfoDetails';
import { useGetAssignment } from 'components/TabBar/gql';
import styles from './styles.module.scss';

const SignOffPageJobInfoDetails = ({ assignmentId }: { assignmentId: string}) => {
  const intlz = useI18n();
  const [assignment] = useGetAssignment(assignmentId);

  if (!assignment) {
    return null;
  }

  const { project } = assignment;

  return (
    <div className={styles.jobInfoSection}>
      <span className={styles.jobInfoSectionLabel}>{intlz('Job Details')}</span>
      <JobInfoDetails assignment={assignment} project={project} hideDirections />
    </div>
  )
};

export default SignOffPageJobInfoDetails;
