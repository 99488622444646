import React from 'react';

//NOTE: To convert from SVG, you must rename any 'xlink:href' to 'xlinkHref' and 'xmlns:xlink' to 'xmlnsXlink'
//https://github.com/facebook/react/issues/4908
const Place = props => (
<svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Mask</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M9.99531342,9.90093086 C9.99841368,9.93259077 10,9.96464932 10,9.99703014 L10,16.0029699 C10,16.5536144 9.55613518,17 9,17 C8.44771525,17 8,16.5469637 8,16.0029699 L8,9.99703014 C8,9.96460301 8.00153931,9.93253744 8.0045502,9.90090332 C5.72004324,9.43939285 4,7.42056524 4,5 C4,2.23857625 6.23857625,0 9,0 C11.7614237,0 14,2.23857625 14,5 C14,7.42061341 12.2798883,9.4394732 9.99531342,9.90093086 Z M12,5 C12,3.34314575 10.6568542,2 9,2 C7.34314575,2 6,3.34314575 6,5 C6,6.65685425 7.34314575,8 9,8 C10.6568542,8 12,6.65685425 12,5 Z M0,16.5 C0,14.7128284 2.37621526,13.6318002 5.87663949,13.1893599 C6.42456476,13.1201043 6.92488876,13.5081427 6.99414442,14.0560679 C7.06340008,14.6039932 6.67536169,15.1043172 6.12743642,15.1735728 C4.76238001,15.3461108 3.60266506,15.6382349 2.81527608,15.9964472 C2.46857306,16.1541753 2.81527608,15.9964472 2.81527608,15.9964472 C2.37197933,16.2460103 2.3773583,16.7264658 2.84285335,17.0058255 C2.84285335,17.0058255 2.53542339,16.9676628 3.64142074,17.313287 C5.02828462,17.7466819 6.94480036,18 9,18 C11.0551996,18 12.9717154,17.7466819 14.3585793,17.313287 C15.4645766,16.9676628 15.1385792,17.0144495 15.1385792,17.0144495 C15.6313926,16.7548101 15.6380663,16.2759547 15.1915984,15.9995808 C15.1915984,15.9995808 15.5357898,16.1567752 15.1915984,15.9995808 C14.4094128,15.6423517 13.2566079,15.3503585 11.8984919,15.1768675 C11.3506589,15.1068852 10.9632844,14.606047 11.0332667,14.0582141 C11.103249,13.5103812 11.6040872,13.1230067 12.1519202,13.192989 C15.6371296,13.6382031 18,14.717341 18,16.5 C18,18.7463831 13.9884259,20 9,20 C4.0115741,20 0,18.7463831 0,16.5 Z" id="path-1"></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/Place" transform="translate(-3.000000, -2.000000)">
            <g id="Colors/Blue-Copy-16" transform="translate(3.000000, 2.000000)">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use id="Mask" fill="#106FEE" xlinkHref="#path-1"></use>
            </g>
        </g>
    </g>
</svg>
);

export default Place;
