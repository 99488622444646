// @flow
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';

export const ASSIGNMENT_DETAIL_QUERY = gql`
  query AssignmentInstance ($instanceId: ID!) {
    projectForInstance(instanceId: $instanceId) {
      id
      client
      type
      name
      companyId
      foreman
      foremanID
      jobNumber
      pickUp
      pickUpSite {
        id
        name
        address
        notes
        type
        coordinates {
          latitude
          longitude
        }
      }
      dropOff
      dropOffSite {
        id
        name
        address
        notes
        type
        coordinates {
          latitude
          longitude
        }
      }
      companyName
      subcontractingCompanyName
      attachments {
        uploadData {
          getURL
          mimeType
          filename
        }
      }
      signOffRequired
      assignments {
        hasTimesheet
        id
        activity {
          ... on AssignmentChangelog {
            id
            from
            to
            fieldName
            timestamp
          }
        }
        client
        companyId
        startTime
        sendStatus
        assignedStatus
        equipment
        equipmentInfo {
          id
          name
        }
        haulerRate
        haulerRateUnit
        quantity
        quantityUnit
        hideRateFromAssignee
        phase
        product
        productInfo {
          id
          name
        }
        notes
        pickUp
        pickUpSite {
          id
          name
          address
          notes
          coordinates {
            latitude
            longitude
          }
        }
        dropOff
        dropOffSite {
          id
          name
          address
          notes
          coordinates {
            latitude
            longitude
          }
        }
        assignmentInstances {
          driverTicketFields
          id
          loggingStart
          loggingStop
          endedBy {
            firstName
            lastName
          }
          info
          tickets {
            id
            canEdit
            data {
              getURL
              thumbnailURL
              mimeType
              filename
            }
            ticketNumber
            quantity
            haulerRate
            haulerRateUnit
            gross
            tare
            notes
            haulerTotal
            rotation
            deleted
            scaleSource
            signoffsRequired
            startTime
            endTime
            signoffs {
              id
              signoffName
              signoffDate
              deleted
            }
            attachments {
              id
              uploadData {
                filename
                getURL
                thumbnailURL
                mimeType
              }
            }
            pickUpArrival
            dropOffDeparture
            pickUp
            dropOff
            dropOffSite {
              id
              name
            }
            jobNumber
            phase
            product
            configurableFields {
              id
              value
            }
          }
          gpsData {
            id
            latitude
            longitude
          }
          status
        }
      }

    }
  }
`;

export const useGetReceipt = (instanceId: string) => {
  const {
    loading, error, data, refetch,
  } = useQuery(ASSIGNMENT_DETAIL_QUERY, {
    variables: {
      instanceId,
    },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => {
    if (loading) return [true, false, () => { }, [null, null, null]];
    if (error || !refetch) return [false, true, () => { }, [null, null, null]];

    const project = { ...data.projectForInstance };
    let assignment = project.assignments.length ? { ...project.assignments[0] } : null;
    if (assignment) {
      // the code below adds in values for project that are required in ticket receipts
      assignment = {
        ...assignment,
        project: {
          companyId: project.companyId,
          ...assignment.project,
          dropOff: project.dropOff,
          dropOffSite: { ...project.dropOffSite },
          jobNumber: project.jobNumber,
        },
      };
    }
    let instance = assignment
      && assignment.assignmentInstances
      && assignment.assignmentInstances.length
      ? { ...assignment.assignmentInstances[0] } : null;
    if (instance) {
      instance = {
        ...instance,
        tickets: instance.tickets
          .filter((t) => !t.deleted)
          .map((t) => ({
            ...t,
            signoffs: t.signoffs.filter((so) => !so.deleted),
          })),
      };
    }

    return [
      false,
      false,
      refetch,
      [project, assignment, instance],
    ];
  }, [loading, error, data, refetch]);
};

export default useGetReceipt;
