// @flow
import React, { useState } from 'react';
import { SmallButton } from 'common-ui/Button';
import { Attach } from 'common-ui/Icon';
import type { Ticket } from 'common-ui/types';
import { UploadWrapper } from 'common-ui/Upload';
import { useI18n } from 'internationalization';
import { ERROR } from 'components/Notification/NotificationTypes';
import parseGraphqlError, { useNotify } from 'utils/Error';
import { useFormikContext } from 'formik';
import { AttachmentThumbnail } from 'components/Tickets/AttachmentThumbnail';
import styles from './styles.module.scss';
import ActionSheetModal from '../Common/ActionSheetModal';

type AttachmentUploadProps = {
  ticket: Ticket,
  instanceId: string,
  setSubmitting: (boolean) => void,
  onUpload?: (Ticket) => void,
  readOnly: boolean,
  canDownload?: boolean,
};

const AttachmentUpload = ({
  ticket,
  instanceId,
  setSubmitting,
  onUpload,
  readOnly,
  canDownload,
}: AttachmentUploadProps) => {
  const intlz = useI18n();
  const notify = useNotify();
  const formik = useFormikContext();

  // user clicks on delete and gets confirmation
  // if inside confirmation triggers then we actually remove it
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const requestTypes = { pendingAttachment: 0, savedAttachment: 1 };
  const [typeRemovalRequest, setTypeRemovalRequest] = useState(requestTypes.savedAttachment);
  const [attachmentIDToRemove, setAttachmentIDToRemove] = useState(-1);


  const removePendingAttachment = (index) => {
    formik.setFieldValue('pendingAttachments', formik.values.pendingAttachments.filter((a, i) => i !== index));
  };
  const removeSavedAttachment = (id) => {
    formik.setFieldValue('deletedAttachmentIDs', [...formik.values.deletedAttachmentIDs, parseInt(id, 10)]);
  };

  const savedAttachments = (ticket && ticket.attachments)
    ? ticket.attachments
      .filter(({ id }) => !formik.values.deletedAttachmentIDs.includes(parseInt(id, 10)))
    : [];

  const canRemove = !readOnly;
  const canUpload = !readOnly;

  return (
    <div className={styles.attachmentUpload}>
      <ActionSheetModal
        visible={showDeleteConfirmModal}
        onClose={() => { setShowDeleteConfirmModal(false); }}
        onComplete={() => {
          if (typeRemovalRequest === requestTypes.savedAttachment) {
            removeSavedAttachment(attachmentIDToRemove);
          } else {
            removePendingAttachment(attachmentIDToRemove);
          }
        }}
        confirmLabel={intlz('Remove Attachment')}
        cancelLabel={intlz('Cancel')}
      />
      <UploadWrapper
        input={{ id: instanceId, domain: 'INSTANCE' }}
        shouldRunOCR={false}
        onUpload={onUpload}
        onError={(err) => notify({ variables: { type: ERROR, message: parseGraphqlError(err) } })}
        setUploading={setSubmitting}
      >
        {(openPicker) => (
          <>
            {canUpload && (
            <SmallButton
              id={null}
              text="Add an attachment"
              icon={<Attach />}
              className={styles.addAttachmentButton}
              onClick={openPicker}
            />
            )}
            <div className={styles.attachmentsList}>
              {savedAttachments.map((a, index) => (
                <AttachmentThumbnail
                  index={index}
                  id={a.id}
                  src={a.uploadData.getURL}
                  filename={a.uploadData.filename}
                  key={a.uploadData.getURL}
                  type={a.uploadData.mimeType}
                  removeAttachment={() => {
                    setShowDeleteConfirmModal(true);
                    setTypeRemovalRequest(requestTypes.savedAttachment);
                    setAttachmentIDToRemove(a.id);
                  }}
                  canRemove={canRemove}
                  canDownload={canDownload}
                />
              ))}
              {formik.values.pendingAttachments.map((a, index) => (
                <AttachmentThumbnail
                  index={index}
                  id={null}
                  src={a.getURL}
                  filename={a.filename}
                  key={a.getURL}
                  type={a.mimeType}
                  removeAttachment={() => {
                    setShowDeleteConfirmModal(true);
                    setTypeRemovalRequest(requestTypes.pendingAttachment);
                    setAttachmentIDToRemove(index);
                  }}
                  canRemove={canRemove}
                  canDownload={canDownload}
                />
              ))}
            </div>
          </>
        )}
      </UploadWrapper>
    </div>
  );
};

AttachmentUpload.defaultProps = {
  onUpload: () => {},
  canDownload: false,
};

export default AttachmentUpload;
