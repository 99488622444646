import React from 'react';

type ExportPropTypes = {
  width?: string,
  height?: string,
  viewBox?: string,
}

const Export = ({
  width,
  height,
  viewBox,
  ...props
}: ExportPropTypes) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...props}>
    <path d="M12.827 14.127h-9.6A1.228 1.228 0 012 12.9V3.3c0-.673.553-1.227 1.227-1.227h3.946c.334 0 .6.267.6.6 0 .334-.266.6-.6.6H3.227L3.2 12.9l9.627.027.026-4.167c0-.333.267-.6.6-.6.334 0 .6.267.6.6v4.14c0 .68-.546 1.227-1.226 1.227z" fill="#fff" />
    <path d="M14.22 1.373c-.006 0-.006 0 0 0l-4.14-.04h-.006a.6.6 0 00-.007 1.2l2.713.027-6.586 6.587a.6.6 0 000 .846c.12.12.273.174.426.174A.6.6 0 007.04 10l6.574-6.573v2.7c0 .333.266.6.6.6.333 0 .6-.267.6-.6V1.38l-.594-.007z" fill="#fff" />
  </svg>
);

Export.defaultProps = {
  width: '16px',
  height: '16px',
  viewBox: undefined,
};
export default Export;
