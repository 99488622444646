import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Line, Play, Stop, Add,
} from 'common-ui/Icon';
import { Button } from 'OldCommon/Button';
import { LOGGING, PENDING, ACCEPTED } from 'utils/constants/job';
import TicketsPageDisplay, { useTicketsPageDisplay } from 'Tickets/TicketsPageDisplay';
import { useFeaturedAssignmentId } from 'Jobs/graphql';
import StopLoggingModal from 'Jobs/subcomponents/StopLoggingModal';
import AcceptJobSection from 'Jobs/subcomponents/AcceptJobSection';
import { useI18n } from 'internationalization';
import ID from 'common-ui/ConsistentIDs';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import {
  useStartLogging, useStopLogging, usePauseLogging, useGetAssignment,
} from './gql';
import { jobIsInLoggingWindow } from 'Jobs/utils/getLatestJob';
import styles from './styles.module.scss';

const FeaturedAssignment = ({ assignment }) => {
  const intlz = useI18n();
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();

  const instanceId = assignment.assignmentInstances[0].id;
  const signoffEnabled = (!!assignment.project.signOffRequired || false);
  const isLogging = assignment.sendStatus === LOGGING;

  const [modalVisible, setModalVisible] = useState(false);

  const startLogging = useStartLogging();
  const stopLogging = useStopLogging();
  const pauseLogging = usePauseLogging();

  const [openPageDisplay, pageDisplayProps] = useTicketsPageDisplay();

  const onComplete = () => {
    setModalVisible(false);
    return stopLogging(assignment);
  };

  const onPause = () => {
    setModalVisible(false);
    return pauseLogging(assignment);
  };

  const onAddNewTicket = () => {
    openPageDisplay(TicketsPageDisplay.Steps.newTicket);
    GA.trackClick(GAActions.newTicket, 'New Ticket');
    setModalVisible(false);
  };

  const allowStartStop = isLogging || jobIsInLoggingWindow(assignment);

  return (
    <div className={styles.featuredAssignment}>
      {isLogging && (
        <div className={styles.logButton}>
          <Button
            fullWidth
            newTicket={isLogging}
            leftIcon={<Add />}
            label={intlz('New Ticket')}
            onClick={onAddNewTicket}
            id={ID.create(ID.Areas.Jobs, ID.Types.Button, ID.Labels.NewTicket)}
          />
        </div>
      )}
      <div className={styles.logButton}>
        <Button
          fullWidth
          disabled={disabled || modalVisible || !allowStartStop}
          primary={!isLogging}
          stopLog={isLogging}
          leftIcon={isLogging ? <Stop /> : <Play />}
          label={isLogging ? intlz('Stop Job') : intlz('Start Next Job')}
          onClick={(isLogging
            ? () => {
              setModalVisible(true);
              GA.trackClick(GAActions.stopLogging, 'Stop Job');
            } : () => {
              setDisabled(true);
              startLogging(assignment)
                .finally(() => setDisabled(false));
              GA.trackClick(GAActions.startLogging, 'Start Next Job');
            }
          )}
          id={ID.create(ID.Areas.Jobs, ID.Types.Button, ID.Labels.StopJob)}
        />
      </div>
      <StopLoggingModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onComplete={onComplete}
        onPause={onPause}
      />
      <TicketsPageDisplay
        assignment={assignment}
        instanceId={instanceId}
        tickets={assignment.assignmentInstances[0].tickets}
        signoffEnabled={signoffEnabled}
        onAddTicket={() => history.push({ pathname: `/jobs/${assignment.id}`, hash: 'activity' })}
        {...pageDisplayProps}
      />
    </div>
  );
};

const TabBar = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(true);
  const featuredAssignmentId = useFeaturedAssignmentId();

  // If the user is viewing a job, get the id
  const jobOfferId = location
    && location.pathname
    && location.pathname.match(/\/jobs\/([0-9]+)/)
    && location.pathname.match(/\/jobs\/([0-9]+)/)[1];

  const [viewingAssignment] = useGetAssignment(jobOfferId);
  const [featuredAssignment] = useGetAssignment(featuredAssignmentId);
  const showAcceptJobSection = viewingAssignment && viewingAssignment.sendStatus === PENDING;
  // we want to show the tab bar in the jobs detail page if the job is currently logging 
  // and we want to show tab bar in any other page (ie. jobs overview page, history, etc.) 
  const showTabBar = (!viewingAssignment && (featuredAssignment && (featuredAssignment.sendStatus === LOGGING || featuredAssignment.sendStatus === ACCEPTED)) ||
    viewingAssignment && viewingAssignment.sendStatus === LOGGING);

  if (!showAcceptJobSection && !featuredAssignment) return null;
  if (!showAcceptJobSection && !showTabBar) return null;

  return (
    <div className={styles.tabBar}>
      <button
        className={styles.tabBarToggle}
        onClick={() => setExpanded((e) => !e)}
        type="button"
      >
        <Line />
      </button>
      {expanded && (
        <>
          {showAcceptJobSection ? (
            <AcceptJobSection jobId={jobOfferId} hideDecline />
          ) : (
            <FeaturedAssignment assignment={featuredAssignment} />
          )}
        </>
      )}
    </div>
  );
};

export default TabBar;
