import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { LOGIN_FAILED } from 'Notification/NotificationTypes';

const APIURL = process.env.REACT_APP_API_URL;

// Moving old localState defaults into Reactive Variables for handling
// local state https://www.apollographql.com/docs/react/local-state/reactive-variables/
const deviceIsLoggingVar = makeVar({
  __typename: 'Device',
  isLogging: false,
});
const notificationVar = makeVar({
  __typename: 'Notification',
  type: null,
  message: null,
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        device: {
          read() {
            return deviceIsLoggingVar();
          },
        },
        notification: {
          read() {
            return notificationVar();
          },
        },
      },
    },
    Employee: {
      keyFields: ['accountID', 'companyID'],
    },
  },
});

const httpLink = createUploadLink({
  uri: APIURL,
  credentials: 'include',
  fetchOptions: { mode: 'cors' },
});

const retryLink = new RetryLink({
  delay: {
    initial: 10000,
    max: Infinity,
    jitter: true,
  },
  attempts: (count, operation, error) => !error.response && count <= 3,
});

let theHistory;
let sendMessage;

export const SetHistory = h => (theHistory = h);

const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }

  if (!!operation && operation.operationName === 'AccountMetadataQuery') {
    sendMessage({ message: 'Error logging in', type: LOGIN_FAILED });
    theHistory.push('/login');
  } else if (!!networkError && (networkError.statusCode === 401 || networkError.statusCode === 403)) {
    theHistory.push('/login');
  }
});

export const Client = new ApolloClient({
  link: ApolloLink.from([retryLink, errorLink, httpLink]),
  resolvers: {
    Mutation: {
      setDeviceIsLogging: (_, { isLogging }) => {
        const device = {
          __typename: 'Device',
          isLogging,
        };
        return deviceIsLoggingVar(device);
      },
      setNotification: (_, { type, message }) => {
        const notification = {
          __typename: 'Notification',
          message: message || null,
          type,
        };
        return notificationVar(notification);
      },
    },
  },
  cache,
  queryDeduplication: true,
});

sendMessage = ({ message, type }) => (
  notificationVar({
    __typename: 'Notification',
    message: message || null,
    type,
  })
);
