/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React from 'react';
// Components
import { Add, Switch, Ellipsis } from 'common-ui/Icon';
import { Button } from 'common-ui/Button';
import ReadOnlyUI from './ReadOnlyFile.ui';
import styles from './styles.module.scss';
import Thumbnail from './components/Thumbnail';


type UploadUIPropTypes = {
  multiple?: boolean,
  isUploading?: boolean,
  readOnly?: boolean,
  orientation?: string | number,
  value: Array<mixed> | mixed,
  error: string,
  onUploadButtonClicked: () => mixed,
  handleSelectedImages: () => mixed,
  setInputRef: mixed => mixed,
  className?: string,
  thumbnail?: string,
  includeThumbnail?: boolean,
  includeFileList?: boolean,
  uploadCount?: string | number,
  uploadingText?: string,
  uploadText?: string,
  uploadAgainText?: string,
};

const UploadUI = ({
  multiple,
  isUploading,
  readOnly,
  orientation,
  value,
  error,
  onUploadButtonClicked,
  handleSelectedImages,
  setInputRef,
  className,
  thumbnail,
  includeThumbnail,
  uploadCount,
  uploadingText,
  uploadText,
  uploadAgainText,
  includeFileList,
}: UploadUIPropTypes) => {
  if (readOnly) {
    return (
      <ReadOnlyUI
        {...{
          includeThumbnail,
          thumbnail,
          orientation,
          uploadCount,
          includeFileList,
          value,
          multiple,
        }}
      />
    );
  }

  // get button text
  let addIcon = <Add />;
  let buttonText = uploadText;
  if (isUploading) {
    buttonText = uploadingText;

    if (
      !multiple
      && (value instanceof Array && value.length > 0)
    ) {
      addIcon = <Ellipsis />;
    }
    // $FlowFixMe ignore error in next line regarding types
  } else if (value instanceof Array && value.length > 0 && value[0].getURL) {
    buttonText = uploadAgainText;
    addIcon = <Switch />;
  } else if (value) {
    buttonText = uploadText;
  }

  return (
    <>
      <div className={`${styles.inputFileUpload} ${className || ''}`}>
        <div className={styles.fileUpload}>
          { includeThumbnail
            ? (
              <Thumbnail
                {...{
                  thumbnail,
                  orientation,
                  uploadCount,
                  multiple,
                }}
                onClick={onUploadButtonClicked}
              />
            )
            : null}
          <div className={styles.uploadButton}>
            <Button
              icon={addIcon}
              onClick={onUploadButtonClicked}
              text={buttonText || ''}
              large
            />
          </div>
        </div>
        <input
          type="file"
          multiple={multiple}
          accept="image/*, .pdf"
          onChange={handleSelectedImages}
          style={{ display: 'none' }}
          ref={setInputRef}
        />
      </div>
      {!!error && <div className={styles.genericErrorMessage}>{error.toString()}</div>}
    </>
  );
};

UploadUI.defaultProps = {
  multiple: true,
  isUploading: false,
  readOnly: false,
  orientation: 1,
  className: '',
  thumbnail: undefined,
  uploadCount: 0,
  includeThumbnail: false,
  uploadText: 'Add',
  uploadingText: 'Adding',
  uploadAgainText: 'Replace',
  includeFileList: false,
};

export default UploadUI;
