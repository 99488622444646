import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderComponent,
  renderNothing,
} from 'recompose';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { DEVICE_QUERY, SET_DEVICE_IS_LOGGING } from 'apollo';
import { ApolloRefetcher } from 'utils/HOC';
import { LOGGING } from 'utils/constants/job';

// Purpose is to reconcile apollo state with mobile device state logging
class DeviceLoggingReconciler extends Component {
  componentDidMount() {
    this.reconcileDeviceLogging(true);
  }

  componentDidUpdate() {
    this.reconcileDeviceLogging(false);
  }

  reconcileDeviceLogging(isFirstLaunch) {
    const {
      activeJobID,
      companyID,
      isLogging,
      setIsLogging,
      setNotLogging,
      userID,
    } = this.props;

    if (!isLogging && !!activeJobID && !!companyID && !!userID) {
      // (1) device not logging (2) active job present
      const startLocationInput = JSON.stringify({
        user_id: userID,
        company_id: companyID,
        job_id: activeJobID,
      });

      if (process.env.NODE_ENV === 'development') {
        console.log(
          `Calling Device Start Logging Handler with input: ${startLocationInput}`,
        );
      }

      try {
        // Starting logging function for iOS
        window.webkit.messageHandlers.StartLocationLoggingHandler.postMessage(
          startLocationInput,
        );
      } catch (error) {
        // throw away error
      }

      try {
        // Start logging function for Android
        window.Android.startLocationLoggingHandler(startLocationInput);
      } catch (error) {
        // throw away error
      }

      setIsLogging();
    } else if (isFirstLaunch || (isLogging && !activeJobID)) {
      // on Android, foreground service can still continue to log even
      // when app terminated
      // in the event device 1 starts logging, device 1 dies, device 2
      // starts logging then stops logging
      // device 1 on app relaunch needs to terminate foreground service
      // even when internal state is reconciled

      // (1) device is logging (2) no active job
      if (process.env.NODE_ENV === 'development') {
        console.log('Calling Device Stop Logging Handler');
      }

      try {
        window.webkit.messageHandlers.StopLocationLoggingHandler.postMessage(
          '',
        );
      } catch (error) {
        // throw away error
      }
      try {
        window.Android.stopLocationLoggingHandler('');
      } catch (error) {
        // throw away error
      }

      setNotLogging();
    }
  }

  render() {
    return null;
  }
}

DeviceLoggingReconciler.propTypes = {
  activeJobID: PropTypes.string,
  companyID: PropTypes.string,
  isLogging: PropTypes.bool.isRequired,
  setIsLogging: PropTypes.func.isRequired,
  setNotLogging: PropTypes.func.isRequired,
  userID: PropTypes.string,
};

DeviceLoggingReconciler.defaultProps = {
  activeJobID: null,
  companyID: null,
  userID: null,
};

const DEVICE_LOGGING_RECONCILER_QUERY = gql`
  query DeviceLoggingReconcilerQuery {
    whoami {
      id
    }
    myAssignments {
      id
      companyId
      company {
        id
      }
      sendStatus
    }
  }
`;

const EnhancedDeviceLoggingReconciler = compose(
  graphql(SET_DEVICE_IS_LOGGING),
  graphql(DEVICE_QUERY, { name: 'deviceQuery' }),
  branch(props => props.deviceQuery.loading, renderNothing),
  graphql(DEVICE_LOGGING_RECONCILER_QUERY, {
    name: 'userQuery',
    skip: props => !props.authenticated,
  }),
  branch(
    props => !!props.userQuery && (!props.userQuery.whoami || !props.userQuery.myAssignments),
    renderComponent(props => (
      <ApolloRefetcher refetch={props.userQuery.refetch} />
    )),
  ),
  branch(props => !!props.userQuery && props.userQuery.loading, renderNothing),
  mapProps(({ deviceQuery, mutate, userQuery }) => {
    const jobs = !!userQuery && !!userQuery.myAssignments ? userQuery.myAssignments : [];
    const activeJob = jobs.filter(job => job.sendStatus === LOGGING).pop() || null;

    return {
      activeJobID: activeJob ? activeJob.id : null,
      companyID: activeJob ? activeJob.companyId.toString() : null,
      isLogging: deviceQuery.device.isLogging,
      setIsLogging: () => {
        mutate({ variables: { isLogging: true } });
      },
      setNotLogging: () => {
        mutate({ variables: { isLogging: false } });
      },
      userID: userQuery && userQuery.whoami ? userQuery.whoami.id : null,
    };
  }),
)(DeviceLoggingReconciler);

EnhancedDeviceLoggingReconciler.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

EnhancedDeviceLoggingReconciler.defaultProps = {
  authenticated: false,
};

export default EnhancedDeviceLoggingReconciler;
