import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import PullToRefresh from 'pulltorefreshjs';
import styles from './styles.module.scss';

// calculate the offsetTop to the document top.
const getOffsetTop = (element) => {
  let { offsetTop } = element;
  let numberOfParents = 0;

  let currentElement = element;

  while (currentElement.offsetParent) {
    currentElement = element.offsetParent;
    offsetTop += currentElement.offsetTop;
    numberOfParents += 1;
    if (numberOfParents > 10) {
      throw new Error('too many parents');
    }
  }

  return offsetTop;
};

class PullToRefreshComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementIdSuffix: _.uniqueId(),
    };
    this.onRefresh = this.onRefresh.bind(this);
    this.setPullRef = this.setPullRef.bind(this);
    this.initPullToRefresh = this.initPullToRefresh.bind(this);
    this.pullToRefreshDom = null;
  }

  componentDidMount() {
    this.initPullToRefresh();
  }

  componentDidUpdate({
    instructionsRefreshing: prevInstructionsRefreshing,
    instructionsPullToRefresh: prevInstructionsPullToRefresh,
    instructionsReleaseToRefresh: prevInstructionsReleaseToRefresh,
    elementIdPrefix: prevElementIdPrefix,
  }) {
    const {
      instructionsRefreshing,
      instructionsPullToRefresh,
      instructionsReleaseToRefresh,
      elementIdPrefix,
    } = this.props;

    if (
      prevInstructionsRefreshing !== instructionsRefreshing
      || prevInstructionsPullToRefresh !== instructionsPullToRefresh
      || prevInstructionsReleaseToRefresh !== instructionsReleaseToRefresh
      || prevElementIdPrefix !== elementIdPrefix
    ) {
      this.initPullToRefresh();
    }
  }

  componentWillUnmount() {
    if (this.pullToRefreshDom) {
      this.pullToRefreshDom.destroy();
    }
  }

  onRefresh() {
    const { onRefresh } = this.props;
    if (onRefresh) {
      onRefresh();
    } else {
      window.location.reload();
    }
  }

  setPullRef(obj) {
    this.refreshDomRef = obj;
  }

  initPullToRefresh() {
    const that = this;
    const { elementIdSuffix } = this.state;
    const {
      instructionsRefreshing,
      instructionsPullToRefresh,
      instructionsReleaseToRefresh,
      elementIdPrefix,
    } = this.props;

    // if old one exists, destroy previous, then create a new one
    if (this.pullToRefreshDom) {
      this.pullToRefreshDom.destroy();
    }

    this.pullToRefreshDom = PullToRefresh.init({
      mainElement: `#${elementIdPrefix}_${elementIdSuffix}`,
      triggerElement: `#${elementIdPrefix}_${elementIdSuffix}`,
      instructionsRefreshing,
      instructionsPullToRefresh,
      instructionsReleaseToRefresh,
      refreshTimeout: 250,
      distThreshold: 50,
      onRefresh: () => {
        if (that.props.onRefresh) {
          return that.props.onRefresh();
        }

        return window.location.reload();
      },
      shouldPullToRefresh: () => {
        if (that.refreshDomRef) {
          const {
            top: distanceFromTop,
          } = that.refreshDomRef.getBoundingClientRect();
          const offsetTop = getOffsetTop(that.refreshDomRef);

          return Math.ceil(distanceFromTop) >= Math.floor(offsetTop);
        }

        return !window.scrollY;
      },
    });
  }

  render() {
    const { children, elementIdPrefix } = this.props;
    const { elementIdSuffix } = this.state;
    return (
      <div className={styles.pullToRefreshWrapper}>
        <div ref={this.setPullRef} id={`${elementIdPrefix}_${elementIdSuffix}`} className={styles.pullToRefresh}>
          {children}
        </div>
      </div>
    );
  }
}

PullToRefreshComponent.defaultProps = {
  elementIdPrefix: 'pull-to-refresh-js-id',
  instructionsRefreshing: 'Refreshing',
  instructionsPullToRefresh: 'Pull to refresh',
  instructionsReleaseToRefresh: 'Release to refresh',
  onRefresh: null,
};

PullToRefreshComponent.propTypes = {
  elementIdPrefix: PropTypes.string,
  onRefresh: PropTypes.func,
  children: PropTypes.node.isRequired,
  instructionsRefreshing: PropTypes.string,
  instructionsPullToRefresh: PropTypes.string,
  instructionsReleaseToRefresh: PropTypes.string,
};

export default PullToRefreshComponent;
