import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';

// Components
import { Close } from 'common-ui/Icon/';
import classNames from 'classnames';
import { Button } from '../Button';
import { Heading } from '../Text';
import styles from './styles.module.scss';
// technically this should be called by whoever is using this library
// but we include this here for convenience, assumes root node has id 'app'
// react-modal has a bug where it fails to set the modal context when
// rendered in a non-browser environment (such as when running tests)
// so we have to force it to find the 'body' element
if (typeof (window) !== 'undefined') {
  Modal.setAppElement('body');
} else {
  Modal.setAppElement('#app');
}

const BaseModal = ({
  className,
  heading,
  handleClose,
  isOpen,
  children,
  hasCornerCloseButton,
  modalContextClassName,
}) => (
  <Modal
    onRequestClose={handleClose}
    isOpen={isOpen}
    overlayClassName={styles.overlay}
    className={`${styles.modal} ${className || ''}`}
  >
    <div className={styles.header}>
      {!!heading && <Heading medium>{heading}</Heading>}
      { hasCornerCloseButton && (
        <div className={styles.close}>
          <Button onClick={handleClose} tiny alert rightIcon={<Close />} />
        </div>
      )}

    </div>
    <div className={classNames(styles.modalContent, modalContextClassName)}>{children}</div>
  </Modal>
);

BaseModal.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.any,
  hasCornerCloseButton: PropTypes.bool,
  modalContextClassName: PropTypes.string,
};

BaseModal.defaultProps = {
  isOpen: false,
  handleClose: () => null,
  children: null,
  hasCornerCloseButton: true,
  modalContextClassName: undefined,
};

export default BaseModal;
