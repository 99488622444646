import React, { useState } from 'react';
import { BaseModal } from 'OldCommon/Modal';
import { Text } from 'OldCommon/Text';
import { Button } from 'OldCommon/Button';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import { useI18n } from 'internationalization';
import ID from 'common-ui/ConsistentIDs';
import styles from './styles.module.scss';

const StopLoggingModal = ({
  visible,
  onClose,
  onComplete,
  onPause,
}) => {
  const intlz = useI18n();
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseModal
      isOpen={visible}
      heading={intlz('Stop Job')}
      handleClose={onClose}
    >
      <Text secondary>{intlz("Let your dispatcher know if you're done this job or planning to resume it later.")}</Text>
      <div className={styles.stopLoggingModalButtons}>
        <Button
          fullWidth
          disabled={disabled}
          primary
          label={intlz('Stop Job')}
          onClick={() => {
            setDisabled(true);
            onComplete()
              .finally(() => setDisabled(false));
          }}
          id={ID.create(ID.Areas.Jobs, ID.Types.Button, ID.Labels.ConfirmStopJob)}
        />
        <Button
          fullWidth
          disabled={disabled}
          newTicket
          label={intlz('Pause Job')}
          onClick={(e) => {
            setDisabled(true);
            onPause(e)
              .finally(() => setDisabled(false));
            GA.trackClick(GAActions.jobPaused, 'Pause Job');
          }}
          id={ID.create(ID.Areas.Jobs, ID.Types.Button, ID.Labels.ConfirmPauseJob)}
        />
      </div>
    </BaseModal>
  );
};

export default StopLoggingModal;
