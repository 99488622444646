// @flow
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

const TICKET_NOTIFICATIONS_QUERY = gql`
  query NotificationsQuery {
    notifications {
      id
      notificationType
      notificationExtraInfo
      clearedAt
    }
  }
`;

// Returns a map of assignment ids to an array of notification ids
type AssignmentNotificationsMap = { [string]: string[] };

const useGetTicketNotifications = (network: ?boolean): [AssignmentNotificationsMap, () => void] => {
  const {
    data, loading, error, refetch,
  } = useQuery(TICKET_NOTIFICATIONS_QUERY, {
    fetchPolicy: network && 'network-only',
  });

  return useMemo(() => {
    if (loading || error || !data) return [{}, () => {}];

    const ticketNotificationsMap = {};
    data.notifications
      .filter((n) => !n.clearedAt)
      .filter((n) => n.notificationType === 'TicketScaleGeneratedNotification')
      .forEach((n) => {
        try {
          const info = JSON.parse(n.notificationExtraInfo);
          const assignmentNotificationsList = ticketNotificationsMap[info.AssignmentID] || [];
          ticketNotificationsMap[info.AssignmentID] = [...assignmentNotificationsList, n.id];
        } catch (e) {
          // noop
        }
      });

    return [ticketNotificationsMap, refetch];
  }, [data, loading, error, refetch]);
};

export default useGetTicketNotifications;
