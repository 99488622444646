// @flow
const TicketFieldTypes = {
  TicketNumber: 'TicketNumber',
  GrossWeight: 'GrossWeight',
  TareWeight: 'TareWeight',
  LoadTime: 'LoadTime',
  DumpTime: 'DumpTime',
  PickUpSiteArrival: 'PickUpSiteArrival',
  DropOffSiteDeparture: 'DropOffSiteDeparture',
  Product: 'Product',
  JobNumber: 'JobNumber',
  Phase: 'Phase',
  PickUpSite: 'PickUpSite',
  DropOffSite: 'DropOffSite',
};

export default TicketFieldTypes;
