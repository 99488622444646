// @flow
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export const DRIVER_CONFIGURABLE_FIELDS = gql`
  query DataSettingsQuery($companyID: ID!, $projectID: ID) {
    dataSettings(companyID: $companyID, projectID: $projectID) {
      configurableFields {
        id
        name
        type
        options
        isProjectField
        isAssignmentField
        isTicketField
        isTimesheetField
        driverVisible
      }
    }
  }
`;

const useDriverConfigurableFields = (companyID: string, projectID: string) => {
  const { loading, error, data } = useQuery(DRIVER_CONFIGURABLE_FIELDS, {
    variables: {
      companyID,
      projectID
    },
    skip: !companyID || companyID === '0',
  });
  return useMemo(() => {
    if (!data || loading || error) {
      return {
        configurableFields: [],
      };
    }
    return data.dataSettings;
  }, [loading, error, data]);
};

export default useDriverConfigurableFields;
