import { connect } from 'formik';
import { compose, withProps } from "recompose";
import CheckboxUI from './Checkbox.ui';

const checkboxHOC = compose(
  connect,
  withProps(({ formik, name }) => {
    const value = formik.values[name] || "";
    const touched = formik.touched[name];
    const error = formik.errors[name];
    const shouldShowError = touched && !!error;

    return {
      value: value,
      touched: touched,
      error: error,
      handleChange: formik.handleChange,
      onBlur: formik.handleBlur,
      shouldShowError: shouldShowError,
    };
  })
)(CheckboxUI);

export default checkboxHOC;
