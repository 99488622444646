import React from 'react';
import moment from 'moment-timezone';
import { Badge } from 'OldCommon/Badge';
import { EmptyState } from 'OldCommon/EmptyState';
import { Heading } from 'OldCommon/Text';
import { LOGGING } from 'utils/constants/job';
import { useTimezone, usePermissions } from 'common-ui/Hooks';
import { useI18n } from 'internationalization';
import {
  useGetAllAssignments,
  useFeaturedAssignmentId,
  useGroupedAssignments,
} from './graphql';
import PullToRefresh from '../Common/PullToRefresh';
import JobAndReceiptTopNav from '../Common/JobAndReceiptTopNav';
import AssignmentCard from './subcomponents/AssignmentCard';
import SelfLogButton from './subcomponents/SelfLogButton';
import DayHeader from './subcomponents/DayHeader';
import styles from './styles.module.scss';

const JobsOverview = () => {
  const timezone = useTimezone();
  const [assignments, onRefresh] = useGetAllAssignments();
  const [pendingJobs, acceptedJobsGroupedByDay] = useGroupedAssignments();
  const featuredAssignmentId = useFeaturedAssignmentId();

  const {
    CanSelfLog: canSelfLog,
  } = usePermissions();

  const intlz = useI18n();
  return (
    <>
      <JobAndReceiptTopNav />
      <PullToRefresh onRefresh={onRefresh}>
        <div className={styles.jobsContainer}>
          {!!pendingJobs && pendingJobs.length !== 0 && (
            <>
              <div className={styles.pendingHeader}>
                <Heading small>{intlz('Offers')}</Heading>
                <Badge count={pendingJobs.length} alert />
              </div>
              {pendingJobs.map((job) => (
                <AssignmentCard key={job.id} assignment={job} />
              ))}
            </>
          )}
          {!!acceptedJobsGroupedByDay
            && Object.keys(acceptedJobsGroupedByDay).sort().map((date) => (
              <div key={date}>
                <DayHeader day={moment.tz(date, timezone)} prefTimeZone={timezone} />
                {acceptedJobsGroupedByDay[date].length ? (
                  acceptedJobsGroupedByDay[date].map((job) => (
                    <AssignmentCard
                      key={job.id}
                      assignment={job}
                      isFeatured={job.id === featuredAssignmentId}
                    />
                  ))
                ) : (
                  <EmptyState
                    heading={intlz('Your schedule is clear')}
                    subheading={intlz('Accepted jobs will appear here')}
                  />
                )}
                {assignments.findIndex((a) => a.sendStatus === LOGGING) === -1
                  && moment.tz(date, timezone).isSame(moment.tz(timezone), 'day')
                  && canSelfLog
                  && (
                    <SelfLogButton />
                  )}
              </div>
            ))}
        </div>
      </PullToRefresh>
    </>
  );
};

export default JobsOverview;
