// @flow
import React, { type Node } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import classNames from "classnames";
import { Download } from "../Icon";
import styles from "./styles.module.scss";

type TicketZoomerProps = {
  src: string,
  filename: string,
  rotation: number,
  mimeType: string,
  imgHeight?: ?string | ?number,
  imgWidth?: ?string | ?number,
  background?: boolean,
  children?: Node
};

const TicketZoomer = ({
  src,
  filename,
  rotation,
  mimeType,
  imgHeight,
  imgWidth,
  background,
  children,
  ...rest
}: TicketZoomerProps) => (
  <div
    className={classNames(styles.ticketZoomerContainer, {
      [styles.darkBackground]: background
    })}
  >
    {mimeType && mimeType.includes("image") ? (
      <TransformWrapper {...rest}>
        <TransformComponent>
          <img
            src={src}
            alt={filename}
            style={{
              transform: `rotate(${rotation * 90}deg)`,
              height: imgHeight,
              width: imgWidth,
              maxHeight: "800px",
              maxWidth: "800px"
            }}
            className={styles.ticketZoomerImage}
          />
        </TransformComponent>
      </TransformWrapper>
    ) : (
      <a href={src} className={styles.pdfDownload} download>
        <Download />
        {filename}
      </a>
    )}
    {children}
  </div>
);

TicketZoomer.defaultProps = {
  imgHeight: "100%",
  imgWidth: "100%",
  background: false,
  children: null
};

export default TicketZoomer;
