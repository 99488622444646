// @flow
import React, { type Node } from 'react';
import moment from 'moment-timezone';
import { useTimezone } from 'common-ui/Hooks';
import type { Account } from 'common-ui/types';
import { useI18n } from 'internationalization';
import { useCurrentUser } from './gql';
import styles from './styles.module.scss';

type ActivityStreamItemProps = {
  children: Node,
  date: string,
  user?: Account | null,
};

const ActivityStreamItem = ({ children, date, user }: ActivityStreamItemProps) => {
  const timezone = useTimezone();
  const intlz = useI18n();
  const currentUser = useCurrentUser();
  const itemUser = user || currentUser; // if no user provided, default to the current user
  const userPhoto = itemUser
    && itemUser.avatar
    && (itemUser.avatar.thumbnailURL || itemUser.avatar.getURL);
  if (!itemUser || !currentUser) return null;
  const isCurrentUser = itemUser.id === currentUser.id;

  return (
    <span
      data-fromother={!isCurrentUser}
      className={styles.activityStreamItemWrapper}
    >
      <div className={styles.activityStreamItem}>
        <div className={styles.activityStreamItemContents}>
          {children}
          <div className={styles.activityStreamItemDate}>
            {moment.tz(date, timezone).calendar(null, {
              lastDay: `[${intlz('Yesterday')}] h:mma`,
              sameDay: `[${intlz('Today')}] h:mma`,
              nextDay: `[${intlz('Tomorrow')}] h:mma`,
              lastWeek: `[${intlz('Last')}] dddd h:mma`,
              nextWeek: 'dddd h:mma',
              sameElse: 'L h:mma',
            })}
            &nbsp;
            {intlz('by {user}', {
              user: isCurrentUser ? intlz('You') : `${itemUser.firstName} ${itemUser.lastName}`,
            })}
          </div>
        </div>
        <div className={styles.activityStreamItemProfile}>
          {userPhoto ? (
            <img
              alt={`${itemUser.firstName || ''} ${itemUser.lastName || ''}`}
              src={userPhoto}
              className={styles.activityStreamItemPhoto}
            />
          ) : (
            <div className={styles.activityStreamItemInitials}>
              {`${itemUser.firstName ? itemUser.firstName.charAt(0) : ''}${itemUser.lastName ? itemUser.lastName.charAt(0) : ''}`}
            </div>
          )}
        </div>
      </div>
    </span>
  );
};

ActivityStreamItem.defaultProps = {
  user: null,
};

export default ActivityStreamItem;
