import React from 'react';

const BlackGhost = props => (
  <svg
    width="22px"
    height="24px"
    viewBox="0 0 22 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Ghost</title>
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, 0.000000)">
        <g transform="translate(2.000000, 0.000000)">
          <path
            d="M1.22994994,7.11922432 C3.33757558,1.87795281 7.41643162,0 10.7385664,0 C14.0607012,0 17.6563686,0.676352878 20.2471829,7.11922432 C22.8379971,13.5620958 22.3069488,22.592584 20.2471829,23.6461541 C18.1874169,24.6997242 19.9518495,20.5249065 18.1009807,20.3907668 C16.250112,20.2566271 16.8328375,23.2945617 15.3777031,23.2945617 C14.3208577,23.2945617 14.321651,20.4617428 12.1809587,20.3907668 C10.0402664,20.3197908 9.47858985,23.416734 7.65709589,23.2945617 C5.83560193,23.1723894 6.94183277,20.3907668 4.87122121,20.3907668 C2.80060965,20.3907668 2.32591551,24.8740684 1.22994994,23.6461541 C0.133984366,22.4182398 -0.877675703,12.3604958 1.22994994,7.11922432 Z M13.997963,9.94129064 C15.1023717,9.94129064 15.997672,9.05111716 15.997672,7.95303251 C15.997672,6.85494787 15.1023717,5.96477439 13.997963,5.96477439 C12.8935542,5.96477439 11.998254,6.85494787 11.998254,7.95303251 C11.998254,9.05111716 12.8935542,9.94129064 13.997963,9.94129064 Z M10.9983995,13.9178069 C11.5506039,13.9178069 11.998254,13.4727202 11.998254,12.9236778 C11.998254,12.3746355 11.5506039,11.9295488 10.9983995,11.9295488 C10.4461951,11.9295488 9.99854497,12.3746355 9.99854497,12.9236778 C9.99854497,13.4727202 10.4461951,13.9178069 10.9983995,13.9178069 Z M5.99912698,9.94129064 C7.10353577,9.94129064 7.99883598,9.05111716 7.99883598,7.95303251 C7.99883598,6.85494787 7.10353577,5.96477439 5.99912698,5.96477439 C4.8947182,5.96477439 3.99941799,6.85494787 3.99941799,7.95303251 C3.99941799,9.05111716 4.8947182,9.94129064 5.99912698,9.94129064 Z"
            id="BlackGhost-path-1"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default BlackGhost;
