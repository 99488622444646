import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Login, Logout } from 'Login';
import AuthApp from './AuthApp';

const App = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route component={AuthApp} />
  </Switch>
);

export default App;
