import { useContext, createContext } from 'react';

// PageContext gives an identifier to the context consumer usePageContext
// This can be used to more specifically identify components that are used in multiple
// places in the app, for things like Google Analytics or Cypress tests
export const PageContext = createContext({
  pageID: 'generic',
});

const usePageContext = () => useContext(PageContext);

export default usePageContext;
