import React from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";

const Text = ({
  className,
  bold,
  secondary,
  label,
  normal,
  hint,
  nav,

  children,
  ...props
}) => {
  let style = "";
  if (!!bold) {
    style = styles.textBold;
  } else if (!!secondary) {
    style = styles.textSecondary;
  } else if (!!label) {
    style = styles.textLabel;
  } else if (!!hint) {
    style = styles.textHint;
  } else if (!!nav) {
    style = styles.navLabel;
  } else {
    style = styles.textDefault;
  }

  if (!!className) {
    style = style.concat(` ${className}`);
  }

  return (
    <div className={style} {...props}>
      {children}
    </div>
  );
};

Text.propTypes = {
  className: PropTypes.string,
  bold: PropTypes.bool,
  secondary: PropTypes.bool,
  label: PropTypes.bool,
  hint: PropTypes.bool,
  nav: PropTypes.bool,
  children: PropTypes.node
};

Text.defaultProps = {
  bold: false,
  secondary: false,
  label: false,
  hint: false,
  nav: false
};

export default Text;
