// @flow
import React from 'react';
import { Button } from 'common-ui/Button';
import moment from 'moment';
import { TicketThumbnail } from 'common-ui/Tickets';
import { Signature } from 'common-ui/Icon';
import type { Ticket } from 'common-ui/types';
import { useI18n } from 'internationalization';
import { isSignedOff } from 'utils/tickets.utils';
import { formatPayUnit } from 'utils/format';
import ActivityStreamItem from '../ActivityStreamItem';
import SpeechBubble from './SpeechBubble';
import SignedOffTag from './SignedOffTag';
import styles from './styles.module.scss';

type TicketStreamItemProps = {
  ticket: Ticket,
  onThumbnailClick: () => void,
  onSignoff: () => void,
  signoffEnabled: boolean,
};

const TicketStreamItem = ({
  ticket,
  onSignoff,
  signoffEnabled,
  onThumbnailClick,
  ...itemProps
}: TicketStreamItemProps) => {
  const intlz = useI18n();

  const ticketText = [];
  if (ticket.id) ticketText.push(`${intlz('Tread ID')}: ${ticket.id}`);
  if (ticket.ticketNumber) ticketText.push(`${intlz('Ticket')}: ${ticket.ticketNumber}`);
  if (ticket.quantity) ticketText.push(`${intlz('Quantity')}: ${ticket.quantity}`);
  if (ticket.haulerRateUnit) ticketText.push(`${intlz('Rate Unit')}: ${formatPayUnit(ticket.haulerRateUnit)}`);
  if (ticket.gross) ticketText.push(`${intlz('Gross')}: ${ticket.gross}`);
  if (ticket.tare) ticketText.push(`${intlz('Tare')}: ${ticket.tare}`);
  if (ticket.notes) ticketText.push(`${intlz('Notes')}: ${ticket.notes}`);
  if (ticket.startTime) ticketText.push(`${intlz('Load Time')}: ${moment(ticket.startTime).format('h:mm a')}`);
  if (ticket.endTime) ticketText.push(`${intlz('Dump Time')}: ${moment(ticket.endTime).format('h:mm a')}`);

  const canSignOff = signoffEnabled && !!ticket.signoffsRequired;

  return (
    <ActivityStreamItem date={ticket.createdAt} {...itemProps}>
      <div className={styles.ticketStreamItemContents}>
        <SpeechBubble text={ticketText.join(', ')} onClick={onThumbnailClick} />
        <div className={styles.ticketContainer}>
          {canSignOff && !isSignedOff(ticket) && (
            <Button
              text={intlz('Get Sign Off')}
              large
              icon={<Signature />}
              className={styles.signOffButton}
              onClick={onSignoff}
            />
          )}
          {canSignOff && isSignedOff(ticket) && (
            <SignedOffTag date={ticket.signoffs[0].signoffDate} />
          )}
          {!!ticket.data.getURL && (
            <div className={styles.ticketThumbnailContainer}>
              <TicketThumbnail ticket={ticket} onClick={onThumbnailClick} />
            </div>
          )}
        </div>
      </div>
    </ActivityStreamItem>
  );
};

export default TicketStreamItem;
