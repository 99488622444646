import * as React from "react";

const TicketNotSignedOff = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={10} cy={10} r={10} fill="#B40707" />
        <path
            d="M9.53333 11.4056C9.42667 10.5385 9.33778 9.62937 9.26667 8.67832C9.20444 7.71795 9.17333 6.82284 9.17333 5.99301V5H10.88V5.99301C10.88 6.5711 10.8622 7.17249 10.8267 7.7972C10.8 8.42191 10.7556 9.04662 10.6933 9.67133C10.64 10.2867 10.5822 10.8648 10.52 11.4056H9.53333ZM10 15C9.68 15 9.43111 14.9021 9.25333 14.7063C9.08444 14.5105 9 14.2354 9 13.8811C9 13.5548 9.08889 13.289 9.26667 13.0839C9.45333 12.8695 9.69778 12.7622 10 12.7622C10.3111 12.7622 10.5556 12.8601 10.7333 13.0559C10.9111 13.2517 11 13.5268 11 13.8811C11 14.2075 10.9067 14.4779 10.72 14.6923C10.5333 14.8974 10.2933 15 10 15Z"
            fill="white"
        />
    </svg>
);

export default TicketNotSignedOff;
