// parseGraphqlError returns a string describing error
// along with a fallback if not a valid graphql error
const parseGraphqlError = (error, fallback) => {
  if (!error) {
    return null;
  }

  if (
    !!error.graphQLErrors
    && error.graphQLErrors.length > 0
    && !!error.graphQLErrors[0].message
  ) {
    return error.graphQLErrors[0].message;
  }

  if (typeof(error) === "string") {
    return error;
  }

  if (fallback) {
    return fallback;
  }

  return 'An error occurred';
};

export default parseGraphqlError;
