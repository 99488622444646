import React, { useState } from 'react';
import { BaseModal } from 'OldCommon/Modal';
import { Text } from 'OldCommon/Text';
import { PageNav } from 'OldCommon/PageNav';
import { Add } from 'common-ui/Icon';
import { useI18n } from 'internationalization';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import ID from 'common-ui/ConsistentIDs';
import { usePermissions } from 'common-ui/Hooks';
import { useCompanies, useStartSelfLog } from './gql';
import styles from './styles.module.scss';

const SelfLogCompanies = ({
  companyID,
  companyName,
  onSelectCompany,
  index,
}) => {
  const {
    CanSelfLog: canSelfLog,
  } = usePermissions(companyID);
  if (!canSelfLog) return null;
  return (
    <PageNav
      key={companyID}
      onClick={() => onSelectCompany(companyID)}
      label={companyName}
      id={ID.create(ID.Areas.Jobs, ID.Types.Option, `company-${index}`)}
    />
  );
};

const CompanyPickerModal = ({
  companies,
  onSelectCompany,
  visible,
  onClose,
}) => {
  const intlz = useI18n();
  return (
    <BaseModal
      heading={intlz('Company')}
      isOpen={visible}
      handleClose={onClose}
    >
      <Text>{intlz('Who are you completing this job for?')}</Text>
      {!!companies && !!companies.length && companies.map((c, i) => (
        <SelfLogCompanies
          companyID={c.id}
          companyName={c.name}
          onSelectCompany={onSelectCompany}
          key={`self-log-companies-${c.id}`}
          index={i}
        />
      ))}
    </BaseModal>
  );
};

const SelfLogButton = () => {
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [selfLogDisabled, setSelfLogDisabled] = useState(false);
  const startSelfLog = useStartSelfLog();
  const companies = useCompanies();
  const intlz = useI18n();
  return (
    <>
      <button
        id={ID.create(ID.Areas.Jobs, ID.Types.Button, ID.Labels.NewSelfLog)}
        className={styles.selfLogButton}
        onClick={() => {
          if (selfLogDisabled || !companies || !companies.length) return;
          if (companies.length === 1) {
            setSelfLogDisabled(true);
            startSelfLog(companies[0].id).then(() => setSelfLogDisabled(false));
          } else {
            setCompanyModalVisible(true);
          }
          GA.trackClick(GAActions.newJobLog, 'New Job Log');
        }}
        type="button"
      >
        <div className={styles.selfLogIcon}>
          <Add />
        </div>
        <Text secondary className={styles.selfLogText}>{intlz('New Job Log')}</Text>
      </button>
      <CompanyPickerModal
        companies={companies}
        onSelectCompany={(id) => {
          setSelfLogDisabled(true);
          startSelfLog(id).then(() => setSelfLogDisabled(false));
          setCompanyModalVisible(false);
        }}
        visible={companyModalVisible}
        onClose={() => setCompanyModalVisible(false)}
      />
    </>
  );
};

export default SelfLogButton;
