// @flow
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import GA, { GAActions } from '../../Analytics/GoogleAnalytics';
import { Chat } from '../../Icon';
import styles from './styles.module.scss';

const IntercomLauncher = ({ mobile }: { mobile?: boolean }) => {
  const [messageCount, setMessageCount] = useState(0);
  const [hidden, setHidden] = useState(true);
  useEffect(() => {
    window.Intercom('onUnreadCountChange', (unreadCount) => {
      setMessageCount(unreadCount);
    });
    window.Intercom('onShow', () => setHidden(false));
    window.Intercom('onHide', () => setHidden(true));
  }, []);
  return (
    <div
      className={classNames(styles.intercomButton, { [styles.open]: !hidden })}
      id="intercom_launcher"
      role="button"
      tabIndex={0}
      // it seems the intercom events were triggering twice so commenting out the code
      // below to prevent that. Leaving this in for future reference. check out pr for
      // detailed description.
      onKeyPress={() => {
        // if (hidden) window.Intercom('show');
        // else window.Intercom('hide');
        GA.trackClick(GAActions.help);
      }}
      onClick={() => {
        // if (hidden) window.Intercom('show');
        // else window.Intercom('hide');
        GA.trackClick(GAActions.help);
      }}
    >
      {!!messageCount && hidden && (
        <div
          className={classNames(styles.intercomIcon, styles.notificationCount, {
            [styles.mobile]: mobile,
          })}
        >
          {messageCount}
        </div>
      )}
      {!hidden && (
        <div
          className={classNames(styles.intercomIcon, {
            [styles.mobile]: mobile,
          })}
        >
          &times;
        </div>
      )}
      <Chat />
    </div>
  );
};

IntercomLauncher.defaultProps = {
  mobile: false,
};

export default IntercomLauncher;
