// @flow
import React, { useState, useLayoutEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, TextOnlyButton } from 'common-ui/Button';
import { FormField, TextInput } from 'common-ui/Form';
import { useFullScreenRender } from 'common-ui/Hooks';
import { Check, Signature } from 'common-ui/Icon';
import { useI18n } from 'internationalization';
import type { Signoff } from 'common-ui/types';
import { ERROR } from 'components/Notification/NotificationTypes';
import parseGraphqlError, { useNotify } from 'utils/Error';
import { useCreateSignoff } from './gql';
import styles from './styles.module.scss';

type SignaturePageProps = {
  instanceId: string,
  instanceAttachmentId: string,
  onClose: () => void,
  onComplete: (Signoff) => void,
};

const SignaturePage = ({
  instanceId,
  instanceAttachmentId,
  onClose,
  onComplete,
}: SignaturePageProps) => {
  const signatureRef = useRef();
  const [name, setName] = useState('');
  const intlz = useI18n();
  const fullScreenRender = useFullScreenRender();
  const createSignoff = useCreateSignoff(instanceId, instanceAttachmentId);
  const [submitting, setSubmitting] = useState(false);
  const notify = useNotify();

  const [canvasWidth, setCanvasWidth] = useState(
    // Check if landscape
    ((window.innerWidth / window.innerHeight) > (13 / 9))
      ? window.innerWidth * 0.75
      : window.innerWidth * 0.9,
  );

  useLayoutEffect(() => {
    function updateSize() {
      setCanvasWidth(
        // Check if landscape
        ((window.innerWidth / window.innerHeight) > (13 / 9))
          ? window.innerWidth * 0.75
          : window.innerWidth * 0.9,
      );
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return fullScreenRender(
    <div className={styles.signaturePage}>
      <div className={styles.signaturePageHeader}>
        <div className={styles.textHeader}>{intlz('Signature')}</div>
        <TextOnlyButton text={intlz('Cancel')} onClick={onClose} />
      </div>
      <FormField
        label={intlz('Your Name')}
        hint={<div className={styles.requiredText}>{intlz('Required')}</div>}
      >
        <TextInput value={name} onChange={setName} />
      </FormField>
      <div className={styles.signatureInputContainer}>
        <div className={styles.signatureCanvasContainer}>
          <SignatureCanvas
            ref={signatureRef}
            clearOnResize={false}
            penColor="black"
            backgroundColor="white"
            canvasProps={{
              width: canvasWidth,
              height: 200,
              className: styles.signatureCanvas,
            }}
          />
        </div>
        <div className={styles.signatureButtonsContainer}>
          <Button
            large
            styleName="negative"
            text={intlz('Clear')}
            onClick={() => {
              if (signatureRef.current) signatureRef.current.clear();
            }}
          />
          <Button
            large
            disabled={!name.length || submitting}
            styleName="primary"
            text={intlz('Done')}
            icon={!submitting ? <Check /> : <span className={styles.wiggle}><Signature /></span>}
            onClick={async () => {
              if (signatureRef.current) {
                setSubmitting(true);
                const url = signatureRef.current.toDataURL('image/jpeg', 1);
                const fileBlob = await (await fetch(url)).blob();
                const file = new File([fileBlob], 'signoff.jpg');
                createSignoff(file, name)
                  .then((signoff) => {
                    onComplete(signoff);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    notify({
                      variables: { type: ERROR, message: parseGraphqlError(error) },
                    });
                  });
              }
            }}
          />
        </div>
      </div>
    </div>,
  );
};

export default SignaturePage;
