// @flow
import React from 'react';
import ClickableDiv from '../ClickableDiv';
import { MultiDoc } from '../Icon';
import type { Ticket } from '../types';
import styles from './styles.module.scss';

type TicketThumbnailProps = {
  ticket: Ticket,
  onClick?: () => void,
};

const TicketThumbnail = ({ ticket, onClick }: TicketThumbnailProps) => (
  <ClickableDiv
    onClick={onClick}
    className={styles.ticketThumbnail}
    style={{
      backgroundImage: `url(${ticket.data.thumbnailURL || ticket.data.getURL})`,
      transform: `rotate(${(ticket.rotation || 0) * 90}deg)`,
    }}
  >
    {(!ticket.data || !ticket.data.mimeType || !ticket.data.mimeType.includes('image')) && <MultiDoc />}
  </ClickableDiv>
);

TicketThumbnail.defaultProps = {
  onClick: () => {},
};

export default TicketThumbnail;
