// @flow
import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { TIMESHEETS_QUERY } from './useTimesheets';

const DELETE_IMAGE_MUTATION = gql`
mutation DeleteTimesheetAttachment($attachmentID: ID!) {
  deleteTimesheetAttachment(attachmentID: $attachmentID) {
    id
  }
}
`;

const useDeleteImage = () => {
    const [deleteImage] = useMutation(DELETE_IMAGE_MUTATION);
    return useCallback((attachmentID: string) => (
        deleteImage({
            variables: {
                attachmentID,
            },
            refetchQueries: [getOperationName(TIMESHEETS_QUERY)],
        })
    ), [deleteImage]);
};

export default useDeleteImage;
