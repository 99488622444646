import React from 'react';
import {
  compose, branch, renderComponent, renderNothing,
} from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { ACCOUNT_METADATA_QUERY } from 'components/Analytics/MetadataProvider';
import ApolloRefetcher from './ApolloRefetcher';

const withRefetchRedirect = WrappedComponent => compose(
  graphql(ACCOUNT_METADATA_QUERY),
  branch(props => props.loading, renderNothing),
  branch(
    props => !props.data.whoami || !props.data.whoami.id,
    renderComponent(props => <ApolloRefetcher refetch={props.data.refetch} />),
  ),
)(WrappedComponent);

export default withRefetchRedirect;
