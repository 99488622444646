// @flow
import { useEffect, useRef } from 'react';
import type { RouterHistory } from 'react-router-dom';

// Listen to route changes and update intercom
export const useIntercomHistoryListener = (history: RouterHistory) => {
  const oldLocation = useRef({});
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!oldLocation.current
        || oldLocation.current.pathname !== location.pathname
        || oldLocation.current.search !== location.search) {
        window.Intercom('update');
      }
      oldLocation.current = location;
    });
    return () => unlisten();
  }, [history]);
};

// Boot intercom as an authenticated user
export const useIntercomAuthenticated = (userData: any, appName: string) => {
  useEffect(() => {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_KEY,
      user_id: userData.id,
      name: `${userData.firstName || ''} ${userData.lastName || ''}`,
      phone: userData.phoneNumber,
      app: appName,
      language_override: userData.language || null,
      custom_launcher_selector: '#intercom_launcher',
      hide_default_launcher: true,
      user_hash: userData.intercomHash || '',
      ...userData,
    });
  }, [userData, appName]);
};

// Update intercom when user data changes
export const useIntercomUserUpdate = (userData: any, appName: string) => {
  useEffect(() => {
    window.Intercom('update', {
      user_id: userData.id,
      name: `${userData.firstName || ''} ${userData.lastName || ''}`,
      phone: userData.phoneNumber,
      app: appName,
      language_override: userData.language || null,
      user_hash: userData.intercomHash || '',
      ...userData,
    });
  }, [userData, appName]);
};
