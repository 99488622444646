// @flow
import _ from 'lodash';
import {
  compose, withHandlers, withProps, type HOC,
} from 'recompose';
import { ImgOrientation } from 'common-ui/Upload/ImageManipulation';
import UploadUI from './Upload.ui';
import withPromisableStateHandlers from './utils/withPromisableStateHandlers';

type ImageUploadProps = {
  name?: string, // name of input
  multiple?: boolean, // allow multiple files
  readOnly?: boolean, // if this is read only
  includeThumbnail?: boolean, // if this is read only
  onUploaded?: any => mixed, // on Uploaded (handle changes to upload)
  value: mixed | Array<mixed>
};

export const ImageUploadHOC: HOC<*, ImageUploadProps> = compose(
  withPromisableStateHandlers(
    {
      isUploading: false,
      inputRef: null,
      orientation: 1,
      error: null,
    },
    {
      setIsUploading: () => isUploading => ({ isUploading }),
      setInputRef: () => input => ({ inputRef: input }),
      handleError: () => error => ({ error, isUploading: false }),
      setState: () => newState => newState,
      completeUpload: ({ value }, { multiple, onUploaded }) => (
        newValues,
        files,
      ) => {
        // set values to updated, and also run onUploaded if exists
        const completeValues = multiple ? value.concat(newValues) : newValues;

        if (onUploaded) {
          onUploaded(completeValues);
        }

        // check orientation of last image uploaded, and set orientation for it.
        const lastFile = _.last(files);

        if (lastFile) {
          return ImgOrientation(lastFile).then(orientation => ({
            isUploading: false,
            orientation,
          }));
        }

        return { isUploading: false };
      },
    },
  ),
  withHandlers({
    handleSelectedImages: ({
      UploadFile,
      setIsUploading,
      isUploading,
      isTestMode,
      value,
      completeUpload,
      handleError,
      inputRef,
    }) => (ev) => {
      const {
        currentTarget: { files },
      } = ev;

      if (!!files && files.length >= 0 && !isUploading) {
        setIsUploading(true);
        let newValue = [];

        if (isTestMode) {
          // test mode
          newValue = _.map(files, (file, i: number) => ({
            id: i,
            getURL: 'https://tread.io/images/logo-icon2.svg',
          }));

          return completeUpload(newValue, files);
        }

        if (!UploadFile) {
          throw new Error('no upload Images function');
        }

        return UploadFile({ files, value })
          .then(({ uploadedFileValues }) => completeUpload(uploadedFileValues, files))
          .catch((e) => {
            handleError(e);
          })
          .finally(() => {
            // eslint-disable-next-line no-param-reassign
            inputRef.value = null;
          });
      }
      return null;
    },
    onUploadButtonClicked: ({ inputRef, isUploading }) => () => {
      if (isUploading || !inputRef) {
        return;
      }

      // trigger file upload button
      inputRef.click();
    },
  }),
  withProps(({ value }) => {
    // get thumbnail url
    let thumbnail = null;
    if (!!value && value instanceof Array && value.length > 0) {
      thumbnail = value[value.length - 1].getURL;
    } else if (!!value && value.getURL) {
      thumbnail = value.getURL;
    }

    // get uploaded count
    let uploadCount = 0;
    if (!!value && value instanceof Array) {
      uploadCount += value.length;
    } else if (!!value && value.getURL) {
      uploadCount += 1;
    }

    return {
      thumbnail,
      uploadCount,
    };
  }),
);

export default ImageUploadHOC(UploadUI);
