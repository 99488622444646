import React from 'react';

const MultiDoc = props => (
  <svg width={36} height={44} viewBox="0 0 36 44" {...props}>
    <title>Multiple Documents</title>
    <path
      d="M19 4h-6.98v27.997L32 32V17h-8.997A4 4 0 0 1 19 13.008V4zm4 .587c0 3.228.002 8.413.003 8.413h8.283L23 4.587zm5 31.385v5.994c0 1.102-.9 1.996-1.991 1.997l-24.018.034A1.989 1.989 0 0 1 0 42V10c0-1.105.89-2 2.005-2H8V2c0-1.105.887-2 2.004-2h13.068c.555 0 1.324.321 1.716.715l10.502 10.57c.392.395.71 1.172.71 1.714v20.96c0 1.105-.9 2.003-1.991 2.004L28 35.972zm-4 .005l-14.009.02A1.989 1.989 0 0 1 8 34V12H4.02l-.019 28H24v-4.023z"
      fill="#2493FF"
      fillRule="evenodd"
    />
  </svg>
);

export default MultiDoc;
