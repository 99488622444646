// @flow
import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type RadioItemProps = {
  label: string,
  value: string,
  checked: boolean,
  onChange: (newValue: string) => void,
  readOnly: boolean,
  innerContent?: React.Node,
  id?: string,
};

const RadioItem = ({
  label,
  value,
  checked,
  onChange,
  readOnly,
  id,
  children,
  ...inputProps
}: RadioItemProps) => (
  <label>
    <input
      id={id}
      type="radio"
      checked={checked}
      aria-checked={checked}
      value={value}
      onClick={() => { if (!readOnly) onChange(value); }}
      onKeyPress={() => { if (!readOnly) onChange(value); }}
      {...inputProps}
    />
    <span>{label}</span>
    {checked && children ? (
      <>
        {children}
      </>
    ) : null}
  </label>
);

RadioItem.defaultProps = {
  id: undefined,
};

type RadioProps = {
  options: { label: string, value: string, content?: React.Node }[],
  value?: string,
  onChange: (newValue: string) => void,
  horizontal?: boolean,
  readOnly: boolean,
  id?: string,
};

const Radio = ({
  options,
  value,
  onChange,
  horizontal,
  readOnly,
  id,
  ...inputProps
}: RadioProps) => (
  <div className={classNames(styles.radioItemsGroup, {
    [styles.horizontal]: horizontal,
  })}
  >
    {options.map((option) => (
      <RadioItem
        id={`${id}-${option.label}-${option.value}`}
        label={option.label}
        key={option.value}
        onChange={onChange}
        readOnly={readOnly}
        children={option.children}
        {...{
          ...inputProps,
          checked: option.value === value,
          value: option.value,
        }}
      />
    ))}
  </div>
);

Radio.defaultProps = {
  value: '',
  horizontal: false,
  id: 'Radio-Item',
};

export default Radio;
