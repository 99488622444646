import React from 'react';

const Bell = props => (
  <svg width={19} height={22} viewBox="0 0 19 22" {...props}>
    <title>Notification</title>
    <path
      d="M6.333 19H3.17C1.419 19 0 17.655 0 16c0-1.099.628-2.078 1.588-2.601l-.005-3.9c0-3.456 2.467-6.365 5.824-7.234A1.914 1.914 0 0 1 7.39 2c0-1.105.945-2 2.111-2 1.166 0 2.111.895 2.111 2 0 .09-.006.178-.018.265 3.358.869 5.824 3.778 5.824 7.239v3.904c.96.525 1.583 1.5 1.583 2.592 0 1.658-1.418 3-3.169 3h-3.164c-.002 1.657-1.423 3-3.167 3-1.749 0-3.167-1.342-3.167-2.998V19zm2.111 0v.002c0 .551.473.998 1.056.998.58 0 1.054-.448 1.056-1H8.444zm7.256-4.065a.518.518 0 0 1-.394-.504V9.504C15.306 6.46 12.708 4 9.5 4 6.293 4 3.694 6.462 3.694 9.499v4.932a.518.518 0 0 1-.395.504l-.397.097c-.462.112-.79.509-.79.968 0 .55.473 1 1.057 1H15.83c.586 0 1.058-.447 1.058-1 0-.457-.33-.855-.792-.968l-.397-.097z"
      fill="#4A4A4A"
      fillRule="evenodd"
    />
  </svg>
);

export default Bell;
