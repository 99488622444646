import { Component } from 'react';
import PropTypes from 'prop-types';

// ApolloRefetcher is a stopgap scalable solution for dealing with Apollo cache object
// invalidation
// When component A queries obj {a, b, c} and component B mutates obj {b}, component
// A stops receiving
// the prop its querying. i.e. props.data.user becomes props.data when user object is
// invalidated.
class ApolloRefetcher extends Component {
  componentDidMount() {
    const { refetch } = this.props;

    refetch();
  }

  render() {
    return null;
  }
}

ApolloRefetcher.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default ApolloRefetcher;
