// @flow
import React, { useRef, type Node } from 'react';
import type { OCRResults } from '../types';
import { CompressIfImage } from './ImageManipulation';
import { useUploadAndRunOCR, useAttachmentUpload } from './gql';

// Usage:
// <UploadWrapper
//   onUpload={() => console.log('uploaded')}
//   onError={() => console.log('error')}
//   setUploading={() => {}}
//   {...uploadInputProps}
// >
//   {(openPicker) => <Button text="Click me" onClick={openPicker} />}
// </UploadWrapper>

type UploadWrapperProps = {
  input: { id: string, domain: string },
  shouldRunOCR?: boolean,
  onUpload?: (OCRResults) => void,
  minDimensions?: [number, number], // minimum [width, height] of the image
  maxSize?: number,
  onError?: (Error, () => void) => void,
  setUploading?: (boolean) => void,
  children: (() => void) => Node,
};

const validateFileSize = (
  file,
  [minWidth, minHeight]: [number, number],
  maxSize,
) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => {
    const { naturalWidth, naturalHeight } = img;

    window.URL.revokeObjectURL(img.src);

    if (naturalWidth < minWidth || naturalHeight < minHeight || file.size > maxSize) {
      reject(Error('Invalid file size'));
    } else {
      resolve(file);
    }
  };
  img.onerror = reject;
  img.src = window.URL.createObjectURL(file);
});

const UploadWrapper = ({
  input,
  shouldRunOCR,
  onUpload,
  onError,
  setUploading,
  minDimensions,
  maxSize,
  children,
}: UploadWrapperProps) => {
  const uploadOCR = useUploadAndRunOCR(input.id);
  const uploadAttachment = useAttachmentUpload(input);

  const uploadFunction = shouldRunOCR ? uploadOCR : uploadAttachment;

  const inputRef = useRef();

  const openPicker = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const multiple = shouldRunOCR ? false : true;

  return (
    <>
      {children(openPicker)}
      <input
        multiple={multiple}
        type="file"
        accept="image/*, .pdf"
        onChange={({ currentTarget: { files } }) => {
          setUploading(true);

          // NOTE: We don't use Promise.allSettled here since it's unsupported
          // on older mobile devices.
          Promise.all(Array.from(files).map((f) => (
            validateFileSize(f, minDimensions, maxSize)
              .then(CompressIfImage)
              .then(uploadFunction)
              .then(onUpload)
              .then(value => ({ status: "fulfilled", value }))
              .catch(reason => ({ status: "rejected", reason }))
          )))
            .then((results) => {
              const rejectedResults = results.filter(r => r.status === "rejected")
              if (rejectedResults.length > 0) {
                onError(`${results.length} attachments failed to upload.`);
              }
              setUploading(false);
              if (inputRef.current) inputRef.current.value = null;
            });
        }}
        style={{ display: 'none' }}
        ref={inputRef}
      />
    </>
  );
};

UploadWrapper.defaultProps = {
  onUpload: () => {},
  onError: () => {},
  minDimensions: [0, 0],
  maxSize: Infinity,
  setUploading: () => {},
  shouldRunOCR: false,
};

export default UploadWrapper;
