import React from 'react';
import moment from 'moment';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import Loadable from 'react-loadable';
import { IntlProvider } from 'react-intl';
import { withRefetchRedirect } from 'utils/HOC';
import DeviceLoggingReconciler from 'Device';
import MetadataProvider from 'Analytics';

// Components
import Jobs from 'Jobs';
import Timesheets from 'Timesheets';
import { NullableNotificationBanner } from 'Notification';
import TabBar from 'TabBar';
import TopNav from 'TopNav';
import {
  TimezoneContext,
  useIntercomAuthenticated,
  useIntercomUserUpdate,
  useIntercomHistoryListener,
  FullScreenPortal,
} from 'common-ui/Hooks';
import { messages } from 'internationalization';
import useGetUserInfo from './gql';
import styles from './styles.module.scss';

import JobAndReceiptTopNav from '../Common/JobAndReceiptTopNav';

const AsyncReceipt = Loadable({
  loader: () => import('Receipts'),
  loading: () => null,
});

const AsyncSettings = Loadable({
  loader: () => import('Settings'),
  loading: () => null,
});

const DummyComponent = () => (<div> <JobAndReceiptTopNav/> </div>)

const AuthApp = withRouter(({ history }) => {
  const whoami = useGetUserInfo();
  const timezone = whoami.timezone || 'America/Toronto';
  useIntercomAuthenticated(whoami, 'operator');
  useIntercomUserUpdate(whoami, 'operator');
  useIntercomHistoryListener(history);

  const language = whoami.language || 'en';
  moment.locale(language);
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <TimezoneContext.Provider value={timezone}>
        <MetadataProvider />
        <DeviceLoggingReconciler authenticated />
        <TopNav />
        <NullableNotificationBanner />
        <FullScreenPortal />
        <div className={styles.main}>
          <Switch>
            <Route path="/jobs" component={Jobs} />
            <Route path="/receipts" component={AsyncReceipt} />
            <Route path="/timesheets" component={Timesheets} />
            <Route path="/settings" component={AsyncSettings} />
            <Redirect to="/jobs" />
          </Switch>
        </div>
        <TabBar />
      </TimezoneContext.Provider>
    </IntlProvider>
  );
});

export default withRefetchRedirect(AuthApp);
// export default AuthApp;
