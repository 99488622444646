import React, { Component } from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";
import { connect } from 'formik';

class GenericError extends Component {
  static contextTypes = {
    formik: PropTypes.object
  };

  static propTypes = {
    name: PropTypes.string.isRequired
  };

  render() {
    const { name, formik } = this.props;
    const errorMessage = formik.errors[name];
    return !!errorMessage ? (
      <div className={styles.genericErrorMessage}>{errorMessage}</div>
    ) : null;
  }
}

export default connect(GenericError);
