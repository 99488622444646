// @flow
import React from 'react';
import { useI18n } from 'internationalization';
import { Formik, Form } from 'formik';
import type { Timesheet } from 'common-ui/types';
import { Button } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import { MimeType } from 'common-ui/Const';
import { FormikTextInput } from 'common-ui/Form';
import { Close } from 'common-ui/Icon';
import TicketZoomer from 'common-ui/Tickets/TicketZoomer';
import FormikUploadToServer from 'components/Common/Upload/FormikUploadToServer';
import useSaveTimesheetImageReference from 'components/Receipts/hooks/useSaveTimesheetImageReference';
import useUpdateTimesheetNumber from '../Hooks/useUpdateTimesheetNumber';
import useDeleteImage from '../Hooks/useDeleteImage';
import styles from './styles.module.scss';

type TimesheetPhotoUploadProps = {
  timesheet: Timesheet,
  onComplete: () => void
};

const TimesheetPhotoUpload = ({
  timesheet,
  onComplete
}: TimesheetPhotoUploadProps) => {
  const intlz = useI18n();
  const saveTimesheetImageReference = useSaveTimesheetImageReference();
  const updateTimesheetNumber = useUpdateTimesheetNumber();
  const deleteImage = useDeleteImage();
  const uploadData =
    timesheet.timesheetAttachment && timesheet.timesheetAttachment.uploadData;
  const companyID =
    timesheet.assignmentInstances &&
    timesheet.assignmentInstances[0].assignment &&
    timesheet.assignmentInstances[0].assignment.project.companyId;

  const { timesheetAttachment, assignmentInstances } = timesheet;

  const handleFormSubmit = (values, formik) => {
    if (values.file) {
      saveTimesheetImageReference(values.file, timesheet.id, `${companyID}`);
    }
    updateTimesheetNumber(timesheet.id, values.timesheetNumber, companyID.toString());
    onComplete();
  };

  return (
    <Formik
      initialValues={{
        data: uploadData || null,
        timesheetNumber: timesheet.timesheetNumber || '',
        file: null
      }}
      isInitialValid
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className={styles.textHeader}>{intlz('Timesheet')}</div>

          <div className={styles.timesheetPhotoUploader}>
            {values.data && values.data.getURL === '' ? (
              <FormikUploadToServer
                onUploaded={value => {
                  const file = value[0] || values.attachments[0];
                  if (file) {
                    setFieldValue('file', file);
                  }
                }}
                name='attachments'
                domain='TIMESHEET'
                uploadId={timesheet.id}
                uploadText={intlz('Add a Photo')}
                multiple={false}
                clearable
                className={styles.taskLink}
              />
            ) : (
              <div className={styles.timesheetPhoto}>
                <TicketZoomer
                  src={
                    (values.file && values.file.getURL) || values.data.getURL
                  }
                  rotation={0}
                  filename={values.data.filename}
                  mimeType={MimeType.Image}
                >
                  <ClickableDiv
                    className={styles.removePhotoButton}
                    onClick={() => {
                      setFieldValue('data', { getURL: '' });
                      deleteImage(timesheetAttachment.id);
                    }}
                  >
                    <Close />
                  </ClickableDiv>
                </TicketZoomer>
              </div>
            )}
          </div>

          <FormikTextInput
            name='timesheetNumber'
            label={intlz('Timesheet Number')}
            placeholder={intlz('ex. 1234')}
          />

          <Button
            styleName={'primary'}
            text={intlz('Add')}
            type='submit'
            fullWidth
            large
          />
        </Form>
      )}
    </Formik>
  );
};

export default TimesheetPhotoUpload;
