import React from 'react';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import { useTimezone } from 'common-ui/Hooks';
import type { Project, Assignment } from 'common-ui/types';
import { formatConfigField } from 'common-ui/Utils/utils';
import { AttachmentsList } from 'Common/AttachmentsViewer';
import { DetailsTable, DetailsRow, SiteDetailsRow } from 'Common/DetailsTable';
import { useI18n } from 'internationalization';
import { formatPayIntlz, formatAssignmentQuantityIntlz } from 'utils/format';
import { getPickUpSite, getPickUpAddress, getDropOffAddress } from 'utils/sites';
import { useForemanAccounts } from 'components/TabBar/gql';
import { default as ConfigurableFields } from 'common-ui/ConfigurableFields';
import { default as useDriverConfigurableFields } from 'Hooks/useDriverConfigurableFields';
import styles from './styles.module.scss';

type JobInfoDetailsProps = {
  project: Project,
  assignment: Assignment,
  hideDirections: boolean,
};

const JobInfoDetails = ({ project, assignment, hideDirections = false }: JobInfoDetailsProps) => {
  const intlz = useI18n();
  const timezone = useTimezone();

  const translatedPay = formatPayIntlz(assignment.haulerRate, assignment.haulerRateUnit, intlz);
  const translatedAssignmentQuantity = formatAssignmentQuantityIntlz(assignment, intlz);

  const pickUpAddress = getPickUpAddress(assignment, project);
  const dropOffAddress = getDropOffAddress(assignment, project);
  const subCompanyName = project.subcontractingCompanyName || assignment.companyName;
  const companyName = subCompanyName || project.companyName;

  const foremanAccounts = useForemanAccounts(`${project.companyId}`);
  const foremanObj = project.foremanID && foremanAccounts.find(f => f.id === project.foremanID)
  const foreman = (foremanObj && `${foremanObj.firstName} ${foremanObj.lastName}`) || project.foreman || '';

  const companyID = assignment.project && assignment.project.companyId ? `${assignment.project.companyId}` : '0';
  const projectID = assignment.project && assignment.project.id ? `${assignment.project.id}` : null;
  const { configurableFields } = useDriverConfigurableFields(companyID, projectID);
  const assignmentConfigurableFields = configurableFields.filter(cf => cf.driverVisible && cf.isAssignmentField);
  const projectConfigurableFields = configurableFields.filter(cf => cf.driverVisible && cf.isProjectField);

  return (
    <DetailsTable>
      {!!assignment.notes && assignment.notes.length > 0 && (
        <div className={styles.notesSection}>
          {assignment.notes}
        </div>
      )}
      {pickUpAddress && (
        <SiteDetailsRow
          label={intlz('Pick Up')}
          site={getPickUpSite(assignment, project)}
          address={pickUpAddress}
          sendStatus={assignment.sendStatus}
          hideDirections={hideDirections}
        />
      )}
      {dropOffAddress && (
        <SiteDetailsRow
          label={intlz('Drop Off')}
          site={assignment.dropOffSite}
          address={dropOffAddress}
          sendStatus={assignment.sendStatus}
          hideDirections={hideDirections}
        />
      )}
      <DetailsRow
        label={intlz('Scheduled Start')}
        value={moment.tz(assignment.startTime, timezone).format('h:mma')}
      />
      {!assignment.hideRateFromAssignee && (
        <DetailsRow
          label={intlz('Rate')}
          value={translatedPay}
        />
      )}
      {!!(assignment.equipmentInfo || assignment.equipment) && (
        <DetailsRow
          label={intlz('Equipment')}
          value={(assignment.equipmentInfo && assignment.equipmentInfo.name) || assignment.equipment}
        />
      )}
      {!!(assignment.productInfo || assignment.product) && (
        <DetailsRow
          label={intlz('Product')}
          value={(assignment.productInfo && assignment.productInfo.name) || assignment.product}
        />
      )}
      {!!assignment.phase && (
        <DetailsRow
          label={intlz('Phase')}
          value={assignment.phase}
        />
      )}
      {!!foreman && (
        <DetailsRow
          label={intlz('Foreman')}
          value={foreman}
        />
      )}
      {!!project.jobNumber && (
        <DetailsRow
          label={intlz('Job No.')}
          value={project.jobNumber}
        />
      )}
      <DetailsRow
        label={intlz('Assigned By')}
        value={companyName}
      />
      <DetailsRow
        label={intlz('Client')}
        value={assignment.client || intlz('Self-Assigned')}
      />
      {!!assignment.quantityUnit && !!assignment.quantity && (
        <DetailsRow
          label={intlz('Quantity')}
          value={translatedAssignmentQuantity}
        />
      )}
      {!!project.type && (
        <DetailsRow
          label={intlz('Type')}
          value={capitalize(project.type)}
        />
      )}
      {!!project.attachments && project.attachments.length > 0 && (
        <DetailsRow
          label={intlz('Project Attachments')}
          value={<AttachmentsList attachments={project.attachments} />}

        />
      )}

      {/* Assignment level configurable fields */}
      {!!assignment.configurableFields && assignment.configurableFields.length > 0 && (
        assignment.configurableFields.map(cf => configFieldRow(assignmentConfigurableFields, cf, timezone))
      )}

      {/* Project level configurable fields */}
      {!!project.configurableFields && project.configurableFields.length > 0 && (
        project.configurableFields.map(cf => configFieldRow(projectConfigurableFields, cf, timezone))
      )}
    </DetailsTable>
  );
};

const configFieldRow = (fields, fieldValue, timezone) => {
  const fieldDetails = fields.find(f => `${fieldValue.id}` === f.id);
  const isFieldVisible = fieldValue.value && fieldDetails && fieldDetails.name;

  return (isFieldVisible && (
    <DetailsRow
      key={fieldDetails.name}
      label={fieldDetails.name}
      value={formatConfigField(fieldDetails, fieldValue.value, timezone)}
    />
  ));
}

export default JobInfoDetails;
