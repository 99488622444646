import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client/react';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import { withRouter } from 'react-router-dom';

import { SetHistory, Client } from './client';

const RouterAwareApolloProvider = ({ children, history }) => {
  SetHistory(history);
  return (
    <ApolloProvider client={Client}>
      <ApolloHooksProvider client={Client}>
        {children}
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

RouterAwareApolloProvider.propTypes = {
  history: PropTypes.shape().isRequired,
  children: PropTypes.node,
};

RouterAwareApolloProvider.defaultProps = {
  children: null,
};

export default withRouter(RouterAwareApolloProvider);
