// @flow
import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

const UPLOAD_RUN_OCR_MUTATION = gql`
  mutation uploadAndRunOCR($file: Upload!, $input: UploadInput!, $instanceId: ID!) {
    uploadAndRunOCR(file: $file, input: $input, instanceId: $instanceId) {
      data {
        filename
        thumbnailURL
        getURL
        mimeType
      }
      results {
        ticketNumber
        quantity
        gross
        tare
        ocrData
      }
    }
  }
`;

export const useUploadAndRunOCR = (instanceId: string) => {
  const [uploadOCR] = useMutation(UPLOAD_RUN_OCR_MUTATION);
  return useCallback((file: File) => (
    uploadOCR({
      variables: {
        file,
        input: {
          id: parseInt(instanceId, 10),
          domain: 'INSTANCE',
        },
        instanceId,
      },
    }).then(({ data }) => data.uploadAndRunOCR)
  ), [uploadOCR, instanceId]);
};

const UPLOAD_ATTACHMENT_MUTATION = gql`
  mutation uploadAttachment($file: Upload!, $input: UploadInput!) {
    uploadAttachment(file: $file, input: $input) {
      filename
      thumbnailURL
      getURL
      mimeType
    }
  }
`;

export const useAttachmentUpload = (input: { id: string, domain: string }) => {
  const [uploadAttachment] = useMutation(UPLOAD_ATTACHMENT_MUTATION);
  return useCallback((file: File) => (
    uploadAttachment({
      variables: {
        file,
        input: {
          ...input,
          id: input.id ? parseInt(input.id, 10) : 0,
        },
      },
    }).then(({ data }) => data.uploadAttachment)
  ), [uploadAttachment, input]);
};

export default useUploadAndRunOCR;
