// @flow
export const Steps = {
  newTicket: 'newTicket',
  editTicket: 'editTicket',
  getSignature: 'getSignature',
  addSignature: 'addSignature',
  confirmation: 'confirmation',
};

export default Steps;
