// @flow
import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { TIMESHEET_QUERY } from './useTimesheet';

const UPDATE_TIMESHEET_NUMBER_MUTATION = gql`
mutation updateTimesheetNumber($timesheetID:ID!, $companyID:ID!, $timesheetNumber:String) {
  updateTimesheetNumber(timesheetID:$timesheetID, companyID:$companyID, timesheetNumber:$timesheetNumber) {
    id
    timesheetNumber
  }
}
`;

const useUpdateTimesheetNumber = () => {
  const [updateTimesheetNumber] = useMutation(UPDATE_TIMESHEET_NUMBER_MUTATION);

  return useCallback((
    timesheetID: string,
    timesheetNumber: string,
    companyID: string,
  ) => (
    updateTimesheetNumber({
      variables: {
        timesheetID,
        companyID,
        timesheetNumber,
      },
      refetchQueries: [{
        query: TIMESHEET_QUERY,
        variables: {
          instanceID: '',
          companyID,
          timesheetID,
        },
      }],
    })
  ), [updateTimesheetNumber]);
};

export default useUpdateTimesheetNumber;
