import axios from 'axios';

const APIAUTHURL = process.env.REACT_APP_API_AUTH_URL;

export const post = (url, data) => (
  axios.post(url, data, {
    baseURL: APIAUTHURL,
    withCredentials: true,
  })
);
