import React from 'react';

type PaginationNextPropTypes = {
  viewBox?: string,
  height?: number,
  width?: number,
};

const PaginationNext = (props: PaginationNextPropTypes) => {
  const { viewBox, width, height } = props;
    return (
        <svg width={width-1} height={height-1} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width={width-1} height={height-1} rx="15.5" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6674 8.71502C12.3829 8.44023 12.2688 8.03331 12.3689 7.65066C12.4691 7.268 12.7679 6.96917 13.1506 6.86901C13.5333 6.76885 13.9402 6.88295 14.215 7.16746L21.8761 14.8286C22.3034 15.256 22.3034 15.9488 21.8761 16.3762L14.215 24.0374C13.7855 24.4522 13.1029 24.4462 12.6807 24.0241C12.2586 23.6019 12.2526 22.9193 12.6674 22.4898L19.5548 15.6024L12.6674 8.71502Z" fill="#4A4A4A"/>
            <rect x="0.5" y="0.5" width={width-1} height={height-1} rx="15.5" stroke="#C3C3C3"/>
        </svg>
    );
};

PaginationNext.defaultProps = {
    viewBox: '0 0 32 32',
    width: 32,
    height: 32,
};

export default PaginationNext;
