import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

// Components
import JobsOverview from './JobsOverview';
import JobsDetail from './JobsDetail';

const Jobs = () => (
  <Switch>
    <Route exact path="/jobs" component={JobsOverview} />
    <Route exact path="/jobs/:id" component={JobsDetail} />
    <Redirect to="/jobs" />
  </Switch>
);

export default Jobs;
