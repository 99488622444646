// @flow
import React from 'react';
import ClickableDiv from 'common-ui/ClickableDiv';
import styles from './styles.module.scss';

type SpeechBubbleProps = {
  text: string,
  onClick?: () => void,
};

const SpeechBubble = ({ text, onClick }: SpeechBubbleProps) => {
  if (!text) return null;
  return (
    <ClickableDiv className={styles.speechBubble} onClick={onClick}>
      {text}
    </ClickableDiv>
  );
};

SpeechBubble.defaultProps = {
  onClick: () => {},
};

export default SpeechBubble;
