export const PENDING = 'PENDING';
export const ACCEPTED = 'ACCEPTED';
export const LOGGING = 'LOGGING';
export const PAUSED = 'PAUSED';
export const DONE = 'DONE';
export const ENDED = 'ENDED';
export const TERMINATED = 'TERMINATED';

export const DECLINED = 'DECLINED';
export const EXPIRED = 'EXPIRED';
export const REASSIGNED = 'REASSIGNED';
export const CANCELLED = 'CANCELLED';
export const REJECTED = 'REJECTED';
export const COMPLETE = 'COMPLETE';
