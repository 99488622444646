/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Thumbnail from './components/Thumbnail';

// Components
import styles from './styles.module.scss';

type ReadOnlyUIProps = {
  thumbnail: string,
  uploadCount: string | number,
  orientation?: string | number,
  includeThumbnail? : boolean,
  uploadCount?: string | number,
  includeFileList?: boolean,
  value: Array<mixed> | mixed,
  multiple?: boolean,
};

// UI for read only version of Upload
const ReadOnlyUI = ({
  thumbnail,
  includeThumbnail,
  includeFileList,
  multiple,
  orientation,
  uploadCount,
  value,
}: ReadOnlyUIProps) => (
  <div className={styles.inputFileUpload}>
    <div className={styles.fileUpload}>
      { includeThumbnail
        ? (
          <Thumbnail
            {...{
              thumbnail,
              orientation,
              uploadCount,
              multiple,
            }}
          />
        )
        : null}
    </div>
  </div>
);

ReadOnlyUI.defaultProps = {
  orientation: null,
  includeThumbnail: false,
  uploadCount: 0,
  includeFileList: false,
  multiple: false,
};

export default ReadOnlyUI;
