import React from 'react';
import {
  Play, Bell, Pause, Check,
} from 'common-ui/Icon';
import { Pill, Tag } from 'common-ui/Labels';
import { useI18n } from 'internationalization';
import styles from './styles.module.scss';

const AssignmentTag = ({ sendStatus, schedule, isFeatured, assignedStatus, instanceStatus }) => {
  const intlz = useI18n();

  if (assignedStatus === 'AUTO') {
    return <Tag text={intlz('Manual Entry')} className={styles.done} />;
  }

  switch (sendStatus) {
    case 'LOGGING':
      return <Tag text={intlz('Live')} className={styles.logging} leftIcon={<Play />} />;
    case 'PAUSED':
      return <Tag text={intlz('Paused')} className={styles.paused} leftIcon={<Pause />} />;
    case 'DONE':
      return <Tag text={intlz('Done')} className={styles.done} leftIcon={<Check />} />;
    case 'TERMINATED':
      if(instanceStatus === 'ENDED') {
        return <Tag text={intlz('Ended')} className={styles.done} />;
      }
      return null;
    case 'PENDING':
      if (schedule) {
        return <Pill text={intlz('Loads')} value={schedule.assignments.length} />;
      }
      return <Tag text={intlz('New')} className={styles.new} leftIcon={<Bell />} />;
    case 'ACCEPTED':
      if (isFeatured) {
        return <Tag text={intlz('Next')} className={styles.next} />;
      }
      return null;
    default:
      return null;
  }
};

export default AssignmentTag;
