// @flow
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { updateCacheObject } from 'apollo/utils';

const SIGNOFF_MUTATION = gql`
  mutation createSignoff($file: Upload!, $uploadInput: UploadInput!, $input:SignoffInput!) {
    createSignoff(file: $file, uploadInput: $uploadInput, input: $input) {
      id
      signoffName
      signoffDate
      data {
        getURL
        filename
      }
      deleted
    }
  }
`;

const SIGNOFFS_FRAGMENT = gql`
  fragment signoffs on Ticket {
    __typename
    id
    signoffs {
      __typename
      id
      deleted
    }
  }
`;

export const useCreateSignoff = (instanceId: string, instanceAttachmentId: string) => {
  const [createSignoff] = useMutation(SIGNOFF_MUTATION);
  return useCallback((file: File, signoffName: string = '') => (
    createSignoff({
      variables: {
        file,
        uploadInput: {
          domain: 'SIGNOFF',
          id: parseInt(instanceId, 10),
        },
        input: {
          signoffName,
          instanceID: parseInt(instanceId, 10),
          instanceAttachmentID: parseInt(instanceAttachmentId, 10),
        },
      },
      update: updateCacheObject(
        'Ticket',
        instanceAttachmentId,
        SIGNOFFS_FRAGMENT,
        (oldTicket, { createSignoff: newSignoff }) => ({
          ...oldTicket,
          signoffs: [...oldTicket.signoffs.filter((so) => !so.deleted), newSignoff],
        }),
      ),
    }).then(({ data }) => data.createSignoff)
  ), [createSignoff, instanceId, instanceAttachmentId]);
};

export default useCreateSignoff;
