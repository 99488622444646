/* eslint-disable no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable brace-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
// @flow
import React, {
  type Node, useRef, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import moment from 'moment';
import { ChevronLeft } from '../../Icon';
import styles from './styles.module.scss';
/**
 * This is a custom Option element in react-select so that menu values
 * will scroll into view
 * @param {any} props
 */
const ScrollIntoViewOption = (props) => {
  const ref = useRef();
  const { isSelected } = props;
  const scrollIntoView = () => { // $FlowFixMe, scrollIntoView() warning
    ref.current.scrollIntoView({ behavior: 'auto', block: 'center' });
  };


  useEffect(() => {
    if (isSelected) {
      scrollIntoView();
    }
  }, [isSelected]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props} innerRef={ref} />
  );
};


type Option = { label: Node, value: string };

type DropdownCreatableProps = {
  options: Option[],
  onChange: (newOption: ?string) => void,
  value: ?string,
  embedded?: boolean,
  id?: string,
  // only available when canCreateOption is true, adds AM and PM as options if the user inputs time
  // without it
  isTimeInput?: boolean,
  isDurationInput?: boolean,
};

/**
 * This component is a Dropdown selection which allows the user to create their own option
 * as needed.
 */
const DropdownCreatable = ({
  options,
  onChange,
  value,
  embedded,
  id,
  isTimeInput,
  isDurationInput,
  disabled,
  ...inputProps
}: DropdownCreatableProps) => {
  // The default start or end time could be non intervals of 5 thus requiring sorting
  const optionsSorted = isTimeInput ? sortDateDropDownOptions(options) : options;
  const [optionsList, setOptionsList] = useState(optionsSorted);
  return (
    // $FlowFixMe, flow errors here for missing props even though it is here.
    <Creatable
      maxMenuHeight={280}
      onChange={(newOption) => {
        onChange(newOption ? newOption.value : null);
      }}
      value={options.filter(({ value: v }) => v === value)}
      formatCreateLabel={(inputValue) => inputValue}
      allowCreateWhileLoading={false}
      isDisabled={disabled}
      noOptionsMessage={() => 'Invalid time'}
      getNewOptionData={
        (inputValue) => {
          if (isTimeInput) {
            autoSuggestTimePeriod(options, inputValue, setOptionsList);
          }
          if (isDurationInput) {
            autoSuggestDuration(options, inputValue, setOptionsList);
          }
          else {
            options.push({ value: inputValue, label: inputValue });
          }
        }
      }
      isValidNewOption={(inputValue) => options.filter((option) => option === inputValue).length === 0}
      id={id}
      classNamePrefix="react-select"
      options={optionsList}
      styles={{
        control: (provided, state) => ({
          ...provided,
          border: embedded && !state.isFocused ? '0 !important' : 'inherit',
          height: embedded ? '100%' : 'inherit',
          backgroundColor: embedded && !state.isFocused ? 'transparent' : provided.backgroundColor,
          minHeight: '40px',
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          textAlign: 'left',
        }),
        container: (provided) => ({
          ...provided,
          height: embedded ? '100%' : 'inherit',
        }),
      }}
      components={{
        // Commenting this since this pushes the other divs up.
        
        // Option: ScrollIntoViewOption,
        IndicatorSeparator: null,
        DropdownIndicator: ({
          // innerProps and selectProps come from react-select library
          innerProps, // eslint-disable-line
          selectProps, // eslint-disable-line
        }) => (
          <div
            className={classNames(styles.dropdownIndicator, {
              [styles.open]: selectProps.menuIsOpen,
            })}
            {...innerProps}
          >
            <ChevronLeft />
          </div>
        ),
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{
        ...inputProps,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: styles.colorSelected,
          primary25: styles.colorSelectedLight,
          primary50: styles.colorSelectedMedium,
        },
      })}

    />
  );
};

DropdownCreatable.defaultProps = {
  embedded: false,
  id: undefined,
  isTimeInput: false,
  isDurationInput: false,
};

const isUnique = (
  options: Option[], inputValue,
): boolean => options.filter((o) => o.value === inputValue).length === 0;

const isValidDateString = (stringInput: string): boolean => {
  if (stringInput.includes('AM') || stringInput.includes('PM')) {
    return moment(stringInput, 'LT', true).isValid();
  }
  return false;
};

/**
 * Function splits up the AM and PM then sorts the values
 * @param {DropdownInputType[]} options
 */
export const sortDateDropDownOptions = (
  options: Option[],
): Option[] => [
  ...sortDate(options.filter((o) => o.value.includes('AM'))),
  ...sortDate(options.filter((o) => o.value.includes('PM'))),
];

/**
 * Function sorts the given list of data according to their values
 * @param {Options[]} options
 */
const sortDate = (
  options: Option[],
): Option[] => [...options].sort((a, b) => new Date(`1970/01/01 ${a.value}`) - new Date(`1970/01/01 ${b.value}`));

/**
 * Function mutates the option list if the user inputs a valid time string but is missing AM/PM.
 * It makes so that AM and PM appear as suggested options for the user
 *
 * @param {{ label: Node, value: string }[]} options
 * @param {string} inputValue
 */
const autoSuggestTimePeriod = (
  options: Option[],
  inputValue: string,
  setOptionsList: any, // this is a react state hook
) => {
  const newValue = { value: inputValue, label: inputValue };
  if (inputValue && isUnique(options, inputValue)) {
    // if the input is valid and unique then we will add it to the list
    if (isValidDateString(inputValue)) {
      options.push(newValue);
      setOptionsList(sortDateDropDownOptions(options));
    }
    // if we're just missing AM/PM then we'll give these as options so the user does not have to
    // type it out
    else if (isValidDateString(`${inputValue} AM`) && isUnique(options, `${inputValue} AM`)) {
      options.push({ value: `${inputValue} AM`, label: `${inputValue} AM` });
      options.push({ value: `${inputValue} PM`, label: `${inputValue} PM` });
      setOptionsList(sortDateDropDownOptions(options));
    }
  }
};

export const formatDuration = (time) => moment.duration(time, 'minutes').format('h[h] mm[m]');

const sortDurationDropdownOptions = (values) => values.sort((a, b) => a.value - b.value);

/**
 * Function suggests duration options between 00m and 4h 00m
 */
const autoSuggestDuration = (
  options: Option[],
  inputValue: string,
  setOptionsList: any,
) => {
  const [hour, minValue] = inputValue.trim().split('h');

  // Expect a user to input new time in the format 2h 30m - if not the option will not be added
  if (hour && parseInt(hour, 10)) {
    let newMinutes = 0;
    if (!minValue) {
      newMinutes = parseInt(hour * 60, 10);
    } else {
      const [minute] = minValue.trim().split('m');
      if (parseInt(minute, 10) <= 59) {
        newMinutes = parseInt(hour * 60, 10) + parseInt(minute, 10);
      }
    }
    const formattedMinutes = formatDuration(newMinutes);
    if (isUnique(options, newMinutes)) {
      options.push({ label: formattedMinutes, value: newMinutes });
    }
    setOptionsList(sortDurationDropdownOptions(options));
  }
};

export default DropdownCreatable;
