// @flow
import { useState, useEffect } from 'react';
import { assignWith } from 'lodash';
import type { Formik } from 'formik';
import type { OCRResults } from '../types';

// When the new OCRResults come in, we want to overwrite any fields that the
// user hasn't explicitly modified
// This hook returns a function that you call when you receive the ocr results
// in order to properly set the formik values to what they should be.
// This hook is necessary to make sure we don't overwrite anything the user
// wrote while the OCR was running
const useFormikOCR = (formik: Formik) => {
  const [ocrResults, setOcrResults] = useState<OCRResults | null>(null);
  useEffect(() => {
    if (formik && ocrResults) {
      const {
        values, state: { values: stateValues } = {}, setValues, initialValues, setFieldValue,
      } = formik;
      // Formik object can have two different formats for getting the values object
      const formikValues = stateValues || values;
      if (ocrResults.data) {
        // Results came from OCR
        setValues(assignWith({
          ...formikValues,
          data: ocrResults.data,
        },
        ocrResults.results,
        (src, dest, key) => (initialValues[key] !== formikValues[key] ? src : dest)));
      } else {
        // Results came from attachment upload
        setFieldValue('data', ocrResults);
      }
      setOcrResults(null);
    }
  }, [ocrResults, formik]);
  return setOcrResults;
};

export default useFormikOCR;
