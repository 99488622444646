import { useCallback } from 'react';
import { SET_NOTIFICATION } from 'apollo';
import { useMutation } from '@apollo/client';

const useNotify = () => {
  const [notify] = useMutation(SET_NOTIFICATION);
  return useCallback((notification) => notify(notification), [notify]);
};

export default useNotify;
