import React, { Component, Fragment } from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";

class CheckboxUI extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string, PropTypes.number]).isRequired,
  };

  render() {
    const { name, className, label, toggle, value, touched, error, shouldShowError, handleChange, handleBlur, ...props } = this.props;

    // form states
    return (
      <Fragment>
        <div className={!!toggle ? styles.inputToggle : styles.inputCheckbox}>
          <div className={styles.check}>
            <input
              name={name}
              type="checkbox"
              checked={value}
              onChange={handleChange}
              onBlur={handleBlur}
              {...props}
            />
            <div className={styles.item} />
          </div>
          {!!label && <span className={styles.label}>{label}</span>}
        </div>
        {shouldShowError && (
          <div className={styles.genericErrorMessage}>{error}</div>
        )}
      </Fragment>
    );
  }
}

export default CheckboxUI;
