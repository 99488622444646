// @flow
import React from 'react';
import classNames from 'classnames';
import Check from './Check';
import styles from './styles.module.scss';

type CircleCheckProps = {
  className: string
}

const CircleCheck = ({ className }: CircleCheckProps) => (
  <Check className={classNames(className, styles.circleCheck)} />
);

export default CircleCheck;
