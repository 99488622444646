import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Button = ({
  className,
  leftIcon,
  rightIcon,
  label,
  hideLabel,
  primary,
  secondary,
  secondaryFill,
  newTicket,
  directionsFill,
  alert,
  alertFill,
  blueAlert,
  tiny,
  back,
  fullWidth,
  pad,
  stopLog,
  thin,
  logging,
  toggle,
  toggleOn,
  type,
  pulse,
  disabled,
  shadow,
  id,
  ...rest
}) => (
  <button
    id={id}
    type={type}
    disabled={disabled || false}
    className={`${
      styles[
        buttonClass({
          primary,
          secondary,
          secondaryFill,
          newTicket,
          directionsFill,
          alert,
          alertFill,
          blueAlert,
          stopLog,
          tiny,
          toggle,
          disabled,
          back,
        })
      ]
    }  ${pulse ? styles.pulse : ''}  ${shadow ? styles.shadow : ''}  ${
      fullWidth ? styles.fullWidth : ''
    } ${hideLabel ? styles.hideLabel : ''} ${
      toggleOn ? styles.toggleActive : ''
    } ${pad ? styles.buttonPad : ''} ${className || ''} ${
      disabled ? styles.disabled : ''
    }  ${thin ? styles.thin : ''} ${logging ? styles.logging : ''}
    `}
    {...rest}
  >
    <div className={styles.wrapper}>
      {!!leftIcon && <div className={styles.icon}>{leftIcon}</div>}
      {!!label && <div className={styles.label}>{label}</div>}
      {!!rightIcon && <div className={styles.icon}>{rightIcon}</div>}
    </div>
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']).isRequired,
};

Button.defaultProps = {
  type: 'button',
  id: undefined,
};

const buttonClass = ({
  primary,
  secondary,
  secondaryFill,
  newTicket,
  directionsFill,
  alert,
  alertFill,
  blueAlert,
  stopLog,
  tiny,
  toggle,
  disabled,
  back,
}) => {
  if (primary) {
    return `button${tiny ? 'Tiny' : ''}Primary`;
  } if (secondary) {
    return `button${tiny ? 'Tiny' : ''}Secondary`;
  } if (secondaryFill) {
    return `button${tiny ? 'Tiny' : ''}SecondaryFill`;
  } if (newTicket) {
    return `button${tiny ? 'Tiny' : ''}NewTicket`;
  } if (directionsFill) {
    return `button${tiny ? 'Tiny' : ''}DirectionsFill`;
  } if (alert) {
    return `button${tiny ? 'Tiny' : ''}Alert`;
  } if (alertFill) {
    return `button${tiny ? 'Tiny' : ''}AlertFill`;
  } if (blueAlert) {
    return `button${tiny ? 'Tiny' : ''}BlueAlert`;
  } if (stopLog) {
    return `button${tiny ? 'Tiny' : ''}StopLog`;
  } if (toggle) {
    return `button${tiny ? 'Tiny' : ''}Toggle`;
  } if (disabled) {
    return `button${tiny ? 'Tiny' : ''}Disabled`;
  } if (back) {
    return `button${tiny ? 'Tiny' : ''}Back`;
  }
  return '';
};

export default Button;
