// @flow
import React from 'react';
import moment from 'moment-timezone';
import { useTimezone } from 'common-ui/Hooks';
import { Pill } from 'common-ui/Labels';
import AssignmentTag from 'components/Common/AssignmentTag';
import { useI18n } from 'internationalization';
import { PENDING } from 'utils/constants/job';
import classNames from 'classnames';
import { type Assignment, type Project } from 'common-ui/types';
import DateDisplay from './DateDisplay';
import TimeTag from './TimeTag';
import styles from './styles.module.scss';

type JobsHeaderProps = {
  assignment: Assignment,
  project: Project,
  instanceStatus?: string,
  className?: string,
};

const JobsHeader = ({
  assignment,
  project,
  instanceStatus,
  className,
}: JobsHeaderProps) => {
  const {
    schedule,
    sendStatus,
    startTime,
    assignedStatus,
  } = assignment;

  const intlz = useI18n();
  const timezone = useTimezone();
  const byPickupArrival = (a, b) => moment.tz(
    a.pickupArrival, timezone,
  ).isAfter(moment.tz(b.pickupArrival, timezone));
  if (schedule && sendStatus === PENDING) {
    const assignments = schedule.assignments.sort(byPickupArrival);
    const start = moment.tz(assignments[0].pickupArrival, timezone);
    const end = moment.tz(assignments[assignments.length - 1].dropoffDeparture, timezone);

    return (
      <div className={classNames(styles.jobsHeader, className)}>
        <DateDisplay date={startTime} />
        <div className={styles.jobsHeaderInfo}>
          <span className={styles.clientName}>{project.companyName}</span>
          <div className={styles.scheduleInfo}>
            <TimeTag startTime={start} endTime={end} />
            <Pill value={assignments.length} text={intlz('Loads')} />
          </div>
        </div>
      </div>
    );
  }
  const projectName = project.name || `${project.companyName} (${intlz('Self-Assigned')})`;

  return (
    <>
    <div className={classNames(styles.jobsHeader, className)}>
      <DateDisplay date={startTime} />
      <div className={styles.jobsHeaderInfo}>
        <div className={styles.assignmentTags}>
          <div>
            <TimeTag startTime={startTime} />
          </div>
          <div>
            <AssignmentTag sendStatus={sendStatus} assignedStatus={assignedStatus} instanceStatus={instanceStatus}/>
          </div>
        </div>
        <div className={styles.clientName}>
          {projectName}
        </div>
      </div>
    </div>
      {assignedStatus === 'AUTO' && (
        <div className={styles.autoProject}>{intlz('Note: this project was manually entered by the company.')}</div>
      )}
    </>
  );
};

JobsHeader.defaultProps = {
  className: undefined,
  instanceStatus: '',
};

export default JobsHeader;
