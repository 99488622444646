// @flow
import React from 'react';
import moment from 'moment';
import { useTimezone } from 'common-ui/Hooks';
import { connect } from 'formik';
import { FormField } from 'common-ui/Form';
import MobileTimePicker from '../MobileTimePicker';

type FormikMobileTimePickerProps = {
  formik: any,
  label: string,
  required?: boolean,
  name: string,
  error?: string,
  placeholder?: string,
  id?: string,
  readOnly?: boolean,
  readOnlyValue?: string,
  hideError?: boolean,
  warnings?: any,
  showWarning?: boolean,
}

const FormikMobileTimePicker = ({
  formik,
  label,
  required,
  name,
  error,
  placeholder,
  id,
  readOnly,
  readOnlyValue,
  hideError,
  showWarning,
  warnings,
}: FormikMobileTimePickerProps) => {
  const timezone = useTimezone();
  const readOnlyTime = formik.values[name] && formik.values[name] !== ''
    ? moment.tz(formik.values[name], timezone).format('h:mma')
    : '-';
  return (
    <FormField
      label={label}
      required={required}
      error={error || (formik.touched[name] && formik.errors[name])}
      hideError={hideError}
      warning={warnings && (warnings[name])}
      showWarning={showWarning}
    >
      {readOnly ? (
        <div>
          {readOnlyValue || readOnlyTime}
        </div>
      ) : (
        <MobileTimePicker
          value={formik.values[name]}
          onChange={(value) => {
            formik.setFieldValue(name, value);
            if (!showWarning && !formik.touched[name]) {
              formik.setFieldTouched(name);
            }
          }}
          placeholder={placeholder}
          id={id}
        />
      )}
    </FormField>
  );
};

FormikMobileTimePicker.defaultProps = {
  required: false,
  error: '',
  placeholder: '',
  id: undefined,
  readOnly: false,
  readOnlyValue: '',
  hideError: false,
  showWarning: false,
  warnings: {},
};

export default connect(FormikMobileTimePicker);
