import React from 'react';

const Sort = props => (
  <svg
    width="21px"
    height="16px"
    viewBox="0 0 21 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Sort</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icon/Filter" transform="translate(-2.000000, -5.000000)">
        <g id="Group" transform="translate(2.000000, 5.000000)">
          <g id="Colors/Black">
            <path
              d="M1.6,12.8 L8.09230769,12.8 C8.97596329,12.8 9.69230769,13.5163444 9.69230769,14.4 C9.69230769,15.2836556 8.97596329,16 8.09230769,16 L1.6,16 C0.7163444,16 1.082166e-16,15.2836556 0,14.4 C-1.082166e-16,13.5163444 0.7163444,12.8 1.6,12.8 Z M1.6,6.4 L16.1692308,6.4 C17.0528864,6.4 17.7692308,7.1163444 17.7692308,8 C17.7692308,8.8836556 17.0528864,9.6 16.1692308,9.6 L1.6,9.6 C0.7163444,9.6 1.082166e-16,8.8836556 0,8 C-1.082166e-16,7.1163444 0.7163444,6.4 1.6,6.4 Z M1.6,0 L19.4,-1.11022302e-15 C20.2836556,-1.27254792e-15 21,0.7163444 21,1.6 C21,2.4836556 20.2836556,3.2 19.4,3.2 L1.6,3.2 C0.7163444,3.2 1.082166e-16,2.4836556 0,1.6 C-1.082166e-16,0.7163444 0.7163444,3.84369505e-16 1.6,2.22044605e-16 Z"
              id="path-1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Sort;
