// @flow
import { useRef, useCallback } from 'react';
import { xorWith, isEqual } from 'lodash';
import usePageContext from '../Hooks/usePageContext';

export const GAEvents = {
  buttonClick: 'buttonClick',
  fieldTiming: 'fieldTiming',
  formTiming: 'formTiming',
  filterUpdate: 'filterUpdate',
  login: 'login',
  formChange: 'formChange',
  dragAndDrop: 'dragAndDrop',
  sortUpdate: 'sortUpdate',
};

type GAEvent = $Values<typeof GAEvents>;

const GAOperatorActions = {
  newJobLog: 'newJobLog',
  requestOpen: 'requestOpen',
  jobNew: 'jobNew',
  jobFuture: 'jobFuture',
  jobLogging: 'jobLogging',
  jobPaused: 'jobPaused',
  help: 'help',
  settings: 'settings',
  receipts: 'receipts',
  backRequest: 'backRequest',
  backJob: 'backJob',
  backReceipt: 'backReceipt',
  backSettings: 'backSettings',
  requestDirections: 'requestDirections',
  jobDirections: 'jobDirections',
  acceptJob: 'acceptJob',
  declineJob: 'declineJob',
  addAttachments: 'addAttachments',
  jobNotes: 'jobNotes',
  newTicket: 'newTicket',
  startLogging: 'startLogging',
  stopLogging: 'stopLogging',
  resumeLogging: 'resumeLogging',
  editNotesAndAttachments: 'editNotesAndAttachments',
  settingsFirstName: 'settingsFirstName',
  settingsLastName: 'settingsLastName',
  settingsEmail: 'settingsEmail',
  updateSettings: 'updateSettings',
  settingsProfileUpload: 'settingsProfileUpload',
  settingsLanguage: 'settingsLanguage',
  notificationRequestReminder: 'notificationRequestReminder',
  notificationRequestReminderHighPriority: 'notificationRequestReminderHighPriority',
  notificationAssignmentReminder: 'notificationAssignmentReminder',
  notificationAssignmentReminderHighPriority: 'notificationAssignmentReminderHighPriority',
  notificationTicketNotification: 'notificationTicketNotification',
  notificationLoggingToggle: 'notificationLoggingToggle',
  notificationReconnectToggle: 'notificationReconnectToggle',
  notificationUpdate: 'notificationUpdate',
  replay: 'replay',
};

const GADispatcherActions = {
  newProjectClick: 'newProjectClick',
  editProjectClick: 'editProjectClick',
  viewProjectClick: 'viewProjectClick',
  copyProjectClick: 'copyProjectClick',
  copyAllProjectClick: 'copyAllProjectClick',
  copyRequestClick: 'copyRequestClick',
  newSmartProjectClick: 'newSmartProjectClick',
  sidebarToggleClick: 'sidebarToggleClick',
  filterChange: 'filterChange',
  sortChange: 'sortChange',
  filterUpdate: 'filterUpdate',
  removeHireClick: 'removeHireClick',
  removeHireConfirmClick: 'removeHireConfirmClick',
  cancelAssignmentsClick: 'cancelAssignmentsClick',
  cancelAssignmentsConfirmClick: 'cancelAssignmentsConfirmClick',
  addAssignmentClick: 'addAssignmentClick',
  selectAllProjectsClick: 'selectAllProjectsClick',
  editAssignmentClick: 'editAssignmentClick',
  hiresListAllClick: 'hiresListAllClick',
  createNewProject: 'createNewProject',
  updateProject: 'updateProject',
  openSubcontractorModalClick: 'openSubcontractorModalClick',
  createNewSmartProject: 'createNewSmartProject',
  updateAssignment: 'updateAssignment',
  bulkAssignDriversModalClick: 'Bulk Assign',
  startLogin: 'startLogin',
  startVerifyCode: 'startVerifyCode',
  loginSuccess: 'loginSuccess',
  loginFailure: 'loginFailure',
  projectsDownload: 'projectsDownload',
  historyDownload: 'historyDownload',
  bulkEditProjectsClick: 'bulkEditProjectsClick',
  historyDownloadClick: 'historyDownloadClick',
  searchHistoryDownloadClick: 'searchHistoryDownloadClick',
  searchDispatchDownloadClick: 'searchDispatchDownloadClick',
  viewActivityStream: 'viewActivityStream',
  viewAssignmentActivityStream: 'viewAssignmentActivityStream',
  selectAllTicketsClick: 'selectAllTicketsClick',
  downloadTicketClick: 'downloadTicketClick',
  removeSignoffClick: 'removeSignoffClick',
  ticketSignoffClick: 'ticketSignoffClick',
  removeTicketClick: 'removeTicketClick',
  detachAttachment: 'detachAttachment',
  acceptEstimatedDuration: 'acceptEstimatedDuration',
  declineEstimatedDuration: 'declineEstimatedDuration',
  viewDriverRoute: 'viewDriverRoute',
  viewDriverRouteSpeed: 'viewDriverRouteSpeed',
  dispatchViewScheduleClick: 'dispatchViewScheduleClick',
  scheduleViewApplyFilterClick: 'scheduleViewApplyFilterClick',
  scheduleViewDragAndDropEdit: 'scheduleViewDragAndDropEdit',
  scheduleViewEditAssignmentClick: 'scheduleViewEditAssignmentClick',
};

export const GAActions = {
  ...GADispatcherActions,
  ...GAOperatorActions,
};

type GAAction = $Values<typeof GAActions>;

export const GAController = {};

GAController.trackClick = (action: GAAction, label?: string) => {
  window.dataLayer.push({
    event: GAEvents.buttonClick,
    action,
    label: label || null,
  });
};

GAController.pushEvent = (event: GAEvent, action: GAAction, label?: string) => {
  window.dataLayer.push({
    event,
    action,
    label: label || null,
  });
};

GAController.startTiming = (action: GAAction) => {
  window.dataLayer.push({
    [`${action}Started`]: Date.now(),
  });
};

GAController.stopTiming = (category: string, action: GAAction) => {
  window.dataLayer.push({
    event: GAEvents.formTiming,
    timingCategory: category,
    timingVariable: action,
    timingValue: 0, // Timing is retrieved using previously pushed data layer variables
  });
};

GAController.stopTimingWithValue = (category: string, action: GAAction, elapsedTime: number) => {
  if (elapsedTime && elapsedTime > 0 && elapsedTime < 1800000) {
    window.dataLayer.push({
      event: GAEvents.formTiming,
      timingCategory: category,
      timingVariable: action,
      timingValue: elapsedTime,
    });
  }
};

export const useTrackForm = (formName: string) => {
  const fieldTimes = useRef({});

  const onFocus = (fieldName: string) => {
    const fieldTimesMap = fieldTimes.current || {};
    if (fieldName && !fieldTimesMap[fieldName]) fieldTimesMap[fieldName] = Date.now();
    fieldTimes.current = fieldTimesMap;
  };

  const onBlur = (fieldName: string) => {
    const fieldTimesMap = fieldTimes.current || {};
    const elapsedTime = Date.now() - fieldTimesMap[fieldName];
    if (elapsedTime && elapsedTime > 0 && elapsedTime < 1800000) {
      window.dataLayer.push({
        event: GAEvents.fieldTiming,
        timingCategory: `${formName} Form Field Timing`,
        timingVariable: fieldName,
        timingValue: elapsedTime,
      });
    }
    delete fieldTimesMap[fieldName];
  };

  return [onFocus, onBlur];
};

export const useTrackFilters = (lastSavedFilters, currentFilters) => {
  const { pageID } = usePageContext();

  return useCallback(() => {
    const changedFilters = xorWith(lastSavedFilters, currentFilters, isEqual);
    // We need to ignore filters that were removed since we only want to track filters added
    const addedFilters = changedFilters.filter((c) => c.value !== '' && !lastSavedFilters.includes(c));
    // Send GA events here
    addedFilters.forEach((f) => {
      const action = `${pageID}-${f.field}`;
      GAController.pushEvent(GAEvents.filterUpdate, action);
    });
  }, [pageID, lastSavedFilters, currentFilters, GAController]);
};

export default GAController;
