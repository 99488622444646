// @flow
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';


export const WHO_AM_I_QUERY = gql`
  query WhoAmIQuery {
    whoami {
      id
      firstName
      lastName
      phoneNumber
      timezone
      email
      isClientOnly
      intercomHash
      avatar {
        getURL
        filename
      }
      notificationSettings {
        acceptAssignment
        acceptProject
        declineProject
        requestNewProject
        timesheetSignoffReminder
        assignmentDeclined
      }
      liveMapSettings {
        driverIdentifier
      }
    }
  }
`;

export const useWhoAmI = () => {
  const { data, loading, error } = useQuery(WHO_AM_I_QUERY);
  return useMemo(() => (
    !loading && !error
      ? [data.whoami, false, false]
      : [{}, loading, error]
  ), [data, loading, error]);
};
