// @flow
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

type CardDividerProps = {
    className?: string,
};

const CardDivider = ({
    className
}:CardDividerProps) => (
    <div className={classNames(styles.cardDivider, className)}/>
);

CardDivider.defaultProps = {
    className: undefined,
};

export default CardDivider;
