// @flow
import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { TIMESHEET_QUERY } from 'components/Timesheets/Hooks/useTimesheet.js';

const UPLOAD_TIMESHEET_IMAGE_MUTATION = gql`
mutation UploadTimesheetAttachment($input: AttachmentInput!, $timesheetID: ID!) {
  uploadTimesheetAttachment(input: $input, timesheetID: $timesheetID) {
    getURL
    expiry
    mimeType
    __typename
  }
}
`;

/**
 * After uploading the image to AWS you will now need to save the
 * AWS image reference in our DB
 */
const useSaveTimesheetImageReference = () => {
  const [uploadImage] = useMutation(UPLOAD_TIMESHEET_IMAGE_MUTATION);
  return useCallback((
    file: { filename: string, getURL: string},
    timesheetID: string,
    companyID: string,
  ) => (
    uploadImage({
      variables: {
        input: {
          filename: file.filename,
          getURL: file.getURL,
        },
        timesheetID,
      },
      refetchQueries: [{
        query: TIMESHEET_QUERY,
        variables: {
          companyID,
          timesheetID,
        },
      }]
    })
  ), [uploadImage]);
};

export default useSaveTimesheetImageReference;
