import { gql, useQuery } from '@apollo/client';

const ACCOUNT_QUERY = gql`
  query AccountMetadataQuery {
    whoami {
      id
      firstName
      lastName
      phoneNumber
      timezone
      email
      language
      intercomHash
    }
    companies(onlyAdmin: false) {
      id
      name
    }
  }
`;

const useGetUserInfo = () => {
  const { loading, error, data } = useQuery(ACCOUNT_QUERY);
  if (loading || error) return {};
  return {
    ...data.whoami,
    companies: data.companies,
  };
};

export default useGetUserInfo;
