// @flow
import React, { type Node } from 'react';
import DatePicker from 'react-datepicker';
import { get, includes } from 'lodash';
import { useTimezone } from 'common-ui/Hooks';
import {
  FormikTextInput,
  FormikDropdown,
  FormikCalendarInput,
} from 'common-ui/Form/Formik';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { formatDatetime, configurableFieldTypeValues } from 'common-ui/Utils/utils';
import 'react-datepicker/dist/react-datepicker.css';
import textInputStyles from 'common-ui/Form/TextInput/styles.module.scss';


// Need to specify the underlying class here since DatePicker clones
// this component and sets the input element's className to it.
class DatePickerTextInputWrapper extends React.PureComponent {
  render() {
    return <FormikTextInput {...this.props} />;
  }
}

type ConfigurableFieldType = $Values<typeof configurableFieldTypeValues>;

type ConfigurableFieldProps = {
  name: string,
  type: ConfigurableFieldType,
  options: Array<string>,
  readOnly: boolean,
};

const ConfigurableField = ({
  field: {
    id,
    name: label,
    type,
    options,
  },
  readOnly,
  groupName,
  formikValues,
  formikSetFieldValue,
  formikTouched,
  timezone,
  formikSetFieldTouched,
}) => {
  const name = `${groupName}-${id}`;
  const newValue = get(formikValues, name);
  const wasTouched = get(formikTouched, name);
  const idInt = parseInt(id, 10);

  // update the field containing all configurable field values (`groupName`)
  // when any configurable field value changes.
  React.useEffect(() => {
    if (wasTouched) {
      const vals = get(formikValues, groupName, []);
      let found = false;
      const newVals = [];

      // If the list already has an element with id, update it.
      // Otherwise, add it to the list.
      for (const v of vals) {
        if (v.id === idInt) {
          newVals.push({ id: idInt, value: newValue });
          found = true;
        } else {
          newVals.push(v);
        }
      }
      if (!found) {
        newVals.push({ id: idInt, value: newValue });
      }
      formikSetFieldValue(groupName, newVals);
    }
  }, [wasTouched, newValue, formikSetFieldValue]);

  if (type === configurableFieldTypeValues.TEXT) {
    return (
      <FormikTextInput
        name={name}
        label={label}
        placeholder="Input text"
        readOnly={readOnly}
      />
    );
  } if (type === configurableFieldTypeValues.DATETIME) {
    let value;
    let readOnlyDate;

    if (formikValues[name]) {
      value = moment.tz(formikValues[name], timezone).toDate();
      readOnlyDate = formatDatetime(formikValues[name], timezone);
    }

    return (
      <DatePicker
        selected={value}
        onChange={(newValue) => {
          formikSetFieldTouched(name, true);
          formikSetFieldValue(name, newValue);
        }}
        showTimeSelect
        timeIntervals={15}
        dateFormat="iii MMM d, yyyy, h:mma"
        readOnly={readOnly}
        placeholderText="Select Date"
        popperModifiers={[ //arrow modifier
          {
            name: 'arrow',
            options: {
              padding: 10,
            },
          },
        ]}
        customInput={(
          <DatePickerTextInputWrapper
            className={textInputStyles.textInputField}
            name={name}
            label={label}
            readOnly={readOnly}
            readOnlyValue={readOnlyDate}
          />
        )}
      />
    );
  } if (type === configurableFieldTypeValues.DROPDOWN) {
    return (
      <FormikDropdown
        name={name}
        label={label}
        placeholder="Select an option"
        readOnly={readOnly}
        options={options.map(o => (
          {
            label: o,
            value: o,
          }
        ))}
      />
    );
  }
  return null;
};

const ConfigurableFields = ({
  name,
  fields,
  readOnly,
  formikValues,
  formikSetFieldValue,
  formikTouched,
  formikSetFieldTouched,
}) => {
  const timezone = useTimezone();

  return (
    <>
      {fields.map(cf => (
        <ConfigurableField
          key={`${name}-${cf.id}`}
          field={cf}
          groupName={name}
          readOnly={(cf && cf.readOnly) || readOnly}
          formikValues={formikValues}
          formikSetFieldValue={formikSetFieldValue}
          formikTouched={formikTouched}
          timezone={timezone}
          formikSetFieldTouched={formikSetFieldTouched}
        />
      ))}
    </>
  );
};

export default ConfigurableFields;
