import * as React from "react";

const TicketSignedOff = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={12} cy={12} r={10} fill="#383838" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.87435 14.3503L7.10857 11.5845L6.16675 12.5197L9.87435 16.2273L17.8334 8.2682L16.8982 7.33301L9.87435 14.3503Z"
            fill="white"
        />
    </svg>
);

export default TicketSignedOff;
