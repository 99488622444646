import { useMemo } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ERROR } from 'Notification/NotificationTypes';
import parseGraphqlError from 'utils/Error';
import { ASSIGNMENTS_QUERY } from 'Jobs/graphql';
import { SET_NOTIFICATION } from 'apollo';

const COMPANIES_QUERY = gql`
  query CompaniesQuery {
    companies {
      id
      name
    }
  }
`;

const START_SELF_LOGGING_MUTATION = gql`
  mutation createSelfAssignmentInstance($input: NewAssignmentInstanceInput!) {
    createSelfAssignmentInstance(input: $input) {
      id
    }
  }
`;

export const useCompanies = () => {
  const { loading, error, data } = useQuery(COMPANIES_QUERY);
  return useMemo(() => {
    if (loading || error) return null;
    return data.companies;
  }, [loading, error, data]);
};

export const useStartSelfLog = () => {
  const [startSelfLogging] = useMutation(START_SELF_LOGGING_MUTATION);
  const [notify] = useMutation(SET_NOTIFICATION);
  return (id) => startSelfLogging({
    variables: {
      input: {
        companyID: parseInt(id, 10),
      },
    },
    refetchQueries: [{ query: ASSIGNMENTS_QUERY }],
  }).catch((err) => {
    notify({
      variables: { type: ERROR, message: parseGraphqlError(err) },
    });
  });
};
