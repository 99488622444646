// @flow
import React from 'react';
import classNames from 'classnames';
import { Close, PDFPlaceholder, Download } from 'common-ui/Icon';
import { Button } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import styles from './styles.module.scss';

export type AttachmentThumbnailProps = {
  id: string,
  index: number,
  filename: string,
  src: string,
  type: string,
  canRemove?: boolean,
  canDownload?: boolean,
};

export const AttachmentThumbnail = ({
  index,
  id,
  filename,
  src,
  type,
  removeAttachment,
  canRemove,
  canDownload,
}: AttachmentThumbnailProps) => {
  const isImage = type && type.includes('image');

  return (
    <div>
      <div className={styles.photoContainer}>
        <div
          className={styles.ticketRowPhoto}
          style={{
            backgroundImage: isImage && `url(${src})`,
          }}
        >
          {!isImage && <PDFPlaceholder />}
          {canDownload && (
            <div>
              <a href={src || null} download>
                <Button
                  text="Download"
                  icon=<Download />
                  className={classNames(
                    styles.downloadAttachmentButton,
                  )}
                />
              </a>
            </div>
          )}
          {canRemove && (
            <ClickableDiv
              className={classNames(styles.detachAttachmentButton)}
              onClick={() => {
                removeAttachment(index, id);
                GA.trackClick(GAActions.detachAttachment, 'Detach Attachment');
              }}
            >
              <Close/>
            </ClickableDiv>
          )}
        </div>
      </div>
    </div>
  );
};

AttachmentThumbnail.defaultProps = {
  canRemove: false,
  canDownload: false,
};

export default AttachmentThumbnail;
