// @flow
import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type CountProps = {
  value: number,
  className?: string,
};

const Count = ({ value, className }: CountProps) => (
  <div
    className={classNames(styles.count, className, {
      [styles.countAlert]: !className,
    })}
  >
    <span>{value}</span>
  </div>
);

Count.defaultProps = {
  className: undefined,
};

export default Count;
