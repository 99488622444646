// @flow
import classNames from 'classnames';
import React, { type Node } from 'react';
import ContextSwitcherItem from './ContextSwitcherItem';
import styles from './styles.module.scss';

type Option = {
  value: string,
  label: string,
  icon?: Node,
}

type ContextSwitcherProps = {
  options: Option[],
  selectedValue: string,
  onChange: (value: string) => void,
  isOperatorStyle?: boolean,
}

const ContextSwitcher = ({ options, onChange, selectedValue, isOperatorStyle }: ContextSwitcherProps) => (

  <div className={classNames(styles.contextSwitcherContainer)}>
    {options.map((option) => (
      <ContextSwitcherItem
        key={option.label}
        onClick={() => { onChange(option.value); }}
        isActive={option.value === selectedValue}
        label={option.label}
        icon={option.icon}
        isOperatorStyle={isOperatorStyle}
      />
    ))}
  </div>

);

ContextSwitcher.defaultProps = {
  isOperatorStyle: false,
};

export default ContextSwitcher;
