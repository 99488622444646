// @flow

import type { Type } from './Types';
import type { Area } from './Areas';
import type { Label } from './Labels';

/**
 * Function creates a unique descriptive ID that is meant to be consistently applied.
 * If you change the values for a given element you will need to make sure to change it
 * in the tests as well.
 *
 * If there are any significant changes please update ReadMe.md in this directory
 *
 * @param area: this tells the general area of the code, i.e. where it is in the top bar
 * @param type: this tells what type of element it is. A top down naming approach
 * focusing on whether it is something that tells the user information only or whether it is
 * a field that can be changed by the user.
 * @param description: this is a description that should describe the field.
 * If you cannot find a description type then you can add it in ConsistentID/DescriptionType
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
const create = (area: Area, type: Type, description: Label) => `${area}-${type}-${description}`;

export default create;
