// @flow
import React, { useState } from 'react';
import GA, { GAActions } from 'common-ui/Analytics/GoogleAnalytics';
import { Button } from 'common-ui/Button';
import { usePermissions } from 'common-ui/Hooks';
import { useI18n } from 'internationalization';
import { useGetAssignment } from 'components/TabBar/gql';
import { useAcceptJob, useDeclineJob } from './gql';
import DeclineJobModal from './DeclineJobModal';
import styles from './styles.module.scss';
import ID from 'common-ui/ConsistentIDs';

type AcceptJobSectionProps = {
  jobId: string,
  hideDecline?: boolean,
};

const AcceptJobSection = ({ jobId, hideDecline }: AcceptJobSectionProps) => {
  const intlz = useI18n();
  const [disabled, setDisabled] = useState(false);
  const acceptJob = useAcceptJob();
  const declineJob = useDeclineJob();
  const [assignment] = useGetAssignment(jobId);
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const { DisableDriverDeclineOption: companyLevelDisableDecline } = usePermissions(assignment.companyId.toString());
  hideDecline = hideDecline || companyLevelDisableDecline;

  return (
    <div className={styles.acceptJobSection}>
      {!hideDecline && (
        <Button
          id={ID.create(
            ID.Areas.Jobs,
            ID.Types.Button,
            ID.Labels.DeclineJob,
          )}
          fullWidth
          large
          styleName="negative"
          text={intlz('Decline')}
          onClick={() => setDeclineModalVisible(true)}
        />
      )}
      <Button
        id={ID.create(
          ID.Areas.Jobs,
          ID.Types.Button,
          ID.Labels.AcceptJob,
        )}
        fullWidth
        disabled={disabled}
        large
        styleName="primary"
        text={intlz('Accept')}
        onClick={() => {
          GA.trackClick(GAActions.acceptJob, 'Accept');
          setDisabled(true);
          if (assignment.schedule) {
            Promise.all(assignment.schedule.assignments.map(a => acceptJob(a.id)))
              .finally(() => setDisabled(false));
          } else {
            acceptJob(jobId)
              .finally(() => setDisabled(false));
          }
        }}
      />
      {!hideDecline && (
        <DeclineJobModal
          visible={declineModalVisible}
          onClose={() => setDeclineModalVisible(false)}
          onDecline={() => {
            GA.trackClick(GAActions.declineJob, 'Decline job');
            if (assignment.schedule) {
              return Promise.all(assignment.schedule.assignments.map(a => declineJob(a.id)));
            } else {
              return declineJob(jobId);
            }
          }}
          companyName={assignment && assignment.companyName}
        />
      )}
    </div>
  );
};

AcceptJobSection.defaultProps = {
  hideDecline: false,
};

export default AcceptJobSection;
