import React from 'react';

const ChevronRight = props => (
  <svg width={11} height={20} viewBox="0 0 11 20" {...props}>
    <title>Arrow Right</title>
    <path
      d="M.365 2.053A1.194 1.194 0 1 1 2.053.365l8.357 8.357a1.194 1.194 0 0 1 0 1.688l-8.357 8.358A1.194 1.194 0 0 1 .365 17.08l7.513-7.514L.365 2.053z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default ChevronRight;
