import React from 'react';

const Camera = props => (
  <svg width={21} height={19} viewBox="0 0 21 19" {...props}>
    <title>Camera</title>
    <path
      d="M11.343 7.632A2.743 2.743 0 0 0 10.5 7.5c-1.582 0-2.864 1.343-2.864 3s1.282 3 2.864 3c1.582 0 2.864-1.343 2.864-3 0-.307-.045-.604-.127-.883a1.43 1.43 0 0 1-1.305.883c-.791 0-1.432-.672-1.432-1.5 0-.609.346-1.133.843-1.368zM4.582 3l.79-2.071C5.57.416 6.157 0 6.678 0h7.646c.524 0 1.11.418 1.304.929L16.418 3h2.672C20.145 3 21 3.895 21 5.002v11.996C21 18.104 20.144 19 19.09 19H1.91C.855 19 0 18.105 0 16.998V5.002C0 3.896.856 3 1.91 3h2.672zM1.91 5v11.998C1.91 17 19.09 17 19.09 17V5.002C19.09 5 16.127 5 16.127 5c-.552 0-1.16-.417-1.358-.933L13.974 2H7.02l-.79 2.067C6.035 4.582 5.42 5 4.875 5H1.91zm3.817 5.5c0-2.761 2.137-5 4.773-5 2.636 0 4.773 2.239 4.773 5s-2.137 5-4.773 5c-2.636 0-4.773-2.239-4.773-5z"
      fill="#000B9D"
      fillRule="evenodd"
    />
  </svg>
);

export default Camera;
