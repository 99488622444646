import React from 'react';

// NOTE: To convert from SVG, you must rename any 'xlink:href'
// to 'xlinkHref' and 'xmlns:xlink' to 'xmlnsXlink'
// https://github.com/facebook/react/issues/4908
const Play = props => (
  <svg
    width="15px"
    height="16px"
    viewBox="0 0 15 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs />
    <g
      id="play-Components"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="play-Colors,-Text,-Icons"
        transform="translate(-380.000000, -1874.000000)"
      >
        <g id="play-Icon/Play" transform="translate(375.000000, 1869.000000)">
          <g
            id="play-Colors/Blue-Copy-4"
            transform="translate(5.000000, 5.000000)"
          >
            <polygon
              id="play-path-1"
              points="0 0 0 15.9199219 14.3515625 7.95996094"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Play;
